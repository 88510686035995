import * as React from "react";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./CostingConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import calculator from "../../Assets/images/calculator.svg";
import guidelines from "../../Assets/images/guidelines.svg";
import ShareIcon from "@mui/icons-material/Share";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import ReactDOMServer from "react-dom/server";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ShareCostingModel from "./ShareCositngModel";
import WatermarkModel from "./WatermarkModel";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import { AUTH } from "../../Redux/Services/auth.service";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import VerifiedIcon from "@mui/icons-material/Verified";
import validatedCalcImg from "../../Assets/images/calculator_green.svg";
import AssumptionCard from "./AssumptionCard";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import CostingConfigurationDrawer from "./CostingConfigurationDrawer";
import './CostingConfigurationGuidelines.scss';
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";


type Anchor = "top" | "left" | "bottom" | "right";

export interface ICostingConfigurationGuidelinesProps { }

function CostingConfigurationGuidelines(props: ICostingConfigurationGuidelinesProps) {
  const history = useHistory();
  useDocumentTitle( "Costing Config" );
  const editorConfig = {

 
    style: {
      padding: "20px",
      width: "100%",
      height: "74vh",
    },

  }
  const { url } = useRouteMatch();
  const [saveCount, setSaveCount] = React.useState<any>(0);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [content, setContent] = useState<any>("");
  const [contentDataCount, setContentDataCount] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [guidelinesId, setGuidelinesId] = React.useState<any>();
  const [guidelineScequenceId, setGuidelineScequenceId] = React.useState<any>();
  const [validation, setValidation] = useState<any>(false);
  const { projectId, projectname } = useRouteParams<any>();
  const [useraction,setuseraction]=useState<any>(null)
  const editor = useRef(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { height, width } = useWindowDimensions();
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module:"Costing Configuration",
        
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}

    >

      <CostingConfigurationDrawer />
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };
  const [guideLinesData, setGuideLinesData] = React.useState<any>({});
  const [otherValidation, setOtherValidation] = React.useState<any>({});

  const comapnionHandle = () => {
    API.get(
      "/web_companion/comapanion_activity_log",
      {
        //   get_recycle_bin_data_count: true
        project: projectId,
        viewed: true,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data.count, "countdata")
      })
      .catch((err: any) => {
        // console.log("Server error")
        //   SetLoader(false)
      });
    history.push(`/companionConfig/${projectId}/${projectname}`);
  };

  const costingModel = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
  };
  const commodityHandle = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/0/commodity`);
  };
  const guideHandler = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines`);
  };

  const assumptionHandler = () => {
          history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/1/false/true`);
        
    }

  const getGuidelinesData = () => {
    API.get(
      "/cost/cost_guide_line/",
      {
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setContentDataCount(res.data?.length);
        setGuidelinesId(res.data[0]?.id);
        setContent(res.data[0].content);
        setGuidelineScequenceId(res.data[0].scequence);
        setValidation(res.data[0]?.validation);
        sessionStorage.setItem("validationGuideLines", JSON.stringify(res.data[0]));
        setGuideLinesData(res.data[0]);
      })
      .catch((err: any) => { });
  };
  const getAllResult = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        costing_config: true,
      },
      0
    )
      .then((res: any) => {
        setOtherValidation(res.data[0]);
        sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getGuidelinesData();
    getAllResult();
  }, [projectId]);

  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>
      <div>
        <p></p>
      </div>
    </>
  );

  useEffect(() => {
    setContent(htmlElement);
  }, [htmlElement]);
  const handleDownload = () => {
    try {
      // API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

      fetch(API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "costing-guideline"; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch (e) { }
  };

  const shareModal = useBaseModal();
  const WatermrkModel = useBaseModal();
  // const AssumptionCardModal = useBaseModal();

  return (
    <div>
      {shareModal.isOpen && (
        <ShareCostingModel
          isOpen={shareModal.isOpen}
          onCloseModal={shareModal.close}
        />
      )}
      {WatermrkModel.isOpen && (
        <WatermarkModel
          isWMOpen={WatermrkModel.isOpen}
          onCloseModal={WatermrkModel.close}
        />
      )}
      {/* {AssumptionCardModal.isOpen && (
        <AssumptionCard
          isASCDopen={AssumptionCardModal.isOpen}
          isASCDclose={AssumptionCardModal.close}
        />
      )} */}

      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap", fontWeight: "500" }}>{projectname}-Costing Configuration</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem", height: "3rem" }}>
            { useraction && useraction?.includes("C") && <Button
              size="small"
              title="Add Watermark"
              sx={{ color: guideLinesData?.water_mark ? "green" : "" }}
              onClick={() => WatermrkModel.open()}>
              <AddIcon
                sx={{
                  marginTop: "-0.2rem",
                  fontSize: "1.5rem",
                  color: guideLinesData?.water_mark ? "green" : "",
                }}
              />
              Watermark
            </Button>}
            {guideLinesData?.water_mark && (
              <Button
                title="Download Guidelines"
                size="small"
                onClick={handleDownload}>
                Download <DownloadIcon sx={{ fontSize: "1.5rem" }} />
              </Button>
            )}
            {useraction && useraction?.includes("U") && <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.3rem", width: "8rem" }}>
              <Checkbox
                style={{ padding: "1px" }}
                {...label}
                // marginTop: validation === false ? '' : '-4px', marginRight: validation === false ? '' : '3px'
                checked={validation}
                disabled={contentDataCount === 0 ? true : false}
                checkedIcon={
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: "1rem",
                      width: "1.4rem",
                    }}>
                    <VerifiedIcon
                      sx={{
                        color: "#39FF14",
                        position: "relative",
                        margin: "-0.35rem -0.25rem 0",
                      }}
                    />
                  </Box>
                }
                sx={{
                  width: "3rem",
                  height: "3rem",
                  color: "primary.main",
                  "& .MuiSvgIcon-root": { fontSize: { lg: 24, xl: 24 } },
                }}
                onChange={() => {
                  setLoading(false);
                  API.put(
                    `/cost/cost_guide_line/${guidelinesId}/`,
                    {
                      make_validation: !validation,
                      project: projectId,
                    },
                    {},
                    0
                  ).then(() => {
                    setValidation((prev: any) => !prev);
                  });
                }}
              />
              <Typography sx={{ fontSize: "1rem" }}>{validation == true ? "Validated" : "Validate"} </Typography>
            </Box>}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              paddingRight: "1rem",
            }}>
            {otherValidation && otherValidation?.calculator_config ? (
              <img
                title="Calulator"
                src={validatedCalcImg}
                style={{ height: "1.65rem", width: "auto", cursor: "pointer" }}
                onClick={() => costingModel()}
              />
            ) : (
              <img
                title="Calulator"
                src={calculator}
                style={{ height: "1.65rem", width: "auto", cursor: "pointer" }}
                onClick={() => costingModel()}
              />
            )}
            {otherValidation && useraction && useraction?.includes("C") && (
              <CreditCardIcon
                titleAccess="Assumption Card"
                sx={{
                  fontSize: "2.5rem",
                  cursor: "pointer",
                  color: otherValidation?.assumption_cofig ? "green" : "",
                }}
                // onClick={() => AssumptionCardModal.open()}
                onClick={() => assumptionHandler()}
              />
            )}
            <ShareIcon
              titleAccess="Share Costing Configuration"
              onClick={() => shareModal.open()}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>
                      Costing Configuration
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  Costing Configuration
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>

        <Box sx={{ width: "100%", height: "89vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 1rem 0.5rem",
              position: "absolute",
              marginTop: { lg: "6.5rem", xl: "6rem" },
              right: "0.5rem",
              zIndex: "1",
            }}>
            {useraction && useraction?.includes("C") && <LoadingButton
              title="Save Configuration"
              variant="contained"
              size="small"
              loading={loading}
              sx={{
                fontSize: "0.87rem",
                cursor: "pointer",
                minWidth: "0.5rem",
                maxWidth: "2.3rem",
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.3s ease",
                },
              }}
              onClick={() => {
                setLoading(true);
                if (contentDataCount > 0) {
                  API.put(
                    `/cost/cost_guide_line/${guidelinesId}/`,
                    {
                      content: content,
                      project: projectId,
                      sequence: guidelineScequenceId,
                      validation: false,
                    },
                    {}
                  )
                    .then((res: any) => {
                      setLoading(false);
                      getGuidelinesData();
                      Swal.fire({
                        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                      <br />
                      <p style="color:#007fff;">Guidelines updated Successfully!</p>   
                       </div>`,
                      });
                    })
                    .catch((err: any) => {
                      setLoading(false);
                    });
                } else {
                  API.post("/cost/cost_guide_line/", {
                    content: content,
                    project: projectId,
                  })
                    .then((res: any) => { })
                    .catch((err: any) => { });
                }
              }}>
              <SaveIcon sx={{ cursor: "pointer" }} />
            </LoadingButton>}
          </Box>
          <Box sx={{ width: { lg: '96vw', xl: '97vw' } }}>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              className='CostingConfigMailEditor'
              value={content}
              onBlur={(newContent) => setContent(newContent)}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default CostingConfigurationGuidelines;
