import React, { useEffect, useState } from "react";

import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./DataBaseLandingPage.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import guide from "../Assets/images/guideIcon.svg";
import calculator from "../Assets/images/calcul.svg";
import ShareIcon from "@mui/icons-material/Share";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import CompanionConfig from "../CompanionConfig/CompanionConfig";
import { API } from "../api-services";
import DataBaseDrawer from "./DataBaseDrawer";
import { useDocumentTitle } from "../ui-reusable-component/useDocumentTitle";
import APIConsoleDrawer from "./APIConsoleDrawer";

type Anchor = "top" | "left" | "bottom" | "right";

interface IConfigurationMainLandingProps {
  
}

function APIConsoleDrawerComponent(props: IConfigurationMainLandingProps) {
  const history = useHistory();
  useDocumentTitle( "Database" );
  const { url } = useRouteMatch();
  const { projectId, projectname } = useRouteParams<any>();
  const [productList, setProductList] = useState<any>([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const onCloseFunc=()=>{
    setState({
      ...state,
      left:false
    })
  }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [DataTypes, setDataTypes] = useState<any>();
  console.log("productListproductListproductList",productList);
  
  useEffect(() => {
    API.get(
      "/config/parameter_types/",
      {
        parameter_database: true,
      },
      0
    )
      .then((res: any) => {
        setDataTypes(res.data);
      })
      .catch((err: any) => {});
  }, [projectId]);

  const getProductList = () =>{
    API.get("/xcpep/product_list/",{
        bom_config_product:true,
        project:projectId
    },0).then((res:any)=>{
        setProductList(res.data)
        
    }).catch((err:any)=>{
        console.log(err)
    })
  }

  React.useEffect(()=>{
    getProductList()
  },[projectId])
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
      },
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, []);
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //  onClick={toggleDrawer(anchor, false)}
      //  onKeyDown={toggleDrawer(anchor, false)}
    >
      <APIConsoleDrawer productList={productList && productList} toggleDrawer={onCloseFunc}/>
    </Box>
  );

  const handleClick = () => {
    history.push("/internallanding");
  };

  const costingModel = () => {
    history.push("/costingconfiguration/calculator");
  };

  const bomparameter = () => {
    // useEffect(() => {
    //     SetLoader(true)
    //     API.get("/xcpep/instrument_calibration/", {
    //       get_recycle_bin_data_count: true
    //     }, 0).then((res: any) => {
    //       SetLoader(false)
    //       setRecycleCount(res?.data.count)
    //       // console.log(res?.data.count, "countdata")
    //     }).catch((err: any) => {
    //       // console.log("Server error")
    //       SetLoader(false)
    //     })
    //   }, [])
    // GET : web_companion/comapanion_activity_log?project=23&viewed=true
    history.push(`/data-base/bomCategories`);
  };

  return (
    <div>
     
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography style={{ width: "100%", textAlign: "center", fontSize: "1rem" }}>Select Product & BOM Category</Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  Select Product & BOM Category
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ width: "100%", height: "89vh" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"></Box>
      </Box>
    </div>
  );
}
export default APIConsoleDrawerComponent;
