import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import { API } from '../../../../api-services';
import { Box, IconButton, Skeleton } from '@mui/material';
import { useShareWithDb } from '../../useShareWithDb';
import useWindowDimensions from '../../../../ui-reusable-component/useWindowDimensions';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import move from '../../../../Assets/images/right move.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import EditIcon from '@mui/icons-material/Edit';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { useToolAddEditModel } from '../../useToolAddEditModel';
import AddOperationDatabaseModel from './AddOperationDatabaseModel';
import OperationMovePopUp from '../OperationRevision/OperationMovePopUp';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import { useBomCategoryUse } from '../../../BOM Categories/useBomCategoryUse';
import ToolUseList from '../../ToolUseList';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Database Operation Revision (Costing-DB) In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);


interface OperationsDatabaseSearchI {
  setRecyclebinCounter: any;
}

const OperationsDatabaseSearch: React.FC<OperationsDatabaseSearchI> = (props) => {
  const { setRecyclebinCounter } = props;
  const { revisionId, typeId, databaseName, categoryName, status, search } = useRouteParams<any>();
  const [searchData, setSearchData] = useState<any>();
  const [searchDataDataLoader, setSearchDataLoader] = useState<boolean>(true);
  const [apiCallsearchData, setApiCallSearchData] = useState<boolean>(true);

  const listOperationsRevisionUse = useBomCategoryUse();
  const operationShare = useShareWithDb();
  const operationAddFromDb = useToolAddEditModel();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [useraction,setuseraction]=useState<any>(null)

  useEffect(() => {
    setSearchDataLoader(true);
    API.get('/api/db/costDB_search/', { operations: true, search_key: search }, 0).then((res: any) => {
      setSearchData(res?.data);
      setSearchDataLoader(false);
    })
  }, [categoryName, search, apiCallsearchData])


  const handleShareWith: any = (idGet: any, shareItemCodeGet: any, listCommodityGet: any, shareItemNameGet: any) => {
    operationShare.open(idGet, shareItemCodeGet, listCommodityGet, shareItemNameGet)
  }


  const handleListToolLibraryUse = (id: any) => {
    listOperationsRevisionUse.open(id)
  }
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);
  const handleDelete = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Operations Code`,
          value: `deleteOperationCode`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteOperationCode":
          API.delete("/api/db/stroke_rate/" + id + "/")
            .then((res: any) => {
              setRecyclebinCounter((prev: any) => prev + 1);
              setApiCallSearchData(!apiCallsearchData);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 135 : 120,
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
          {cellValues?.row?.mapped_status ? <IconButton title='Info' sx={{ color: '#007fff', padding: '0rem', cursor: 'pointer' }} onClick={() => handleListToolLibraryUse(cellValues?.row?.id)}> <InfoIcon /> </IconButton> : useraction &&  useraction?.includes("D") && <IconButton title='Delete' sx={{ color: '#007fff', padding: '0rem', cursor: 'pointer' }} onClick={(e: any) => handleDelete(e, cellValues?.row?.id)}> <DeleteIcon /> </IconButton>}
          { useraction &&  useraction?.includes("U") && <IconButton title='Move' sx={{ cursor: 'pointer', color: '#007fff', padding: '0rem' }} onClick={() => handleShareWith(cellValues?.row?.id, cellValues?.row?.operationCode, 'dummy', cellValues?.row?.category)}> <img src={move} style={{ height: '1.3rem' }} /> </IconButton>}
          { useraction &&  useraction?.includes("U") && <IconButton title='Edit' sx={{ color: '#007fff', padding: '0rem' }} onClick={() => { operationAddFromDb.open(cellValues?.row?.id, "edit", cellValues?.row); }}><EditIcon /></IconButton>}
        </Box>)
      }
    },
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 60 : 50, },
    { field: "operationCode", headerName: "Operation Code", width: width > 1370 ? 200 : 160, },
    { field: "category", headerName: "Category", width: width > 1370 ? 200 : 160, },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 270 : 260,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: width > 1370 ? 270 : 250,
      //   flex: 1,
    },
    { field: "rate", headerName: "Rate/Unit (Curr)", width: width > 1370 ? 140 : 120,
    headerAlign: "right",
    align: 'right', },
    // {
    //   field: "unit",
    //   headerName: "Unit",
    //   minWidth: width > 1370 ? 120 : 80,      
    // },
    {
			field: 'currency_code',
			headerName: 'Currency/Unit',
			sortable: false,
			minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
			renderCell: (cellValues: any) => {
				
				
				return (
					<Box sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',columnGap:'1rem',}}> 
					{`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`} 
					 </Box>
				);
			},
		},

  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = searchData && searchData?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.id,
      sno: index + 1,
      operationCode: ideaItem?.code,
      description: ideaItem?.description,
      remark: ideaItem?.remark,
      unit: ideaItem?.unit,
      rate: ideaItem?.rate_per_unit,
      category: ideaItem?.category,
      mapped_status: ideaItem?.mapped_status,
      currency_id: ideaItem?.currency_id,
      currency_code: ideaItem?.currency_code,
    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  return (
    <div style={{ width: "100%", height: '90vh' }}>
      {operationShare.isOpen &&
        <OperationMovePopUp
          isOpen={operationShare.open}
          onCloseModal={operationShare.close}
          idIncoming={operationShare.id}
          shareItemCode={operationShare.shareItemCode}
          shareItemCategory={operationShare.shareItemCategory}
          apiCallAccordianInsideData={apiCallsearchData}
          setApiCallAccordianInsideData={setApiCallSearchData}
        />
      }
      {
        operationAddFromDb.isOpen &&
        <AddOperationDatabaseModel
          isOpen={operationAddFromDb.open}
          onCloseModal={operationAddFromDb.close}
          id={operationAddFromDb.id}
          actionSend={operationAddFromDb.actionSend}
          apiCallAccordianInsideData={apiCallsearchData}
          setApiCallAccordianInsideData={setApiCallSearchData}
          valueEdit={operationAddFromDb?.valueEdit}
        />
      }

      {listOperationsRevisionUse.isOpen &&
        <ToolUseList
          isOpen={listOperationsRevisionUse.isOpen}
          onCloseModal={listOperationsRevisionUse.close}
          id={listOperationsRevisionUse.id}
        />}

      <Box sx={{ height: { lg: '88vh', xl: '90vh' }, width: "100%" }}>
        {!searchDataDataLoader ?
          searchData && searchData?.length > 0 ? <DataGrid
            headerHeight={48}
            rowHeight={36}
            {...dataStructureDataGrid}
            disableSelectionOnClick={true}
            components={{
              Toolbar: () => {
                return CustomToolbar();
              },
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: 'none',
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
              },
            }}
            density={"compact"}
            pageSize={100}
            rowsPerPageOptions={[100]}
          /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <h2>No Search Result Found..</h2>
          </div> : <>
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
            <Skeleton width="100%" height="2rem" />
          </>}
      </Box>
    </div>)
}

export default OperationsDatabaseSearch;