import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { API } from '../../../api-services';
import swal from 'sweetalert';

export interface IBoughtOutPartActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	infoRawMaterialModal?: any;
	graphModal?: any;
	bopMoveModal?: any;
	history?: any;
	tab?: any;
	year?: any;
	month?: any;
	categoryName?: any;
	status?: any;
	search?: any;
	useraction?: any;
	getMaterialData?: any;
	setAnchorEl?: any;
}

export default function BoughtOutPartActionComponents(
	props: IBoughtOutPartActionComponentsProps
) {
	const {
		cellValues,
		rowId,
		infoRawMaterialModal,
		graphModal,
		bopMoveModal,
		history,
		tab,
		year,
		month,
		categoryName,
		status,
		search,
		useraction,
		getMaterialData,
		setAnchorEl,
	} = props;

	// console.log("cellValues", cellValues);
	console.log("rowId", rowId);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			{rowId.mapped_status ? (
				<IconButton
					title='Info'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
					onClick={() => infoRawMaterialModal.open(rowId.id)}>
					<InfoIcon />
				</IconButton>
			) : (
				rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('D') && (
					<IconButton
						title='Delete'
						sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
						onClick={() => {
                            setAnchorEl(null);
							swal({
								title: `Delete material`,
								text: `Are you sure you want to delete ?`,
								icon: 'error',
								buttons: ['Cancel', 'Yes, Delete'],
							}).then((confirm) => {
								if (confirm) {
									API.delete(`/api/db/bought_out_cost/${rowId.id}/`, {}, 0)
										.then((res: any) => {
											getMaterialData(categoryName);
										})
										.catch((err: any) => {});
								}
							});
						}}>
						<DeleteIcon />
					</IconButton>
				)
			)}

			<IconButton
				title='Graph'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() => graphModal.open(rowId)}>
				<EqualizerIcon />
			</IconButton>

			<IconButton
				title='Redirect'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() =>
					history.push(
						`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`
					)
				}>
				<LaunchIcon />
			</IconButton>
			{rowId?.flag_reference && (
				<IconButton
					title='Flag'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'green' }}
					onClick={() =>
						window.open(rowId?.flag_reference, '_blank', ' noopener')
					}>
					<FlagIcon />
				</IconButton>
			)}
    
			{rowId?.lock_status == false &&
				!rowId.mapped_status &&
				useraction &&
				useraction?.includes('U') && (
					<IconButton
						title='Move'
						sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
						onClick={() => {bopMoveModal.open(rowId)
                        setAnchorEl(null);
                        }}>
						<img
							src={move}
							style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem' }}
						/>
					</IconButton>
				)}
		</Box>
	);
}
