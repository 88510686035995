import { omit } from "lodash";
import { API } from "./index.service";
import { ID } from "../../utlis/commonInterfaces";
// import { CalculatorInput } from "../Reducers/calculatorInput.reducer";
import { bomcalculatorInput } from "../Reducers/bomCalculatorInput.reducer";

const url = "config/bom_calculator/";
const IdeaUrl="/xcpep/bom_calculator_field/"
const newTableUrl = "/config/bom_table_calculator_field/";
const trigulationurl = "cost/triangulation_calculator_field/";
const bomDeleteurl = "/xcpep/bom_calculator_field/"

export default {
    get ( calculatorId: ID ) {
        return API.get( url, { calculator_parameter: calculatorId } );
    },
    create ( input: Partial<bomcalculatorInput> ) {
        
        return API.post( url, input );
    },
    update ( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( url + id + "/", omit( input, ["id"] ) );
    },
    delete ( inputId: ID ) {
        return API.delete( bomDeleteurl + inputId + "/" );
    },
    deleteTableCalcFields ( inputId: ID ) {
        return API.delete( newTableUrl + inputId + "/" );
    },

    getTriangulation ( calculatorId: any, ) {
        return API.get( trigulationurl, { calculator: calculatorId } );
    },
    getBOMCost( calculatorId: any, ) {
        return API.get( IdeaUrl, { calculator_id: calculatorId } );
    },
    getBOMCostTable( calculatorId: any, ) {
        return API.get( newTableUrl, { calculator_id: calculatorId } );
    }
    ,
    createCostModal( input: Partial<bomcalculatorInput> ) {
        return API.post( IdeaUrl, input );
    },
    createCostModalTable( input: Partial<bomcalculatorInput> ) {
        return API.post( newTableUrl, input );
    },
    createTiangulation ( input: Partial<bomcalculatorInput> ) {
        return API.post( trigulationurl, input );
    },

    deleteCostModal( inputId: ID ) {
        return API.delete( IdeaUrl + inputId + "/" );
    },

    deleteTriangulation ( inputId: ID ) {
        return API.delete( trigulationurl + inputId + "/" );
    },
    updateBomCalc( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( IdeaUrl + id + "/", omit( input, ["id"] ) );
    },
    updateBomCalcTable( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( newTableUrl + id + "/", omit( input, ["id"] ) );
    },
    updateTriangulation ( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( trigulationurl + id + "/", omit( input, ["id"] ) );
    },

    updateflagTriangulation ( id: any, flag: boolean ) {
        return API.patch( trigulationurl + id + "/", { flag: flag } );
    },
    updateflagIdea ( id: any, flag: boolean ) {
        return API.patch( IdeaUrl + id + "/", { flag: flag } );
    },

    uploadFileTriangulation ( id: any, file: any ) {
        return API.patch( trigulationurl + id + "/", { image_url: file } );
    },
    uploadFileIdea ( id: any, file: any ) {
        return API.patch( IdeaUrl + id + "/", { image_url: file } );
    }
};