import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface IHornModulesFormProps {
  actions?: any;
  onCloseModal: any;
  getMaterialData: any;
  subCategory: any;
  subCategoryy: any;
  getElectricalCategories: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select type"),
  rated_voltage: yup.string().required("Please type Item Related voltage"),
  current_rating: yup.mixed().required("Please type current rating"),
  power_rating: yup.mixed().required("Please type Power rating"),
  frequency: yup.mixed().required("Please enter Frequency"),
  sound_level: yup.mixed().required("Please enter Sound level"),
  reference: yup.string(),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function HornModulesForm(props: IHornModulesFormProps) {
  const { actions, onCloseModal, getMaterialData, subCategory, subCategoryy, getElectricalCategories } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { categoryName, databaseName, revisionId } = useRouteParams<any>();
      // ----------------------------------------------

      const [countryList, setCountryList] = React.useState<any[]>([]);
      const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
      const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
      const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
        React.useState<any>("");
        const [selectedCurrencyIds, setSelectedCurrencyIds] =
        React.useState<any>("");
      // ----------------------------------------------
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      rated_voltage: "",
      current_rating: "",
      power_rating: "",
      frequency: "",
      sound_level: "",
      reference: "",
      unit_cost: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
      const { currency_code, ...apiPayload } = values; // remove currency_code from apiPayload  as it is not needed in the api
      if (actions === "add") {
        API.post(
          "/api/db/bought_out/",
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: subCategory,
              module_type: "Horn",
            },
          },
          {},
          0
        )
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              API.post(
                "/api/db/bought_out_cost/",
                {
                  purchase_items: [res.data.id],
                  revision: revisionId,
                },
                {},
                0
              )
                .then((res: any) => {
                  setIsLoadingSave(false);
                  getMaterialData("Horn");
                  getElectricalCategories();
                  onCloseModal();
                })
                .catch((err: any) => {
                  setIsLoadingSave(false);
                }).finally(() => {  
                  onCloseModal();
                }).finally(() => { 
                  onCloseModal();
                 });
            } else {
              setIsLoadingSave(false);
              getMaterialData("Horn");
              getElectricalCategories();
              onCloseModal();
            }
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      } else {
        API.put(
          `/api/db/bought_out/${actions?.id}/`,
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: actions?.commodity_name,
              module_type: actions?.module_type,
            },
          },
          {},
          0
        )
          .then((res: any) => {
            onCloseModal();
            setIsLoadingSave(false);
            getMaterialData("Horn");
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes([actions?.currency_code] || []);
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue("rated_voltage", actions?.rated_voltage || "");
      formik.setFieldValue("current_rating", actions?.current_rating || "");
      formik.setFieldValue("power_rating", actions?.power_rating || "");
      formik.setFieldValue("frequency", actions?.frequency || "");
      formik.setFieldValue("sound_level", actions?.sound_level || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue(
        "unit_cost",
        parseFloat(actions.unit_cost?.slice(1)) || ""
      );
      formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);
    }
  }, [actions]);

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});


  
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Type{" "}
            </InputLabel>

            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Single Tone", "Dual Tone", "Others"].map(
                (item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.type && Boolean(formik.errors.type) && (
                <p>{formik.errors.type}</p>
              )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Related Voltage"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="rated_voltage"
              name="rated_voltage"
              onChange={formik.handleChange}
              value={formik.values.rated_voltage}
              multiline
              error={
                formik.touched.rated_voltage &&
                Boolean(formik.errors.rated_voltage)
              }
              helperText={
                formik.touched.rated_voltage && formik.errors.rated_voltage
              }
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Current Rating"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="current_rating"
              name="current_rating"
              onChange={formik.handleChange}
              value={formik.values.current_rating}
              type="number"
              error={
                formik.touched.current_rating &&
                Boolean(formik.errors.current_rating)
              }
              helperText={
                formik.touched.current_rating && formik.errors.current_rating
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Power Rating"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="power_rating"
              name="power_rating"
              onChange={formik.handleChange}
              value={formik.values.power_rating}
              error={
                formik.touched.power_rating &&
                Boolean(formik.errors.power_rating)
              }
              helperText={
                formik.touched.power_rating && formik.errors.power_rating
              }
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Frequency"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="frequency"
              name="frequency"
              onChange={formik.handleChange}
              value={formik.values.frequency}
              error={
                formik.touched.frequency && Boolean(formik.errors.frequency)
              }
              helperText={formik.touched.frequency && formik.errors.frequency}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Sound level"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="sound_level"
              name="sound_level"
              onChange={formik.handleChange}
              value={formik.values.sound_level}
              error={
                formik.touched.sound_level && Boolean(formik.errors.sound_level)
              }
              helperText={
                formik.touched.sound_level && formik.errors.sound_level
              }
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (Currency)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={
                formik.touched.unit_cost && Boolean(formik.errors.unit_cost)
              }
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit"
          >
            {actions === "add" ? "Add" : "Save"}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
