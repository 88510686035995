import { Box, TextField } from "@mui/material";
import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { debounce } from "lodash";
import Swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";
import { ADMIN } from "../../Redux/Services/admin.service";

export interface IEditNameProps {
  item?: any;
  setEditName?: any;
  editName?: any;
  type?: any;
  getTreeData?: any;
  useraction?:any;
  useraction1?:any
}

export function EditName(props: IEditNameProps) {
  const { item, setEditName, editName, type, getTreeData,useraction ,useraction1} = props;
  const { library } = useRouteParams<any>();
  const { url } = useRouteMatch<any>();
  const [name, setName] = React.useState<any>("");

  React.useEffect(() => {
    if (item) {
      setName(item?.name);
    }
  }, [item]);
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(
        `/config/costing_configuration/${item.id}/`,
        {
          update_library:
            type === "commodity" ? "commodity_library" : "process_library",
          name: value,
        },
        {},
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Name Updated Successfully");
          if (url.includes("costingconfiguration")) {
            getTreeData();
          }
        })
        .catch((err: any) => {
          // const { data } = err?.response;
          // Swal.fire({
          //   icon: "error",
          //   html: `<div>
          //                      <br />
          //                      <p style="color:"red">${data[0]}</p>   
          //                       </div>`,
          // });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);
  return (
    <Box>

      {
        url.includes("costingconfiguration")? <TextField
        placeholder="Name"
        variant="standard"
        value={name}
        sx={{
          width: "100%",
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: "1rem !important",
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "primary.main",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "primary.main",
          },
        }}
        disabled={useraction1?.includes("U")?false:true}
        onChange={(event: any) => {
          const { value } = event.target;
          setName(value);
          if (value.length > 0) {
            ExactDebounce(value);
          }
        }}
      />: <TextField
      placeholder="Name"
      variant="standard"
      value={name}
      sx={{
        width: "100%",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "1rem !important",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "primary.main",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "primary.main",
        },
      }}
      disabled={useraction && useraction?.includes("U")?false:true}
      onChange={(event: any) => {
        const { value } = event.target;
        setName(value);
        if (value.length > 0) {
          ExactDebounce(value);
        }
      }}
    />
      }
     
    </Box>
  );
}
