import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  FormLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "../CostingEstimation/Process/AddProcessRevisionModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { BorderRight, Height } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";
import { API } from "../../../api-services";
import CalculatorListTree from "../CostingEstimation/CalculatorListTree";
import CostingCostCalculator from "../../../Configuration/Costing/CostingCreateCalculator";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { EditSequenceOverhead } from "../CostingEstimation/EditSequenceOverhead";
import { EditNameOverheadCreate } from "../CostingEstimation/EditNameOverheadCreate";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { CostModelCancelAlertModal } from "../CostingEstimation/CostModelCancelAlertModal";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface IAssignCostModeltoAddedProcessModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData: any;
  parentClose?: any;
  getRMData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function CreateOverHeadsModal(
  props: IAssignCostModeltoAddedProcessModalProps
) {
  const { onCloseModal, isOpen, parentClose, costDetailsData, getRMData } =
    props;
  const {
    projectId,
    projectname,
    productId,
    topVault,
    vault,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
  } = useRouteParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [proceedButtonStatus, setProccedButtonStatus] =
    React.useState<any>(false);
  const [age, setAge] = React.useState("");
  const [selectedMateialItem, setSelectedMaterialItem] =
    React.useState<any>("");
  const [materialItemsData, setMaterialItemsData] = React.useState<any>([]);
  const [allHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isTreeLoading, setIsTreeLoading] = React.useState<any>(false);
  const store: any = useSelector((state) => state);
  // store?.is_compare?.selcetedCalculators
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>([]);
  const [singleSelectedTreeData, setSingleSelectedTreeData] =
    React.useState<any>([]);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [quantity, setQuantity] = React.useState<any>(1);
  const [loading, setLoading] = React.useState<any>(false);
  const [calculatorsData, setCalculatorsData] = React.useState<any>([]);
  const handleChangeQuantity = (event: any) => {
    const { value } = event.target;
    setQuantity(value);
  };

  const getMaterialItemData = () => {
    API.get(
      "/cost/overhead/",
      {
        cost_estimation: costDetailsData.cost_estimation,
        calculator: true,
      },
      0
    )
      .then((res: any) => {
        setMaterialItemsData(res.data);
        setSelectedMaterialItem(res.data[0].id);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getMaterialItemData();
  }, []);
  React.useEffect(() => {
    if (selectedMateialItem !== "") {
      dispatch(selectedCalculators([]));
    }
  }, [selectedMateialItem]);

  // const getCalcsDetailsData = () => {
  //   API.get(
  //     "/cost/calculator_input/",
  //     { calculator: singleSelectedTreeData[0]?.id },
  //     0
  //   )
  //     .then((res: any) => {
  //       console.log("kjsfukgsdkjsds", res.data);
  //       setCalculatorsData(res.data);
  //     })
  //     .catch((err: any) => {});
  // };
  // React.useEffect(() => {
  //   if (singleSelectedTreeData?.length > 0) {
  //     getCalcsDetailsData();
  //   }
  // }, [singleSelectedTreeData]);

  // React.useEffect(() => {
  //   if (store?.is_compare?.selcetedCalculators) {
  //     setSelectedTreeData(store?.is_compare?.selcetedCalculators);
  //   }
  // }, [store?.is_compare?.selcetedCalculators]);

  const getTreeData = () => {
    setIsTreeLoading(true);
    API.get(
      "/config/costing_configuration/",
      {
        project: projectId,
        costing_config_hier: true,
        commodity: costDetailsData?.commodity?.toString(),
        cal_type: 0,
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsTreeLoading(false);
      })
      .catch((err: any) => {
        setIsTreeLoading(false);
      });
  };
  React.useEffect(() => {
    getTreeData();
  }, []);

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    // history.push(`/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
  };

  const CancelCostModal = useBaseParamsModal();
  const cancelCostModelHndler = () => {
    CancelCostModal.open();
    history.push(
      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${
        materialItemsData[0]?.id
      }/${costDetailsData?.cost_estimation}/${0}/${0}/${0}/0/${scenarioId}`
    );
    getRMData();
    //     Swal.fire({
    //       html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    // <br />
    // <p style="color:#007fff;"> Overheads added successfully
    // </p>
    // </div>`,
    //     });
    onCloseModal();
  };

  const closeHandlerParent = () => {
    API.post(
      "/cost/cost_calculator/",
      {
        overhead: true,
        cost_estimation: costDetailsData?.cost_estimation,
        scenario: scenarioId ? scenarioId : undefined,
      },
      {},
      0
    )
      .then((res: any) => {})
      .catch((err: any) => {});
    onCloseModal();
    parentClose();
  };

  return (
    <div>
      {CancelCostModal.isOpen && (
        <CostModelCancelAlertModal
          isOpen={CancelCostModal.open}
          onCloseModal={CancelCostModal.close}
          cancelHandler={closeHandlerParent}
          parentClose={parentClose}
        />
      )}

      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Cost Model Overheads
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                cancelCostModelHndler();
              }}
              // onClick={() => {
              //   API.post(
              //     "/cost/cost_calculator/",
              //     {
              //       overhead: true,
              //       cost_estimation: costDetailsData?.cost_estimation,
              //     },
              //     {},
              //     0
              //   )
              //     .then((res: any) => { })
              //     .catch((err: any) => { });
              //   onCloseModal();
              // }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                columnGap: "0.5rem",
                width: "100%",
                marginBottom: "0.5rem",
                height: "24vh",
                // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                borderRadius: "10px",
                backgroundColor: "white",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {/* <FormControl
                sx={{ width: { lg: "17vw", xl: "15vw" } }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: "primary.light",
                    color: "primary.main",
                    width: "100%",
                    fontWeight: "500",
                    padding: "0.24rem 0.5rem",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Overhead Name
                  
                </FormLabel>
                <FormGroup sx={{ fontSize: "1rem", marginLeft: "1rem" }}>
                  {materialItemsData &&
                    materialItemsData?.map((item: any) => {
                      return (
                        <FormControlLabel
                          title={item?.alias}
                          sx={{
                            height: { lg: "2.4rem", xl: "2.3rem" },
                            "span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.MuiTypography-root":
                              {
                                width: {
                                  lg: "15rem !important",
                                  xl: "17rem !important",
                                },
                                fontSize:'1rem',
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                          }}
                          control={
                            item?.calculator_mapped ? (
                              <Checkbox
                                checked={true}
                                disabled={true}
                                sx={{
                                  padding: "3px",
                                  fontSize: "18px",
                                  color: "#007fff",
                                }}
                                onClick={() =>
                                  setSelectedMaterialItem(item?.id)
                                }
                              />
                            ) : (
                              <Checkbox
                                checked={
                                  selectedMateialItem === item?.id
                                    ? true
                                    : false
                                }
                                sx={{
                                  padding: "3px",
                                  fontSize: "18px",
                                  color: "#007fff",
                                }}
                                onClick={() =>
                                  setSelectedMaterialItem(item?.id)
                                }
                              />
                            )
                          }
                          label={item?.alias}
                        />
                      );
                    })}
                </FormGroup>
              </FormControl> */}
              <Box sx={{ width:{ lg: "35vw", xl: "25vw" }  }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ width: "100%" }}
                >
                  <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sticky",
                      top: "0",
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                    <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                        }}
                      >
                        Select
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                        }}
                      >
                        Sequence
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                        }}
                      >
                       Overhead Name 
                        <AddCircleIcon
                          titleAccess="Add Overhead"
                          sx={{ cursor: "pointer", float: "right" }}
                          onClick={() => {
                            API.post(
                              "/cost/overhead/",
                              { cost_estimation: costDetailsData?.cost_estimation },
                              {},
                              0
                            )
                              .then((res: any) => {
                                getMaterialItemData();
                              })
                              .catch((err: any) => {});
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialItemsData &&
                      materialItemsData?.map((item: any) => {
                        return (
                          <TableRow sx={{backgroundColor: item?.id === selectedMateialItem ? '#c4efb3': ''}}>
                            <TableCell
                                  sx={{
                                    padding: "0rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                {
                                item?.calculator_mapped ? (
                                  <Checkbox
                                    checked={true}
                                    disabled={true}
                                    sx={{
                                      padding: "3px",
                                      fontSize: "18px",
                                      color: "#007fff",
                                    }}
                                    onClick={() =>
                                      setSelectedMaterialItem(item?.id)
                                    }
                                  />
                                ) : (
                                  <Checkbox
                                    checked={
                                      selectedMateialItem === item?.id
                                        ? true
                                        : false
                                    }
                                    sx={{
                                      padding: "3px",
                                      fontSize: "18px",
                                      color: "#007fff",
                                    }}
                                    onClick={() =>
                                      setSelectedMaterialItem(item?.id)
                                    }
                                  />
                                )
                              }
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <EditSequenceOverhead
                                value={item?.sequence}
                                costEstimationId={item?.id}
                                getMaterialItemData={getMaterialItemData}
                                key={item?.id}
                                item={item}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0rem 0.5rem",
                                borderBottomColor: "primary.light",
                                width:'100%',
                              }}
                            >
                              <EditNameOverheadCreate
                                value={item?.alias}
                                idd={item?.id}
                                getRMData={getMaterialItemData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ width: { lg: "57vw", xl: "68vw" } }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ width: "100%" }}
                >
                  <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sricky",
                      top: "0",
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                          width: "4rem",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                        }}
                      >
                        Select Cost Models
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialItemsData &&
                      materialItemsData?.map((item: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {item?.qty}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                height: { lg: "2.4rem", xl: "2.35rem" },
                              }}
                              title={
                                item?.cal_name?.length > 0
                                  ? item?.cal_name?.join(", ")
                                  : "---"
                              }
                            >
                              <Box
                                sx={{ width: { lg: "52vw", xl: "65vw" } }}
                                className={styles.tableCell}
                              >
                                {" "}
                                {item?.cal_name?.length > 0
                                  ? item?.cal_name?.join(", ")
                                  : "---"}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Box sx={{ height: "62vh", marginTop: "0.5rem" }}>
              {selectedMateialItem != "" && (
                <>
                  <Box className={styles.selectMaterialCost}>
                    <Box className={styles.selectedMaterialCalc}>
                      <Box
                        sx={{
                          width: "25vw",
                          height: { lg: "60vh", xl: "60vh" },
                          borderRight: "1px solid",
                          borderColor: "primary.light",
                        }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Select Cost Model
                        </Typography>
                        {!isTreeLoading && allHierarchy ? (
                          allHierarchy?.map((item: any) => {
                            return (
                              <CalculatorListTree
                                HierarchyData={item}
                                onSelectCallback={onSelect}
                                setSelectedTreeData={setSelectedTreeData}
                                setCounterApiCall={setCounterApiCall}
                                selectedTreeData={selectedTreeData}
                                setSingleSelectedTreeData={
                                  setSingleSelectedTreeData
                                }
                                singleSelectedTreeData={singleSelectedTreeData}
                              />
                            );
                          })
                        ) : (
                          <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                            <Skeleton sx={{ height: "2rem", width: "30%" }} />
                            <Skeleton sx={{ height: "2rem", width: "45%" }} />
                            <Skeleton sx={{ height: "2rem", width: "50%" }} />
                            <Skeleton sx={{ height: "2rem", width: "65%" }} />
                            <Skeleton sx={{ height: "2rem", width: "70%" }} />
                            <Skeleton sx={{ height: "2rem", width: "60%" }} />
                            <Skeleton sx={{ height: "2rem", width: "55%" }} />
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            height: {
                              sm: "60vh",
                              md: "69vh",
                              lg: "55vh",
                              xl: "56vh",
                            },
                            width: "100%",
                          }}
                        >
                          {singleSelectedTreeData?.length > 0 && (
                            <CostingCostCalculator
                              selectedTreeData={singleSelectedTreeData[0]}
                              setCounterApiCall={setCounterApiCall}
                              dataType={singleSelectedTreeData?.type}
                              vault={singleSelectedTreeData?.id}
                            />
                          )}
                        </Box>
                        {selectedTreeData != "" && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              columnGap: "1rem",
                              marginTop: "0.5rem",
                              paddingRight: "0.5rem",
                            }}
                          >
                            {selectedTreeData != "" && (
                              <FormControl
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                  flexDirection: "row",
                                }}
                              >
                                <label>
                                  Add Quantity
                                  <sup style={{ color: "red" }}>*</sup> :
                                </label>

                                <TextField
                                  type="number"
                                  variant="standard"
                                  placeholder="Quantity*"
                                  value={quantity}
                                  onChange={handleChangeQuantity}
                                  inputProps={{
                                    step: "any",
                                    min: 0,
                                    style: { fontSize: "1rem" },
                                  }}
                                  sx={{
                                    width: "5rem",
                                    borderBottomColor: "primary.light",
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "primary.light",
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "primary.main",
                                    },
                                  }}
                                />
                              </FormControl>
                            )}
                            <LoadingButton
                              size="small"
                              variant="contained"
                              sx={{
                                ".MuiCircularProgress-root": {
                                  color: "primary.main",
                                },
                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              loading={loading}
                              disabled={
                                quantity !== 0 &&
                                store?.is_compare?.selcetedCalculators?.length >
                                  0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                setLoading(true);

                                API.post(
                                  "/cost/calculation/",
                                  {
                                    object_id: selectedMateialItem,
                                    calculators:
                                      store?.is_compare?.selcetedCalculators?.map(
                                        (item: any) => item.id
                                      ),
                                    qty: parseInt(quantity),
                                    category: 0,
                                    multiple: true,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    dispatch(selectedCalculators([]));
                                    getMaterialItemData();
                                    Swal.fire({
                                      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                              <br />
                              <p style="color:#007fff;"> Overheads added successfully
                              </p>   
                               </div>`,
                                    });
                                    setLoading(false);
                                    setProccedButtonStatus(true);
                                  })
                                  .catch((err: any) => {
                                    setLoading(false);
                                  });
                              }}
                            >
                              Add Cost Model
                            </LoadingButton>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              disabled={proceedButtonStatus ? false : true}
                              onClick={() => {
                                // API.post(
                                //   "cost/cost_calculator/",
                                //   {
                                //     material: materialItemsData
                                //       ?.filter(
                                //         (item: any) => item?.calculator_mapped === true
                                //       )
                                //       .map((item: any) => item?.id),
                                //   },
                                //   {},
                                //   0
                                // )
                                //   .then((res: any) => {
                                //     getRMData();
                                //     parentClose();
                                //     onCloseModal();
                                //   })
                                //   .catch((err: any) => { });
                                history.push(
                                  `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${
                                    materialItemsData[0]?.id
                                  }/${
                                    costDetailsData?.cost_estimation
                                  }/${0}/${0}/${0}/0/${scenarioId}`
                                );
                                API.post(
                                  "/cost/cost_calculator/",
                                  {
                                    process: true,
                                    cost_estimation:
                                      costDetailsData?.cost_estimation,
                                    scenario: scenarioId
                                      ? scenarioId
                                      : undefined,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    getRMData();
                                    onCloseModal();
                                    parentClose();
                                  })
                                  .catch((err: any) => {});
                              }}
                            >
                              Proceed
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
