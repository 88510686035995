import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { ADMIN } from '../../../Redux/Services/admin.service';
import { Autocomplete, FormControl, MenuItem, Select, TextField, Divider, InputLabel, Input, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { userPermissionAction } from './BOMEntryLandingPage';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api-services';
import Swal from 'sweetalert2';
import { last, set } from 'lodash';
import { ImageInputViewBox } from "./ImageInputViewBox";
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { table } from 'console';
import { ImageEdit } from 'material-ui/svg-icons';
import { ImageEditViewBox } from './ImageEditViewBox';
import Switch from '@mui/material/Switch';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import SelectDuplicate from './SelectDuplicate';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import PreviewIcon from "@mui/icons-material/Preview";
import RViewerJS from "viewerjs-react";


import { SelectChangeEvent } from '@mui/material/Select';
import PCBS3Upload from '../../../DataBaseModule/Costing/PCBS3Upload';


type Props = {}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 60vw",
  height: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IEditPCBModalProps {
  onCloseModal: any;
  isOpen: any;
  tableData: any;
  key1: any;
  val: any;
  setDataForUpdate: any;
  target: any;
  AllData: any;
  setColorButton: any;
  setShowButton: any;
  setCounter: any;
  options: any;
  itmKey: any;
  currentPage: any;
  counter: any;
  type: any;
  mappKeyValue: any;
  tableIdddList: any;
  initialData: any;
  setSelectedOptions: any;
  setTableDataCount?: any;
  lastRow: any;

}

export default function EditModalForm(props: IEditPCBModalProps) {
  const { onCloseModal, isOpen, tableData, key1, val, target, AllData, setCounter, setDataForUpdate, setColorButton, setShowButton, options, itmKey, currentPage, counter, type, mappKeyValue, tableIdddList, initialData, setSelectedOptions, setTableDataCount, lastRow } = props;
  const [isSave, setIsSave] = React.useState<any>(false);
  const [isDeleting, setIsDeleting] = React.useState<any>(false);
  const [saveDisabled, setSaveDisabled] = React.useState<any>(true);
  const [totalPercentage, setTotalPercentage] = React.useState<any>(0);
  const selectDuplicate = useBaseModal();









  // let currentTarget: any;
  // if(tableData?.[0]?.total_length > 75){
  //   currentTarget = 75 - target -1;
  // }
  // else {
  //   currentTarget = tableData?.[0]?.total_length - target -1;
  // }


  const currentTarget = tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75;
  const [rowData, setRowData] = React.useState<any>(tableData?.[0]?.data?.[tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75]);
  const [rowPrevData, setRowPrevData] = React.useState<any>(lastRow?.[0]);
  const { vault, topVault, projectId } = useRouteParams<any>();
  const [name, setName] = React.useState<any>(rowData?.[mappKeyValue['Item Name']]?.length > 0 ? true : false);
  const [description, setDescription] = React.useState<any>(rowData?.[mappKeyValue['Item Description']]?.length > 0 ? true : false);
  const [length, setLength] = React.useState<any>(Number(rowData?.[mappKeyValue['Length']]) != -1 ? true : false);
  const [width, setWidth] = React.useState<any>(Number(rowData?.[mappKeyValue['Width']]) != -1 ? true : false);
  const [componentType, setComponentType] = React.useState<any>(rowData?.[mappKeyValue['Component Type']]?.length > 0 ? true : false);
  const [reference, setReference] = React.useState<any>(rowData?.[mappKeyValue['Reference']]?.length > 0 ? true : false);
  const [unitCost, setUnitCost] = React.useState<any>(Number(rowData?.[mappKeyValue['Unit Cost']]) > 0 ? true : false);
  const [isItemNameChanged, setIsItemNameChanged] = React.useState<any>(false);
  const [isItemDescriptionChanged, setIsItemDescriptionChanged] = React.useState<any>(false);
  const [initialItemName, setInitialItemName] = React.useState<any>(rowData?.[mappKeyValue['Item Name']]);
  const [initialItemDescription, setInitialItemDescription] = React.useState<any>(rowData?.[mappKeyValue['Item Description']]);

  const [isAnythingChanged, setIsAnythingChanged] = React.useState<any>(false);
  const [flag, setFlag] = React.useState<any>();
  const [checkDuplicateShow, setCheckDuplicateShow] = React.useState<any>(false);
  const [revisionList, setRevisionList] = React.useState<any>();
  const [disabledFeilds, setDisabledFeilds] = React.useState<any>(rowData?.[mappKeyValue['Item Name']]?.length > 0 && rowData?.[mappKeyValue['Item Description']]?.length > 0);
  const [revision, setRevision] = React.useState<any>(true);
  const [selectedRevision, setSelectedRevision] = React.useState<any>();
  const [formType, setFormType] = React.useState<any>();

  const [flagS3Url, setFlagS3Url] = React.useState<any>();
  const [uploadFile, setUploadFile] = React.useState<any>(false);
  const ImageViewer = React.useRef<any>();
  const [srcImages, setSrcImages] = React.useState<any>();
  const [assumptionCardList, setAssumptionCardList] = React.useState<any>();
  const [assumptionCard, setAssumptionCard] = React.useState<any>([]);
  const [disableItemDescriptionAndName, setDisableItemDescriptionAndName] = React.useState<any>(false);
  const [revisionData, setRevisionData] = React.useState<any>();
  const [flagUploaded, setFlagUploaded] = React.useState<any>(false);



  React.useEffect(() => {
    if (type == "edit") {
      setRowData(tableData?.[0]?.data?.[tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75]);
    }
    else {
      setRowData(initialData);
    }
  }, [target, currentPage, tableData]);




  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeFile = async (e: any) => {
    setFlag(e.currentTarget.files);
    await PCBS3Upload(e.currentTarget.files, "PCBS3Upload", setFlagS3Url, setUploadFile, setFlagUploaded);
  };
  // React.useEffect(() => {
  //   if (type == "add") {

  //     setRowPrevData(AllData?.slice(-1)[0]);
  //   }
  //   else {

  //     setRowPrevData(AllData?.slice(-1)[0]);
  //   }
  // }, []);

  const handleClick = (srcImg: any, e: any) => {
    
    e.stopPropagation();
    if (srcImg?.length > 0 && srcImg?.split('/').pop()?.split('.').pop() != "pdf") {
      ImageViewer.current.src = srcImg;
      ImageViewer.current?.click?.();
      setSrcImages(srcImg);
    }
    else{
      const encodedString = srcImg.replace(/\s/g, "%20");

      window.open(encodedString, "_blank");
    }
  };

  // React.useEffect(() => {
  // }, [rowData]);

  const getAssumptionCard = () => {
    API.get(`/api/db/location_revision/`, {
      project: projectId,
      revision_main: true,
      top_vault: topVault,


    }, 0)
      .then((res: any) => {
        // console.log(res?.data);
        setAssumptionCardList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      })
  }

  const checkDuplicate = (itemName: any, itemDescription: any) => {
    if(isItemNameChanged || isItemDescriptionChanged){

    

    API.get(`/xcpep/pcbcosting_redirection/`, {
      project: projectId,
      item_name: itemName,
      item_description: itemDescription,
      top_vault: topVault,
    }, 0)
      .then((res: any) => {

        if (res?.data?.length > 0) {
          setCheckDuplicateShow(true);
          setRevisionList(res?.data);


          setDisabledFeilds(true);
          // setDisableItemDescriptionAndName(true);
        }
        else {
          setCheckDuplicateShow(false);
          setDisabledFeilds(false);

        }
        setIsItemDescriptionChanged(false);
        setIsItemNameChanged(false);

        // selectDuplicate.open();

      })
      .catch((err: any) => {
        console.log(err);
      })
    }


  }

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  React.useEffect(() => {
    if (tableData?.[0]?.key != tableIdddList['PCB BOM Table'] || type == "edit") {
      setSaveDisabled(!isAnythingChanged);
    }

  }, [isAnythingChanged]);

  const calculateTotalPercentage = () => {
    let total = 0;
    rowData && Object.keys(rowData).map((item: any) => {
      total += Number(rowData?.[item]);
    }
    )
    setTotalPercentage(total);
  }

  const handleDataAdd = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    if (tableData?.[0]?.key == tableIdddList['PCB BOM Table']) {
      
      handleDataPCBAdd(e);
    }
    else {
      handleOtherAdd(e);
    }
    setSelectedOptions([]);
    // setTableDataCount((prev: any) => prev + 1);
  }

  React.useEffect(() => {
    calculateTotalPercentage();
  }, [rowData]);

  React.useEffect(() => {
    getAssumptionCard();
  }, []);



  const handleClickOnSave = (e:any) => {
    if (checkDuplicateShow) {
      selectDuplicate.open();
    }
    else {
      handleDataAdd(e)
    }
  }

  const handleOtherAdd = (e:any) => {
    e.preventDefault();
    e.stopPropagation();

    API.put(
      "/xcpep/component_details/74699/",
      {
        param_value: rowData,
        index: target,
        table_id: tableData?.[0]?.key,
      },
      {
        ...getObjKeyName(),
      },

      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Data Updated Successfully");

        setIsSave(false);
        setCounter((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        setIsSave(false);
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"green">${data[0]}</p>   
                 </div>`,
          customClass: {
            container: "swal2Container",
          },
        });
      });
    onCloseModal();
  }

  

  const handleDataPCBAdd = (e:any) => {
    e.preventDefault();
    e.stopPropagation();


    if (formType == "Revision") {
      API.put(
        "/xcpep/component_details/74699/",
        {
          param_value: {...rowData, [mappKeyValue['Item Name']]: rowData?.[mappKeyValue['Item Name']].trim() , [mappKeyValue['Item Description']]: rowData?.[mappKeyValue['Item Description']].trim() , [mappKeyValue['Quantity']] : rowData?.[mappKeyValue['Quantity']] == 0 ? 1 : rowData?.[mappKeyValue['Quantity']]},
          index: target,
          table_id: tableData?.[0]?.key,
          purchase_item: selectedRevision?.purchase_item,
          revision: selectedRevision?.revision,
          flag: flagS3Url,
        },
        {
          ...getObjKeyName(),
        },

        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Data Updated Successfully");

          setIsSave(false);
          setCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          setIsSave(false);
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                  <br />
                  <p style="color:"green">${data[0]}</p>   
                   </div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        });
      onCloseModal();
    }
    else {
      if (formType == "Database") {
        API.put(
          "/xcpep/component_details/74699/",
          {
            param_value: {...rowData, [mappKeyValue['Item Name']]: rowData?.[mappKeyValue['Item Name']].trim() , [mappKeyValue['Item Description']]: rowData?.[mappKeyValue['Item Description']].trim() , [mappKeyValue['Quantity']] : rowData?.[mappKeyValue['Quantity']] == 0 ? 1 : rowData?.[mappKeyValue['Quantity']]},
            index: target,
            table_id: tableData?.[0]?.key,
            purchase_item: selectedRevision?.purchase_item,
            assumption_list: assumptionCard,
            flag: flagS3Url,
          },
          {
            ...getObjKeyName(),
          },

          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Data Updated Successfully");

            setIsSave(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setIsSave(false);
            const { data } = err.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
              customClass: {
                container: "swal2Container",
              },
            });
          });
        onCloseModal();
      }
      else {
        API.put(
          "/xcpep/component_details/74699/",
          {
            param_value: {...rowData, [mappKeyValue['Item Name']]: rowData?.[mappKeyValue['Item Name']].trim() , [mappKeyValue['Item Description']]: rowData?.[mappKeyValue['Item Description']].trim() , [mappKeyValue['Quantity']] : rowData?.[mappKeyValue['Quantity']] == 0 ? 1 : rowData?.[mappKeyValue['Quantity']]},
            index: target,
            table_id: tableData?.[0]?.key,
            assumption_list: assumptionCard,
            flag: flagS3Url,
          },
          {
            ...getObjKeyName(),
          },

          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Data Updated Successfully");

            setIsSave(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setIsSave(false);
            const { data } = err.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
              customClass: {
                container: "swal2Container",
              },
            });
          });
        onCloseModal();
      }

    }

    // API.put(
    //   "/xcpep/component_details/74699/",
    //   {
    //     param_value: {...rowData, assumption_list: assumptionCard},
    //     index: target,
    //     table_id: tableData?.[0]?.key,
    //   },
    //   {
    //     ...getObjKeyName(),
    //   },

    //   0
    // )
    //   .then((res: any) => {
    //     ADMIN.toast.info("Data Updated Successfully");

    //     setIsSave(false);
    //     setCounter((prev: any) => prev + 1);
    //   })
    //   .catch((err: any) => {
    //     setIsSave(false);
    //     const { data } = err.response;
    //     Swal.fire({
    //       icon: "error",
    //       html: `<div>
    //             <br />
    //             <p style="color:"green">${data[0]}</p>   
    //              </div>`,
    //       customClass: {
    //         container: "swal2Container",
    //       },
    //     });
    //   });
    // onCloseModal();

  }



  const handleDelete = () => {



    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      customClass: {
        container: "swal2Container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsDeleting(true);
        // setSelectedIdDelete(ind);
        API.put(
          "/xcpep/component_details/74699/",
          {
            table_id: tableData?.[0]?.key,
            index: target,
          },
          {
            ...getObjKeyName(),
          },
          0
        )
          .then((res: any) => {
            setIsDeleting(false);
            // setSelectedIdDelete("");
            setColorButton("");
            setShowButton(false);

            setCounter((prev: any) => prev + 1);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsDeleting(false);
            onCloseModal();
          });
      } else if (
        result.dismiss ===
        Swal.DismissReason.cancel
      ) {
        Swal.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
          customClass: {
            container: "swal2Container",
          },
        });
      }
    });




  }

  // const handleCloseModal = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "This action will discard all of your unsaved data. Please make sure that everything has been saved before proceeding.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, Save and Close",
  //     cancelButtonText: "No",
  //     customClass: {
  //       container: "swal2Container",
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {

  //       handleDataAdd()
  //       onCloseModal();
  //     }
  //   });
  // };

  const handleRedirectiontoDB = () => {
    if (formType == "Revision") {
      const date = selectedRevision?.revised_on.split("-");

      const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


      const databaseName = "Electronic Child Components"
      const searchKeywords = `${rowData?.[mappKeyValue['Item Name']]},${rowData?.[mappKeyValue['Item Description']]}`
      const url = `#/data-base/revision-database/1/${date[0]}/${monthName[date[1] - 1]}/${selectedRevision?.revision}/${selectedRevision?.revision}/bop/${databaseName}/true/${rowData?.[mappKeyValue['Item Name']]}`;
      window.open(url, "_blank");
    }
    else {
      const databaseName = "Electronic Child Components"
      const searchKeywords = `${rowData?.[mappKeyValue['Item Name']]},${rowData?.[mappKeyValue['Item Description']]}`
      const url = `#/data-base/revision-database/1/0/0/0/0/bopDB/${databaseName}/true/${rowData?.[mappKeyValue['Item Name']]}`
      window.open(url, "_blank");
    }

  }


  const disableAllandCheckDuplicates = () => {

    if (rowData?.[mappKeyValue['Item Description']] != '' && rowData?.[mappKeyValue['Item Name']] != '' && rowData?.[mappKeyValue['Item Description']] && rowData?.[mappKeyValue['Item Name']] && rowData?.[mappKeyValue['Item Description']]?.length > 0 && rowData?.[mappKeyValue['Item Name']]?.length > 0) {

      checkDuplicate(rowData?.[mappKeyValue['Item Name']], rowData?.[mappKeyValue['Item Description']]);
    }
    else {

    }
  }

  React.useEffect(() => {
    if(type == "edit"){
    API.get(`/xcpep/pcbcosting_redirection/`, {
      project: projectId,
      item_name: rowData?.[mappKeyValue['Item Name']],
      item_description: rowData?.[mappKeyValue['Item Description']],
      top_vault: topVault,
      get_selected_assumption: true,
      vault: vault,
    }, 0)
      .then((res: any) => {

        setRevisionData(res?.data);
        // setAssumptionCard(res?.data);

      })
      .catch((err: any) => {
        console.log(err);
      })
    }
  }, [assumptionCardList]);

  const handleRedirection = () => {
    const date = revisionData?.revised_on.split("-");

    const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const searchKeywords = `${rowData?.[mappKeyValue['Item Name']]},${rowData?.[mappKeyValue['Item Description']]}`
    const databaseName = "Electronic Child Components"
    const url = `#/data-base/revision-database/1/${date[0]}/${monthName[date[1] - 1]}/${revisionData?.id}/${revisionData?.id}/bop/${databaseName}/true/${rowData?.[mappKeyValue['Item Name']]}`;
    window.open(url, "_blank");    
  }

  const handleClose = () => {
    if (type == "edit") {
      onCloseModal();
    }
    else {
      API.put(
        "/xcpep/component_details/74699/",
        {
          table_id: tableData?.[0]?.key,
          index: target,
        },
        {
          ...getObjKeyName(),
        },
        0
      )
        .then((res: any) => {
          setIsDeleting(false);
          // setSelectedIdDelete("");
          setColorButton("");
          setShowButton(false);

          setCounter((prev: any) => prev + 1);
          onCloseModal();
        })
        .catch((err: any) => {
          setIsDeleting(false);
          onCloseModal();
        });
    }

  }



  return (

    <Modal
      open={isOpen}

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style} adl-scrollbar="true"
                adl-scrollbar-width="0.3">
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? <Typography sx={{ display: "flex", gap: "0.2rem", width: "15%" }}>Last Entry: <Tooltip title={
            <React.Fragment>
              <Typography color="inherit">S No. : {rowPrevData?.[mappKeyValue['Sl No.']]}</Typography>
              <Typography color="inherit">Item Name : {rowPrevData?.[mappKeyValue['Item Name']]}</Typography>
              <Typography color="inherit">Item Description : {rowPrevData?.[mappKeyValue['Item Description']]}</Typography>
              <Typography color="inherit">Component Type : {rowPrevData?.[mappKeyValue['Component Type']]}</Typography>
              <Typography color="inherit">Quantity : {rowPrevData?.[mappKeyValue['Quantity']]}</Typography>
              <Typography color="inherit">Length : {rowPrevData?.[mappKeyValue['Length']]}</Typography>
              <Typography color="inherit">Width : {rowPrevData?.[mappKeyValue['Width']]}</Typography>
              <Typography color="inherit">Unit Cost : INR {rowPrevData?.[mappKeyValue['Unit Cost']]}</Typography>
              <Typography color="inherit">Reference : {rowPrevData?.[mappKeyValue['Reference']]}</Typography>
              {/* <Typography color="inherit">Flag : {rowPrevData?.[mappKeyValue['Flag']]}</Typography>
              <Typography color="inherit">Revision : {rowPrevData?.[mappKeyValue['Revision']]}</Typography> */}


            </React.Fragment>
          }><InfoIcon /></Tooltip></Typography> : ""}
          <Typography id="modal-modal-title" style={{
            fontSize: "1rem",
            fontWeight: "600",
            width: "100%",
            textAlign: "center",
          }}>
            {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? (type == "edit" ? "Edit ECP Data" : "Add ECP Data") : "Edit Row Data"}
          </Typography>
          {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] && type == "add" && (formType == "Revision" || formType == "Database") ? <Button variant='outlined' sx={{ cursor: 'pointer', margin: '0.5rem', width: "25%" }} onClick={handleRedirectiontoDB} >{formType == "Revision" ? "Go To Revision" : "Go To Database"}</Button> : null}
          {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] && type == "edit" ? <Button variant='outlined' sx={{ cursor: 'pointer', margin: '0.5rem', width: "25%" }} onClick={handleRedirection} > Go to Revision </Button> : null}
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />

        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] && ( 
        <>
          <Typography sx={{ display: "flex", gap: "0.2rem", width: "100%", padding: "0.5rem" }}>Database Parameters</Typography>
        </>
        )}
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', rowGap: '1rem', margin: "1rem", columnGap: "1rem" }}>
          {
            tableData?.[0]?.key == tableIdddList['PCB BOM Table'] && (
              <>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", }}>Item Name<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"

                    variant="outlined"
                    type="text"
                    disabled={disabledFeilds && disableItemDescriptionAndName}
                    value={rowData?.[mappKeyValue['Item Name']] || ''}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      if (e.target.value == '') {
                        setRowData({ ...rowData, [mappKeyValue['Item Name']]: e.target.value });
                        setName(false);
                        setSaveDisabled(true);
                        setIsItemNameChanged(true);
                        setIsAnythingChanged(true);


                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Item Name']]: e.target.value });
                        setSaveDisabled(description && length >= 0 && width >= 0 && componentType && reference && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setName(true);
                        setIsItemNameChanged(true);
                        setIsAnythingChanged(true);
                      }
                    }}
                    onBlur={disableAllandCheckDuplicates}
                    autoFocus={mappKeyValue['Item Name'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", }}>Item Description<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"
                    disabled={disabledFeilds && disableItemDescriptionAndName}
                    variant="outlined"
                    type="text"
                    value={rowData?.[mappKeyValue['Item Description']] || ''}
                    onBlur={disableAllandCheckDuplicates}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      if (e.target.value == '') {
                        setRowData({ ...rowData, [mappKeyValue['Item Description']]: e.target.value });
                        setDescription(false);
                        setSaveDisabled(true);
                        setIsItemDescriptionChanged(true);
                        setIsAnythingChanged(true);

                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Item Description']]: e.target.value });
                        setDescription(true);
                        setIsItemDescriptionChanged(true);
                        setSaveDisabled(name && length >= 0 && width >= 0 && componentType && reference && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    autoFocus={mappKeyValue['Item Description'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", }}>Component Type<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"

                    variant="outlined"
                    type="text"
                    value={rowData?.[mappKeyValue['Component Type']] || ''}
                    disabled={disabledFeilds}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      if (e.target.value == '') {
                        setRowData({ ...rowData, [mappKeyValue['Component Type']]: e.target.value });
                        setComponentType(false);
                        setSaveDisabled(true);
                        setIsAnythingChanged(true);

                      }
                      else {

                        setRowData({ ...rowData, [mappKeyValue['Component Type']]: e.target.value });
                        setComponentType(true);
                        setSaveDisabled(name && description && length >= 0 && width >= 0 && reference && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    autoFocus={mappKeyValue['Component Type'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />
                </Box>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem" }}>Length<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={rowData?.[mappKeyValue['Length']] || 0}
                    disabled={disabledFeilds}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      // if (itmKey == 90) {
                      //   setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                      // }
                      if (Number(e.target.value) <= 0) {
                        setRowData({ ...rowData, [mappKeyValue['Length']]: e.target.value });
                        setLength(false);
                        setSaveDisabled(true);
                        setIsAnythingChanged(true);

                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Length']]: e.target.value });
                        setLength(true);
                        setSaveDisabled(name && description && componentType && width >= 0 && reference && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    autoFocus={mappKeyValue['Length'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />

                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem" }}>Width<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={rowData?.[mappKeyValue['Width']] || 0}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      // if (itmKey == 90) {
                      //   setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                      // }
                      if (Number(e.target.value) <= 0) {
                        setRowData({ ...rowData, [mappKeyValue['Width']]: e.target.value });
                        setWidth(false);
                        setSaveDisabled(true);
                        setIsAnythingChanged(true);

                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Width']]: e.target.value });
                        setWidth(true);
                        setSaveDisabled(name && description && componentType && length >= 0 && reference && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    disabled={disabledFeilds}
                    autoFocus={mappKeyValue['Width'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />


                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem" }}>Unit Cost<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={rowData?.[mappKeyValue['Unit Cost']] || ''}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      // if (itmKey == 90) {
                      //   setTotalPercentage(totalPercentage - rowData?.[1294] + Number(e.target.value));
                      // }
                      if (Number(e.target.value) <= 0) {
                        setRowData({ ...rowData, [mappKeyValue['Unit Cost']]: e.target.value });
                        setUnitCost(false);
                        setSaveDisabled(true);
                        setIsAnythingChanged(true);

                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Unit Cost']]: e.target.value });
                        setUnitCost(true);
                        setSaveDisabled(name && description && componentType && length >= 0 && width >= 0 && reference && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    disabled={disabledFeilds && revision}
                    autoFocus={mappKeyValue['Unit Cost'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />

                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", }}>Reference<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"

                    variant="outlined"
                    type="text"
                    value={rowData?.[mappKeyValue['Reference']] || ''}
                    disabled={disabledFeilds && revision}
                    onChange={(e) => {
                      // let temp = [...val]
                      // temp[index] = e.target.value
                      if (e.target.value == '') {
                        setRowData({ ...rowData, [mappKeyValue['Reference']]: e.target.value });
                        setReference(false);
                        setSaveDisabled(true);
                        setIsAnythingChanged(true);

                      }
                      else {
                        setRowData({ ...rowData, [mappKeyValue['Reference']]: e.target.value });
                        setReference(true);
                        setSaveDisabled(name && description && componentType && length >= 0 && width >= 0 && unitCost > 0 && assumptionCard?.length > 0 ? false : true)
                        setIsAnythingChanged(true);
                      }
                    }}
                    autoFocus={mappKeyValue['Reference'] === key1 ? true : false}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />
                </Box>
                {type == "add" && formType != "Revision" && formType != "Database" && <Box sx={{
                  width: '100%', display: 'flex',
                  flexDirection: "column", alignItems: 'center', gap: "0.3rem"
                }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem" }}>Flag<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <FormControl fullWidth sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "0.3rem" }}>
                    {/* <InputLabel shrink
                id="demo-simple-select-label-Activity"
                sx={{ color: "primary.main", marginLeft: '-1rem', marginTop: '0.5rem' }}>
                Flag
              </InputLabel> */}
                    <TextField
                      type="file"
                      style={{ fontSize: '1rem', flexGrow: "1" }}
                      inputProps={{
                        accept: "image/*,application/pdf",
                      }}
                      onChange={handleChangeFile}
                    // disabled={disabledFeilds && (formType == "Revision" || formType == "Database")}
                    />
                    {uploadFile && <CircularProgress size={20} sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />}
                    <PreviewIcon
                      sx={{
                        cursor: flagS3Url?.length > 0 ? "pointer" : "not-allowed",
                        color: flagS3Url?.length > 0 ? "green" : "red",
                      }}
                      onClick={(e: any) => {
                        handleClick(`${tableData?.[0]?.header_item?.[0]?.cdn}${flagS3Url}`, e);
                      }}
                    />
                  </FormControl>
                  <div style={{ display: "none" }}>
                    {/* @ts-ignore */}
                    <RViewerJS>
                      <img src={ImageViewer.current?.src} alt="image" ref={ImageViewer} />
                    </RViewerJS>
                  </div>

                </Box>}
                {formType != "Revision"  && (initialItemName != rowData?.[mappKeyValue['Item Name']] || initialItemDescription != rowData?.[mappKeyValue['Item Description']])  && <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem" }}>Select Assumption Card<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={assumptionCard}

                      // autoFocus={item?.key === key1 ? true : false}
                      multiple
                      disabled={disabledFeilds && revision || uploadFile}
                      onChange={(e) => {
                        setAssumptionCard(e.target.value);

                        if (rowData?.[mappKeyValue["Unit Cost"]] > 0 && e.target.value.length > 0 && rowData?.[mappKeyValue["Reference"]] && rowData?.[mappKeyValue["Reference"]].length > 0 && rowData?.[mappKeyValue["Component Type"]] && rowData?.[mappKeyValue["Component Type"]].length > 0 && rowData?.[mappKeyValue["Item Description"]] && rowData?.[mappKeyValue["Item Description"]].length > 0 && rowData?.[mappKeyValue["Item Name"]] && rowData?.[mappKeyValue["Item Name"]].length > 0 && flagUploaded) {
                          setSaveDisabled(false);

                        }
                        else {
                          setSaveDisabled(true);
                        }

                        // checkDuplicate(rowData?.[1004], rowData?.[1005]);


                        // setIsAnythingChanged(true);
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                          {selected.map((value: any) => {
                            let selectedOptionsTag: any = assumptionCardList?.filter((item: any) => item.id == value)[0];
                            return (
                              <Chip key={value} label={`${selectedOptionsTag['item_name']} - (${selectedOptionsTag["city"]},${selectedOptionsTag["country"]})`} />
                            )
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      // disabled= {!name || !description || disabledFeilds}
                      sx={{
                        width: '100%',
                        marginTop: '0.5rem !important',
                        "&:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                          fontSize: '1rem'
                        },
                        '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                      }}
                    >
                      {
                        assumptionCardList?.map((item: any) => (
                          <MenuItem value={item.id}>{item.item_name} - ({item?.city},{item?.country})</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>}
                {
                  initialItemName == rowData?.[mappKeyValue['Item Name']] && initialItemDescription == rowData?.[mappKeyValue['Item Description']] && type == "edit" && <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                  <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", }}>Selected Assumption Card<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                  <TextField
                    id="outlined-basic"

                    variant="outlined"
                    type="text"
                    value={`${revisionData?.revision_name} - (${revisionData?.city},${revisionData?.country})`}
                    disabled={true}
                    
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline": { fontSize: "1rem" },
                    }}
                  />
                </Box>
                }
              </>
            )

          }
        </Box>

        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1.2rem",
          }}
        />
        {tableData?.[0]?.key == tableIdddList['PCB BOM Table'] && ( 
        <>
          <Typography sx={{ display: "flex", gap: "0.2rem", width: "100%", padding: "0.5rem", color:"#724A24" }}>BOM Parameters</Typography>
        </>
        )}

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', rowGap: '1rem', margin: "1rem", columnGap: "1rem" }}>
          {
            tableData?.[0]?.header_item?.map((item: any, index: any) => {

              switch (item?.data_type) {
                case "number":
                case "float":
                case "nonzero-positive-int":
                case "nonzero-positive-float":
                case "positive-float":
                case "nonzero-float":
                case "percentage":
                case "positive-int":
                case "integer":
                case "percentage":


                  if (item?.key == mappKeyValue['Total Cost'] || item?.key == mappKeyValue['Length'] || item?.key == mappKeyValue['Width'] || item?.key == mappKeyValue['Unit Cost']) {

                    return;
                  }
                  if (item?.key == mappKeyValue['Quantity']){
                    return (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        disabled={item?.paramter_name == "Result"}
                        value={rowData?.[item?.key] == 0 ? 1 : rowData?.[item?.key]}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          if (itmKey == tableIdddList['Material Percentage']) {
                            setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                          }
                          setRowData({ ...rowData, [item?.key]: e.target.value });



                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          '.MuiInputBase-input.MuiOutlinedInput-input': {
                            fontSize: "1rem",
                            color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>
                    )
                  }
                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        disabled={item?.paramter_name == "Result"}
                        value={rowData?.[item?.key] || 0}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          if (itmKey == tableIdddList['Material Percentage']) {
                            setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                          }
                          setRowData({ ...rowData, [item?.key]: e.target.value });



                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          '.MuiInputBase-input.MuiOutlinedInput-input': {
                            fontSize: "1rem",
                            color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>
                  );
                  case "BOM-table-calculator" :
                    if (item?.key == mappKeyValue['Total Cost'] || item?.key == mappKeyValue['Length'] || item?.key == mappKeyValue['Width'] || item?.key == mappKeyValue['Unit Cost']) {

                      return;
                    }
                    if (item?.key == mappKeyValue['Quantity']){
                      return (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                        <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup></div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          disabled={true}
                          value={rowData?.[item?.key] == 0 ? 1 : rowData?.[item?.key]}
                          onChange={(e) => {
                            // let temp = [...val]
                            // temp[index] = e.target.value
                            if (itmKey == tableIdddList['Material Percentage']) {
                              setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                            }
                            setRowData({ ...rowData, [item?.key]: e.target.value });
  
  
  
                            setIsAnythingChanged(true);
                          }}
                          autoFocus={item?.key === key1 ? true : false}
                          sx={{
                            width: "100%",
                            color: "#000",
                            "& .MuiFormLabel-root": {
                              color: "#000",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#000",
                            },
                            '.MuiInputBase-input.MuiOutlinedInput-input': {
                              fontSize: "1rem",
                              color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline": { fontSize: "1rem" },
                          }}
                        />
                      </Box>
                      )
                    }
                    return (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                        <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          disabled={true}
                          value={rowData?.[item?.key] || 0}
                          onChange={(e) => {
                            // let temp = [...val]
                            // temp[index] = e.target.value
                            if (itmKey == tableIdddList['Material Percentage']) {
                              setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                            }
                            setRowData({ ...rowData, [item?.key]: e.target.value });
  
  
  
                            setIsAnythingChanged(true);
                          }}
                          autoFocus={item?.key === key1 ? true : false}
                          sx={{
                            width: "100%",
                            color: "#000",
                            "& .MuiFormLabel-root": {
                              color: "#000",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#000",
                            },
                            '.MuiInputBase-input.MuiOutlinedInput-input': {
                              fontSize: "1rem",
                              color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline": { fontSize: "1rem" },
                          }}
                        />
                      </Box>
                    );

                case "text":
                case "string":



                  if (item?.key == mappKeyValue['Item Name'] || item?.key == mappKeyValue['Item Description'] || item?.key == mappKeyValue['Component Type'] || item?.key == mappKeyValue['Reference']) {

                    return;
                  }

                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <TextField
                        id="outlined-basic"

                        variant="outlined"
                        type="text"
                        value={rowData?.[item?.key] || ''}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          setRowData({ ...rowData, [item?.key]: e.target.value });
                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          '.MuiInputBase-input.MuiOutlinedInput-input': {
                            fontSize: "1rem",
                            color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>
                  );

                case "link":

                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="url"
                        value={rowData?.[item?.key] || ''}

                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          setRowData({ ...rowData, [item?.key]: e.target.value });
                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          '.MuiInputBase-input.MuiOutlinedInput-input': {
                            fontSize: "1rem",
                            color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>
                  );
                case "textarea":
                case "longtext":
                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        multiline
                        rows={3}
                        value={rowData?.[item?.key] || ''}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          setRowData({ ...rowData, [item?.key]: e.target.value });
                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          '.MuiInputBase-input.MuiOutlinedInput-input': {
                            fontSize: "1rem",
                            color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? '#000000' : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>
                  );

                case "dropdown":
                case "singleselect":
                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={rowData?.[item?.key] || ''}
                          autoFocus={item?.key === key1 ? true : false}
                          onChange={(e) => {
                            setRowData({ ...rowData, [item?.key]: e.target.value })
                            setIsAnythingChanged(true);
                          }}
                          sx={{
                            width: '100%',
                            marginTop: '0.5rem !important',
                            "&:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                              color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main",
                              fontSize: '1rem'
                            },
                            '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                          }}
                        >
                          {
                            item?.allowed_values?.map((item: any) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Box>
                  );

                case "boolean":
                  if (item?.key == mappKeyValue['is_validated']) {
                    return;
                  }
                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <FormControl fullWidth>
                        <Switch
                          id="demo-simple-select"
                          checked={rowData?.[item?.key] == true ? true : false}
                          autoFocus={item?.key === key1 ? true : false}
                          onChange={(e) => {
                            setRowData({ ...rowData, [item?.key]: e.target.checked })
                            // setIsAnythingChanged(true);
                          }}
                          sx={{
                            // width: '100%',
                            marginTop: '0.5rem !important',
                            "&:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                              color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main",
                              fontSize: '1rem'
                            },
                            '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                          }}
                        />

                      </FormControl>
                    </Box>
                  )

                case "image":

                  return (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                      <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "start", minHeight: "4rem" }}>
                        <ImageEditViewBox
                          key1={item?.key}
                          val={rowData?.[item?.key]}
                          setDataForUpdate={setDataForUpdate}
                          target={target}
                          AllData={tableData}
                          allOptions={tableData?.[0]?.header_item?.filter((itemmm: any) => itemmm?.key === item?.key) && tableData?.[0]?.header_item?.filter((itemmm: any) => itemmm?.key === item?.key)?.[0]?.allowed_values}
                          option={tableData?.[0]?.header_item?.filter((itemmm: any) => itemmm?.key === item?.key)}
                          itmKey={itmKey}
                          setColorButton={setColorButton}
                          setShowButton={setShowButton}
                          setCounter={setCounter}
                          setSaveDisabled={setIsAnythingChanged}
                          rowData={rowData}
                          setRowData={setRowData}

                        />
                      </div>
                    </Box>
                  );

                case "multiselect":
                  return (<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.3rem" }}>
                    <div style={{ width: '100%', alignSelf: "left", fontSize: "1.2rem", color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main" }}>{item?.paramter_name}</div>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      limitTags={1}
                      options={item?.allowed_values}

                      value={rowData?.[item?.key] || []}
                      renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: '12px' }}>
                          {option}
                        </li>
                      )}
                      onChange={(e, newSystemValue) => {
                        setRowData({ ...rowData, [item?.key]: [...newSystemValue] });
                        setIsAnythingChanged(true);

                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus={item?.key === key1 ? true : false}
                          variant="standard"
                          placeholder="Select"
                          sx={{
                            marginTop: '1rem',
                            '& .MuiInput-underline:before': {
                              borderBottomColor: 'primary.light',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'primary.main',
                            },
                            '.MuiSvgIcon-root': { color: 'primary.main' },
                          }}
                        />
                      )}
                      sx={{
                        // margin: "0.5rem",
                        width: "97%",
                        "& .MuiFormLabel-root": {
                          color: "#007fff",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: tableData?.[0]?.key == tableIdddList['PCB BOM Table'] ? "#724A24" : "primary.main",
                          borderColor: "primary.main",
                        },
                        "&:focus-visible": {
                          outline: "none",
                          borderColor: "#007fff",
                        },
                        "&:hover": {
                          // outline: "none",
                          borderColor: "#007fff",
                        },
                      }}
                    />
                  </Box>
                  );
              }
            }
            )


          }

        </Box>


        <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center", }}>
          {itmKey == tableIdddList['Material Percentage'] && <Typography sx={{ width: "100%", flexGrow: "1", fontSize: "1rem", color: "red", margin: "1rem 0rem", padding: "0rem 0.5rem" }}>*Sum of all parameters must be 100%</Typography>}
          {itmKey == tableIdddList['Material Percentage'] && <Typography sx={{ width: "100%", fontSize: "1rem", margin: "1rem 0rem", padding: "0rem 0.5rem" }}>Total Percentage: {totalPercentage}%</Typography>}
          <Box sx={{ width: '100%', display: "flex", justifyContent: "end", alignItems: "center", gap: "0.5rem", margin: "1rem 0rem", padding: "0rem 0.5rem" }}>
            <LoadingButton variant="outlined" color="error" loading={isDeleting} onClick={() => handleDelete()}>Delete Row</LoadingButton>
            {itmKey == tableIdddList['Material Percentage'] && <LoadingButton variant="contained" loading={isSave} disabled={saveDisabled || totalPercentage != 100} onClick={(e:any) => handleDataAdd(e)}>Save</LoadingButton>}
            {/* check based on name and description any duplicate is present or not -> if yes check for duplicates and if not save the data */}
            {itmKey != tableIdddList['Material Percentage'] && <LoadingButton variant="contained" loading={isSave} disabled={saveDisabled  && !checkDuplicateShow} onClick={(e:any) => handleClickOnSave(e)}>{checkDuplicateShow ? "Check Duplicate" : "Save"}</LoadingButton>}
          </Box>
        </Box>
        {
          selectDuplicate.isOpen &&
          <SelectDuplicate isOpen={selectDuplicate.open} onCloseModal={selectDuplicate.close} revisionList={revisionList} rowData={rowData}
            index={target} table_id={tableData?.[0]?.key} closeParentModal={onCloseModal} setCounter={setCounter} setCheckDuplicateShow={setCheckDuplicateShow} setRevision={setRevision} setDisabledFeilds={setDisabledFeilds} setSelectedRevision={setSelectedRevision} setFormType={setFormType} mappKeyValue={mappKeyValue} setDisableItemDescriptionAndName={setDisableItemDescriptionAndName} setSaveDisabled={setSaveDisabled} />

        }
      </Box>

    </Modal>

  )

}