import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import {
  Box,
  Button,
  Divider,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90%',
  height: { lg: "90vh", xl: "85vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  transition: "none !important", // Disable the transition animation
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setParentCount: any;
  handleChangeCheckBox: any;
}

const AddParameterModal = (props: AddParameterModalProps) => {
  const {
    isOpen,
    onCloseModal,
    setCount,
    setParentCount,
    handleChangeCheckBox,
  } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const classes = useStyles();
  const [paramsData, setParamsData] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<any>(undefined);
  const [dataTypes, setDataTypes] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
  const [selecteParameter, setselecteParameter] = useState<any>([]);
  const [searchKeys, setSearchKeys] = useState<any>(null);
  const [searchvalue, setSearchvalue] = useState<any>(null);
  const [selectedTab, setSelectedTab] = React.useState('');
  const [tempselectedParameters, setTempSelectedParameters] =
    React.useState<any>([]);

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     event.stopPropagation();
  //     setExpanded(newExpanded ? panel : false);
  //   };
  const handleChange = (name: any) => {
  setSelectedTab(name)
  };

  // const getParamsData = () => {
  //   API.get("/api/db/parameter/", {}, 0)
  //     .then((res: any) => {
  //       setParamsData(res.data);
  //     })
  //     .catch((err: any) => {});
  // };
  const getDataTypes = () => {
    API.get(
      "/config/parameter_types/",
      {
        project: projectId,
        module: 6,
        top_vault: productId,
        category: categoryId,
      },
      0
    )
      .then((res: any) => {
        // setDataTypes(res.data);

        // Sort the received data by parameter_group in ascending order
        const sortedData = res?.data.sort((a: any, b:any) => a.parameter_group - b.parameter_group);
        setDataTypes(sortedData);
      })
      .catch((err: any) => { });
  };
  const getParametersByDataType = (type: any) => {
    setIsLoading(true);
    API.get(
      "/api/db/parameter/",
      {
        project: projectId,
        module: 6,
        data_types: type,
        category: categoryId,
        top_vault: productId,
      },
      0
    )
      .then((res: any) => {
        setParamsData(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    if (selectedTab) {
      getParametersByDataType(selectedTab);
    }
  }, [selectedTab]);

  React.useEffect(() => {
    if (datatype !== 0) {
      setSelectedTab(datatype);
    } else {
      setSelectedTab("0");
    }
  }, [categoryId, datatype]);

  React.useEffect(() => {
    getDataTypes();
  }, [projectId]);
  const handleChangeCheck = (e: any, item: any) => {
    if (tempselectedParameters.includes(item?.id)) {
      setTempSelectedParameters((prev: any) =>
        prev.filter((itm: any) => itm !== item?.id)
      );
      setselecteParameter((prev: any) =>
        prev.filter((itm: any) => itm !== item?.id)
      );
    } else {
      setselecteParameter((prev: any) => [...prev, item]);
      setTempSelectedParameters((prev: any) => [...prev, item?.id]);
    }
  };

  const columnsFiled = CellNameOne.includes(selectedTab) ? (
    <TableHead
      sx={{ backgroundColor: "primary.light", position: "sticky", top: "0" }}>
      <TableRow>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}></TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            minWidth:'12rem',
          }}>
          Parameter Name
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Description
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Unit
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Exceptions
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Allowed Values
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Prohibited Values
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Default Values
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Guideline
        </TableCell>

        {/* <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Show in Tag</TableCell>
        <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Is required?</TableCell>
        <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Work Station</TableCell> */}
      </TableRow>
    </TableHead>
  ) : CellNameSecond.includes(selectedTab) ? (
    <TableHead
      sx={{ backgroundColor: "primary.light", position: "sticky", top: "0" }}>
      <TableRow>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}></TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            minWidth:'12rem',
          }}>
          Parameter Name
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Description
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Guideline
        </TableCell>
        {/* <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Show in Tag</TableCell>
        <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Is required?</TableCell>
        <TableCell sx={{
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                            }}>Work Station</TableCell> */}
      </TableRow>
    </TableHead>
  ) : CellNameThird.includes(selectedTab) ? (
    <TableHead
      sx={{ backgroundColor: "primary.light", position: "sticky", top: "0" }}>
      <TableRow>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}></TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            minWidth:'12rem',
          }}>
          Parameter Name
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Description
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Unit
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Exceptions
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Allowed Values
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Guideline
        </TableCell>
      </TableRow>
    </TableHead>
  ) : (
    <TableHead
      sx={{ backgroundColor: "primary.light", position: "sticky", top: "0" }}>
      <TableRow>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}></TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            minWidth:'12rem',
          }}>
          Parameter Name
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Description
        </TableCell>

        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}>
          Guideline
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const SaveHandler = () => {
    setIsLoadingSave(true);

    var SelecteData =
      tempselectedParameters &&
      tempselectedParameters?.map((item: any, index: any) => ({
        parameter_id: item,
        required: false,
      }));

    setSelectedTab("");
    API.post(
      "/config/parameter_mapping/",
      {
        project: projectId,
        module: 6,
        category: categoryId,
        top_vault:
          validationId == 0 ? productId?.toString() : validationId?.toString(),
        bom_config: true,
        parameters: SelecteData,
      },
      {},
      0
    )
      .then((res: any) => {
        // setExpanded(false);
        setSelectedTab('0');
        setTempSelectedParameters([]);
        setParentCount((prev: any) => prev + 1);
        handleChangeCheckBox();
        setSearchvalue(null);
        setSearchKeys(null);
        setCount((prev: any) => prev + 1);
        setIsLoadingSave(false);
      })
      .catch((err: any) => {
        setIsLoadingSave(false);
      });
  };

  const handleSearch = (event: any) => {
    setSearchKeys(event?.target.value);
    if (event?.target.value.length == 0) {
      getDataTypes();
      setSearchKeys(null);
      setSearchvalue(null);
    }
  };

  const searchClick = () => {
    if (searchKeys !== null) {
      API.get(`/config/parameter_mapping_search/`, {
        top_vault: productId,
        module: 6,
        category: categoryId,
        project: projectId,
        search_key: searchKeys?.trim(),
      })
        .then((res: any) => {
          setDataTypes(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
      setSearchvalue(searchKeys);
      setSearchKeys(searchKeys);
    }
  };

  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      if (searchKeys !== null) {
        API.get(`/config/parameter_mapping_search/`, {
          top_vault: productId,
          module: 6,
          category: categoryId,
          project: projectId,
          search_key: searchKeys?.trim(),
        })
          .then((res: any) => {
            setDataTypes(res?.data);
          })
          .catch((err: any) => {
            console.log("Server Error");
          });
        setSearchvalue(searchKeys);
        setSearchKeys(searchKeys);
      }
    }
  };
  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Add BOM Parameter
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "1rem",
              width: "100%",
              marginBottom: "0.5rem",
              padding: "0 1rem",
            }}>
            <Box></Box>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
              <Box
                sx={{                  
                  padding: "0 1rem",
                  display: "flex", alignItems: "center",
                }}>
                <TextField
                  size="small"
                  type="search"
                  value={searchKeys == null ? "" : searchKeys}
                  onKeyDown={(event: any) => handlechangeDown(event)}
                  onChange={(e: any) => handleSearch(e)}
                  id="standard-basic"
                  placeholder="Search Parameter Name"
                  variant="standard"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
                <SearchIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => searchClick()}
                />
              </Box>
              <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                {tempselectedParameters?.length} Selected
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{
                  whiteSpace: "nowrap",
                  ":hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={() => setTempSelectedParameters([])}>
                Clear Selected
              </Button>

              {tempselectedParameters?.length > 0 && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={isLoadingSave}
                  sx={{
                    ":hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    backgroundColor: "#5bad06",
                  }}
                  onClick={() => {
                    SaveHandler();
                  }}>
                  Add
                </LoadingButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{ height: { lg: "88%", xl: "89%" }, width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {searchvalue ? (
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: "0",
                  }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}></TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        minWidth:'12rem',
                      }}>
                      Parameter Name
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Exceptions
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Allowed Values
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Prohibited Values
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Default Values
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Guideline
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dataTypes &&
                    dataTypes?.map((rowItem: any, index: any) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              <input
                                type="checkbox"
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                  borderColor: "#ddefff",
                                }}
                                // size="small"
                                key={index}
                                checked={
                                  tempselectedParameters.includes(rowItem.id)
                                    ? true
                                    : false
                                }
                                onChange={(e: any) =>
                                  handleChangeCheck(e, rowItem)
                                }
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem?.name}
                            </TableCell>

                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem?.description}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem.unit}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem?.exceptions}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem?.allowed_values?.length == 0
                                ? "_"
                                : rowItem?.allowed_values?.toString()}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {" "}
                              {rowItem?.not_allowed_values?.length == 0
                                ? "_"
                                : rowItem?.not_allowed_values?.toString()}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {rowItem?.default_value}
                              {rowItem?.default_value == ""
                                ? "_"
                                : rowItem?.default_value}
                            </TableCell>
                            <TableCell
                              sx={{
                                cursor: "pointer",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                              onClick={() =>
                                rowItem?.info_doc != null &&
                                window.open(rowItem?.info_doc, "_blank")
                              }>
                              {rowItem?.info_doc == null ? (
                                "-"
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            ) : (
              <>
              <Box
                  sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                  width: '100%',
                  borderBottom: '1px solid',
                  }} adl-scrollbar="true"
                  adl-scrollbar-width="0.2">
                {dataTypes &&
                  dataTypes?.map((item: any, index: any) => {
                    return (<>
                    <Box
                        key={index}
                          sx={{
                            color: '#007fff',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0',
                            fontSize: '1rem',
                            width: '100%',
                            backgroundColor:
                              item?.name == selectedTab
                                ? 'primary.main'
                                : item?.parameter_group === 1 ? 'primary.light'                                
                                : item?.parameter_group === 2 ? '#d0f1aa'
                                : item?.parameter_group === 3 ? '#fbdddd'
                                : item?.parameter_group === 4 ? '#ffee93'
                                : '#d1cfe2',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Typography
                            sx={{
                              color:
                                item?.name == selectedTab ? 'white' : '#007fff',
                              height: '2rem',
                              minHeight: '2.1rem',
                              maxWidth: '100% !important',
                              padding: '0 0.5rem',
                              fontSize: '1rem',
                              width: '100%',
                              textAlign: 'center',
                              display: 'flex',
                              whiteSpace: 'nowrap',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => handleChange(item.name)}>
                            {item.name.toLowerCase()} ({item?.prameter_count
                                ? item?.prameter_count
                                : "-"})
                          </Typography>
                        </Box>
                      {/* <Accordion
                        expanded={expanded === item.name}
                        onChange={handleChange(item.name)}>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ minHeight: "36px" }}>
                          <Box
                            sx={{
                              padding: "0 1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}>
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                textTransform: "capitalize",
                              }}>
                              {item.name}
                            </Typography>
                            <Typography sx={{ fontSize: "1rem" }}>
                              {item?.prameter_count
                                ? item?.prameter_count
                                : "-"}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        {!isLoading ? (
                          <AccordionDetails
                            sx={{
                              height: { lg: "30rem", xl: "33rem" },
                              width: "100%",
                              paddingRight: "1rem",
                            }}>
                            <Box
                              sx={{ paddingRight: "0.5rem", height: "100%" }}
                              adl-scrollbar="true"
                              adl-scrollbar-width="0.3">
                              <Table>
                                {columnsFiled}
                                <TableBody>
                                  {paramsData &&
                                    paramsData?.map(
                                      (rowItem: any, index: any) => {
                                        return (
                                          <>
                                            {CellNameOne.includes(
                                              rowItem?.data_type
                                            ) ? (
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  <input
                                                    type="checkbox"
                                                    style={{
                                                      padding: "10px",
                                                      cursor: "pointer",
                                                      borderColor:
                                                        "primary.light",
                                                    }}
                                                    // size="small"
                                                    key={index}
                                                    checked={
                                                      tempselectedParameters.includes(
                                                        rowItem.id
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e: any) =>
                                                      handleChangeCheck(
                                                        e,
                                                        rowItem
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.name}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.description}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem.unit}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.exceptions}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.allowed_values
                                                    ?.length == 0
                                                    ? "_"
                                                    : rowItem?.allowed_values?.toString()}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {" "}
                                                  {rowItem?.not_allowed_values
                                                    ?.length == 0
                                                    ? "_"
                                                    : rowItem?.not_allowed_values?.toString()}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.default_value}
                                                  {rowItem?.default_value == ""
                                                    ? "_"
                                                    : rowItem?.default_value}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    cursor: "pointer",
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}
                                                  onClick={() =>
                                                    rowItem?.info_doc != null &&
                                                    window.open(
                                                      rowItem?.info_doc,
                                                      "_blank"
                                                    )
                                                  }>
                                                  {rowItem?.info_doc == null ? (
                                                    "-"
                                                  ) : (
                                                    <VisibilityOutlinedIcon />
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ) : CellNameSecond.includes(
                                              rowItem?.data_type
                                            ) ? (
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  <input
                                                    type="checkbox"
                                                    style={{
                                                      padding: "10px",
                                                      cursor: "pointer",
                                                      borderColor:
                                                        "primary.light",
                                                    }}
                                                    // size="small"
                                                    key={index}
                                                    checked={
                                                      tempselectedParameters.includes(
                                                        rowItem.id
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e: any) =>
                                                      handleChangeCheck(
                                                        e,
                                                        rowItem
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.name}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.description}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    cursor: "pointer",
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}
                                                  onClick={() =>
                                                    rowItem?.info_doc != null &&
                                                    window.open(
                                                      rowItem?.info_doc,
                                                      "_blank"
                                                    )
                                                  }>
                                                  {rowItem?.info_doc == null ? (
                                                    "-"
                                                  ) : (
                                                    <VisibilityOutlinedIcon />
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ) : CellNameThird.includes(
                                              rowItem?.data_type
                                            ) ? (
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  <input
                                                    type="checkbox"
                                                    style={{
                                                      padding: "10px",
                                                      cursor: "pointer",
                                                      borderColor:
                                                        "primary.light",
                                                    }}
                                                    // size="small"
                                                    key={index}
                                                    checked={
                                                      tempselectedParameters.includes(
                                                        rowItem.id
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e: any) =>
                                                      handleChangeCheck(e, rowItem)
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.name}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.description}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.unit}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.exceptions}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.allowed_values
                                                    ?.length == 0
                                                    ? "_"
                                                    : rowItem?.allowed_values?.toString()}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    cursor: "pointer",
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}
                                                  onClick={() =>
                                                    rowItem?.info_doc != null &&
                                                    window.open(
                                                      rowItem?.info_doc,
                                                      "_blank"
                                                    )
                                                  }>
                                                  {rowItem?.info_doc == null ? (
                                                    "-"
                                                  ) : (
                                                    <VisibilityOutlinedIcon />
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ) : (
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  <input
                                                    type="checkbox"
                                                    style={{
                                                      padding: "10px",
                                                      cursor: "pointer",
                                                      borderColor:
                                                        "primary.light",
                                                    }}
                                                    // size="small"
                                                    key={index}
                                                    checked={
                                                      tempselectedParameters.includes(
                                                        rowItem.id
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e: any) =>
                                                      handleChangeCheck(
                                                        e,
                                                        rowItem
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.name}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}>
                                                  {rowItem?.description}
                                                </TableCell>

                                                <TableCell
                                                  sx={{
                                                    cursor: "pointer",
                                                    padding: "0.2rem 0.5rem",
                                                    borderBottomColor:
                                                      "primary.light",
                                                  }}
                                                  onClick={() =>
                                                    rowItem?.info_doc != null &&
                                                    window.open(
                                                      rowItem?.info_doc,
                                                      "_blank"
                                                    )
                                                  }>
                                                  {rowItem?.info_doc == null ? (
                                                    "-"
                                                  ) : (
                                                    <VisibilityOutlinedIcon />
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </Box>
                          </AccordionDetails>
                        ) : (
                          <Box>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="pulse" />
                          </Box>
                        )}
                      </Accordion> */}
                    </>);
                  })}
              </Box>
              <Box
                sx={{ height: "94%" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                  {isLoading ?  (
                          <Box sx={{ width: '100%' }}>
                          <Table>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box>
                                  <Skeleton></Skeleton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Box>
                        ): (
                <Table>
                  {columnsFiled}
                  <TableBody>
                  {selectedTab == "0" ? (
                    <>
                    <TableRow>
                      <TableCell colSpan={7} sx={{borderBottom:'none',padding:'0'}}>
                        <Box sx={{width:'100%',height:{lg:'66vh',xl:'66vh'},display:'flex',alignItems:'center',justifyContent:'center'}}>
                          <Typography >Select Category</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    </>)
                          :
                        <>
                          {paramsData &&
                            paramsData?.map(
                              (rowItem: any, index: any) => {
                                return (
                                  <>
                                    {CellNameOne.includes(
                                      rowItem?.data_type
                                    ) ? (
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          <input
                                            type="checkbox"
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              borderColor:
                                                "#ddefff",
                                            }}
                                            // size="small"
                                            key={index}
                                            checked={
                                              tempselectedParameters.includes(
                                                rowItem.id
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              handleChangeCheck(
                                                e,
                                                rowItem
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.name}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.description}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem.unit}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.exceptions}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.allowed_values
                                            ?.length == 0
                                            ? "_"
                                            : rowItem?.allowed_values?.toString()}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {" "}
                                          {rowItem?.not_allowed_values
                                            ?.length == 0
                                            ? "_"
                                            : rowItem?.not_allowed_values?.toString()}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.default_value}
                                          {rowItem?.default_value == ""
                                            ? "_"
                                            : rowItem?.default_value}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            cursor: "pointer",
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}
                                          onClick={() =>
                                            rowItem?.info_doc != null &&
                                            window.open(
                                              rowItem?.info_doc,
                                              "_blank"
                                            )
                                          }>
                                          {rowItem?.info_doc == null ? (
                                            "-"
                                          ) : (
                                            <VisibilityOutlinedIcon />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ) : CellNameSecond.includes(
                                      rowItem?.data_type
                                    ) ? (
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          <input
                                            type="checkbox"
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              borderColor:
                                                "#ddefff",
                                            }}
                                            // size="small"
                                            key={index}
                                            checked={
                                              tempselectedParameters.includes(
                                                rowItem.id
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              handleChangeCheck(
                                                e,
                                                rowItem
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.name}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.description}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            cursor: "pointer",
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}
                                          onClick={() =>
                                            rowItem?.info_doc != null &&
                                            window.open(
                                              rowItem?.info_doc,
                                              "_blank"
                                            )
                                          }>
                                          {rowItem?.info_doc == null ? (
                                            "-"
                                          ) : (
                                            <VisibilityOutlinedIcon />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ) : CellNameThird.includes(
                                      rowItem?.data_type
                                    ) ? (
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          <input
                                            type="checkbox"
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              borderColor:
                                                "#ddefff",
                                            }}
                                            // size="small"
                                            key={index}
                                            checked={
                                              tempselectedParameters.includes(
                                                rowItem.id
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              handleChangeCheck(e, rowItem)
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.name}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.description}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.unit}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.exceptions}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.allowed_values
                                            ?.length == 0
                                            ? "_"
                                            : rowItem?.allowed_values?.toString()}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            cursor: "pointer",
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}
                                          onClick={() =>
                                            rowItem?.info_doc != null &&
                                            window.open(
                                              rowItem?.info_doc,
                                              "_blank"
                                            )
                                          }>
                                          {rowItem?.info_doc == null ? (
                                            "-"
                                          ) : (
                                            <VisibilityOutlinedIcon />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          <input
                                            type="checkbox"
                                            style={{
                                              padding: "10px",
                                              cursor: "pointer",
                                              borderColor:
                                                "#ddefff",
                                            }}
                                            // size="small"
                                            key={index}
                                            checked={
                                              tempselectedParameters.includes(
                                                rowItem.id
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              handleChangeCheck(
                                                e,
                                                rowItem
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.name}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}>
                                          {rowItem?.description}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            cursor: "pointer",
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor:
                                              "primary.light",
                                          }}
                                          onClick={() =>
                                            rowItem?.info_doc != null &&
                                            window.open(
                                              rowItem?.info_doc,
                                              "_blank"
                                            )
                                          }>
                                          {rowItem?.info_doc == null ? (
                                            "-"
                                          ) : (
                                            <VisibilityOutlinedIcon />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                );
                              }
                            )}
                      </>}
                  </TableBody>
                </Table>)}
              </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
const AddBomParameterModals = memo(AddParameterModal);
export default AddBomParameterModals;
