import { Box, FormControl, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface IBulbFormProps {
  actions: any;
  onCloseModal: any;
  getMaterialData: any;
  subCategory: any;
  target: any;
  onCloseParent: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select type"),
  rated_voltage: yup.string(),
  base_code: yup.mixed().required("Please type Base Code"),
  no_of_filament: yup.mixed().required("Please type No. of filaments"),
  watttage: yup.mixed(),
  reference: yup.string(),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function BulbFormMove(props: IBulbFormProps) {
  const { actions, onCloseModal, getMaterialData, subCategory, target, onCloseParent } = props;
      // ----------------------------------------------

      const [countryList, setCountryList] = React.useState<any[]>([]);
      const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
      const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
      const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
        React.useState<any>("");
      // ----------------------------------------------
  const { url } = useRouteMatch();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const classes = useStyles();
  const { categoryName } = useRouteParams<any>();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      rated_voltage: "",
      base_code: "",
      no_of_filament: "",
      watttage: "",
      reference: "",
      unit_cost: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
      const { currency_code, ...apiPayload } = values; // remove currency_code from apiPayload  as it is not needed in the api
      API.post(
        "/api/db/move_cost_data/",
        {
          ...values,
          ...{ target_commodity: subCategory, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout: true, module_type: target },
        },
        {},
        0
      )
        .then((res: any) => {
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Moved Successfully!</p>   

       </div>`,
          });
          getMaterialData();
          onCloseModal();
          onCloseParent();
        })
        .catch((err: any) => {});
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes([actions?.currency_code] || []);
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue("rated_voltage", actions?.rated_voltage || "");
      formik.setFieldValue("base_code", actions?.base_code || "");
      formik.setFieldValue("no_of_filament", actions?.no_of_filament || "");
      formik.setFieldValue("watttage", actions?.watttage || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue("unit_cost", parseFloat(actions.unit_cost?.slice(1)) || "");
      formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);
    }
  }, [actions]);

    const getCountryData = () => {
      setCountryLoading(true);
      API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
        .then((res: any) => {
          const c_codes = res?.data.map((item: any) => item.currency_code);
          setCurrencyCodes(c_codes);
          setCountryList(res.data);
          setCountryLoading(false);
        })
        .catch((err: any) => {
          console.error("Error fetching currency data:", err);
          setCountryLoading(false);
        });
    };
  
    React.useEffect(() => {
      getCountryData();
    }, []);
    const flagMap = countryList?.reduce((acc, country) => {
      acc[country?.currency_code] = country?.flag;
      return acc;
    }, {});
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label">
              Select Type{" "}
            </InputLabel>

            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["LED", "Halogen", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.type && Boolean(formik.errors.type) && <p>{formik.errors.type}</p>}</div>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Related Voltage"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="rated_voltage"
              name="rated_voltage"
              onChange={formik.handleChange}
              value={formik.values.rated_voltage}
              multiline
              error={formik.touched.rated_voltage && Boolean(formik.errors.rated_voltage)}
              helperText={formik.touched.rated_voltage && formik.errors.rated_voltage}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Base Code"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="base_code"
              name="base_code"
              onChange={formik.handleChange}
              value={formik.values.base_code}
              type="number"
              error={formik.touched.base_code && Boolean(formik.errors.base_code)}
              helperText={formik.touched.base_code && formik.errors.base_code}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="No. of filaments"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="no_of_filament"
              name="no_of_filament"
              onChange={formik.handleChange}
              value={formik.values.no_of_filament}
              error={formik.touched.no_of_filament && Boolean(formik.errors.no_of_filament)}
              helperText={formik.touched.no_of_filament && formik.errors.no_of_filament}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Filament wattage"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="watttage"
              name="watttage"
              onChange={formik.handleChange}
              value={formik.values.watttage}
              error={formik.touched.watttage && Boolean(formik.errors.watttage)}
              helperText={formik.touched.watttage && formik.errors.watttage}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (Currency)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                        inputProps: {
                          pattern: "^[^0-9]*$", // This regex pattern disallows any numerical input
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={(e)=> {
                        formik.handleChange(e);
                        // if (!/[0-9]/.test(e.target.value)) {
                         
                          
                        // }  
                      }}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box> */}
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit">
            Move
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
