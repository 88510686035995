import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CellNameFive,
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./ParameterHeaderType";
import { Label } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { API } from "../../api-services";
import ParameterDataBaseS3Doc from "./ParameterDataBaseS3Doc";
import { LoadingButton } from "@mui/lab";
import successIcon from "../../Assets/images/success.png";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { set } from "lodash";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  height: 'auto',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface AddParameterDataBaseModalProps {
  isOpen: any;
  onCloseModal: any;
  Item?: any;
  datatype?: any;
  Modaltype: any;
  rows?: any;
  TableIds?: any;
  setTableCounter: any;
  setDataTypeCounter?: any;
  extraType?: any
}
var DataTypeList = [
  {
    lable: "float",
    text: "float",
    id: 1,
  },
  {
    lable: "nonzero-float",
    text: "nonzero-float",
    id: 2,
  },
  {
    lable: "positive-float",
    text: "positive-float",
    id: 3,
  },
  {
    lable: "nonzero-positive-float",
    text: "nonzero-positive-float",
    id: 4,
  },
  {
    lable: "positive-int",
    text: "positive-int",
    id: 5,
  },
  {
    lable: "nonzero-positive-int",
    text: "nonzero-positive-int",
    id: 6,
  },
  {
    lable: "percentage",
    text: "percentage",
    id: 7,
  },
  {
    lable: "string",
    text: "string",
    id: 8,
  },
  {
    lable: "negative-float",
    text: "negative-float",
    id: 9,
  },
  {
    lable: "integer",
    id: 10,
    text: "integer",
  },
  {
    lable: "nonzero-integer",
    text: "nonzero-integer",
    id: 11,
  },
  {
    lable: "negative-integer",
    text: "negative-integer",
    id: 12,
  },
  {
    lable: "nonzero-negative-integer",
    text: "nonzero-negative-integer",
    id: 13,
  },
  {
    lable: "date",
    text: "date",
    id: 14,
  },
  {
    lable: "date-and-time",
    text: "date-and-time",
    id: 15,
  },
  {
    lable: "longtext",
    text: "longtext",
    id: 16,
  },
  {
    lable: "360-video",
    text: "360-video",
    id: 17,
  },
  {
    lable: "textarea",
    text: "textarea",
    id: 18,
  },
  {
    lable: "table",
    id: 19,
    text: "table",
  },
  {
    lable: "image",
    text: "image",
    id: 20,
  },
  {
    lable: "boolean",
    text: "boolean",
    id: 21,
  },
  {
    lable: "video",
    text: "video",
    id: 22,
  },
  {
    lable: "database",
    text: "database",
    id: 23,
  },
  {
    lable: "singleselect",
    text: "singleselect",
    id: 24,
  },
  {
    lable: "nonzero-negative-float",
    text: "nonzero-negative-float",
    id: 25,
  },
  {
    lable: "multiselect",
    text: "multiselect",
    id: 26,
  },
  {
    lable: "document",
    text: "document",
    id: 27,
  },
  {
    lable: "country",
    text: "country",
    id: 28,
  },
  {
    lable: "link",
    text: "link",
    id: 29,
  },
  {
    lable: "Idea calculator",
    text: "Idea calculator",
    id: 30
  },
  {
    lable: "BOM calculator",
    text: "BOM calculator",
    id: 31
  },
  {
    lable: "BOM Table calculator",
    text: "BOM-table-calculator",
    id: 32
  }
];
var DataTypeListTAble = [
  {
    lable: "float",
    text: "float",
    id: 1,
  },
  {
    lable: "nonzero-float",
    text: "nonzero-float",
    id: 2,
  },
  {
    lable: "positive-float",
    text: "positive-float",
    id: 3,
  },
  {
    lable: "nonzero-positive-float",
    text: "nonzero-positive-float",
    id: 4,
  },
  {
    lable: "positive-int",
    text: "positive-int",
    id: 5,
  },
  {
    lable: "nonzero-positive-int",
    text: "nonzero-positive-int",
    id: 6,
  },
  {
    lable: "percentage",
    text: "percentage",
    id: 7,
  },
  {
    lable: "string",
    text: "string",
    id: 8,
  },
  {
    lable: "negative-float",
    text: "negative-float",
    id: 9,
  },
  {
    lable: "integer",
    id: 10,
    text: "integer",
  },
  {
    lable: "nonzero-integer",
    text: "nonzero-integer",
    id: 11,
  },
  {
    lable: "negative-integer",
    text: "negative-integer",
    id: 12,
  },
  {
    lable: "nonzero-negative-integer",
    text: "nonzero-negative-integer",
    id: 13,
  },
  {
    lable: "date",
    text: "date",
    id: 14,
  },
  {
    lable: "date-and-time",
    text: "date-and-time",
    id: 15,
  },
  {
    lable: "longtext",
    text: "longtext",
    id: 16,
  },
  {
    lable: "360-video",
    text: "360-video",
    id: 17,
  },
  {
    lable: "textarea",
    text: "textarea",
    id: 18,
  },
  // {
  //   lable: "table",
  //   id: 19,
  //   text: "table",
  // },
  {
    lable: "image",
    text: "image",
    id: 20,
  },
  {
    lable: "boolean",
    text: "boolean",
    id: 21,
  },
  {
    lable: "video",
    text: "video",
    id: 22,
  },
  {
    lable: "database",
    text: "database",
    id: 23,
  },
  {
    lable: "singleselect",
    text: "singleselect",
    id: 24,
  },
  {
    lable: "nonzero-negative-float",
    text: "nonzero-negative-float",
    id: 25,
  },
  {
    lable: "multiselect",
    text: "multiselect",
    id: 26,
  },
  {
    lable: "document",
    text: "document",
    id: 27,
  },
  {
    lable: "country",
    text: "country",
    id: 28,
  },
  {
    lable: "link",
    text: "link",
    id: 29,
  },

];
export const AddParameterDataBaseModal = (
  props: AddParameterDataBaseModalProps
) => {
  const { isOpen, onCloseModal, datatype, setTableCounter,
    setDataTypeCounter, Modaltype, Item, rows, TableIds, extraType } = props;
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  React.useEffect(() => {
    if (extraType != "filterList") {
      setDataTypeValues(DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text == datatype })[0])
    } else {
      setDataTypeValues(DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text != datatype })[0])
    }
  }, [datatype])

  const [Name, setName] = useState<any>(null);
  const [NameError, setNameError] = useState<any>(false);
  const [unit, setUnit] = useState<any>(null);
  const [unitError, setUnitError] = useState<any>(false);
  const [Description, setDescription] = useState<any>(null);
  const [Exceptions, setExceptions] = useState<any>(null);
  const [Allowedvalues, setAllowedvalues] = useState<any>(null);
  const [NotAllowed, setNotAllowed] = useState<any>(null);
  const [Defaultvalues, setDefaultvalues] = useState<any>(null);
  const [FileData, setFiledata] = useState<any>("text");
  const [DocLoader, setDocLoader] = useState<any>(false)
  const [FileUrl, setFileUrl] = useState<any>(null)
  const [loader, setLoader] = useState<any>(false);
  const [SuccessStatus, setSuccessStatus] = useState<any>(false)
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const [TimingData, setTimingData] = useState<any>(null)
  const [TimingDataError, setTimingDataError] = useState<any>(false);
  const [FileName, setFileName] = useState<any>(null)
  const ErrorModalFiles = useBaseModal()
  const inputElRef = useRef<any>()
  const inputElRef2 = useRef<any>()
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
  };



  useEffect(() => {
    setSuccessStatus(false)
    if (datatype == "BOM-table-calculator") {
      if (Modaltype == "Edit") {
        if (extraType === "filterList") {
          var data = DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text != rows?.data_type })[0]
          setDataTypeValues(data)
        } else {
          var data = DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text == rows?.data_type })[0]
          setDataTypeValues(data)
        }

      } if (Modaltype == "tableEdit") {
        var data = DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text == rows?.data_type })[0]

        setDataTypeValues(data)
      }
    } else {
      if (Modaltype == "Edit") {
        var data = DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text == rows?.data_type })[0]

        setDataTypeValues(data)
      } if (Modaltype == "tableEdit") {
        var data = DataTypeList && DataTypeList?.filter((item: any, index: any) => { return item?.text == rows?.data_type })[0]

        setDataTypeValues(data)
      }
    }

  }, [Modaltype, rows])


  useEffect(() => {
    setNameError(false)
    setTimingDataError(false)
    setUnit("")
    setName("")
    setTimingData(null)
    setFiledata(null)
    setFileUrl(null)
    setDescription("")
    setExceptions("")
    setNotAllowed("")
    setAllowedvalues("")
    setDefaultvalues("")
    setFileName(null)
    setDocLoader(false)
    if (Modaltype == "Edit" || Modaltype == "tableEdit") {
      setNameError(false)
      setTimingDataError(false)
      setUnit(rows && rows?.unit)
      setName(rows && rows?.name)
      setFiledata(null)
      setFileUrl(null)
      setTimingData(rows && rows?.time)
      setFileName(rows && rows?.file_name)
      setDescription(rows && rows?.description)
      setExceptions(rows && rows?.exceptions?.toString())
      setNotAllowed(rows && rows?.not_allowed_values?.toString())
      setAllowedvalues(rows && rows?.allowed_values?.toString())
      setDefaultvalues(rows && rows?.default_value)
      setDocLoader(false)
    }
  }, [DataTypeValues])
  const handlechange = (e: any, type: any) => {
    if (type == "Name") {
      setNameError(false)
      setName(e?.target?.value);
    } else if (type == "unit") {
      setUnit(e?.target?.value);
    } else if (type == "Description") {
      setDescription(e?.target?.value);
    } else if (type == "Exceptions") {
      setExceptions(e?.target?.value);
    } else if (type == "Allowedvalues") {
      setAllowedvalues(e?.target?.value);
    } else if (type == "NotAllowed") {
      setNotAllowed(e?.target?.value);
    } else if (type == "Defaultvalues") {
      setDefaultvalues(e?.target?.value);
    } else if (type == "TimingData") {
      setTimingDataError(false)
      setTimingData(e?.target?.value)
    }
  };


  const Filechange = (e: any) => {

    setFiledata(e?.target?.files[0]);
    setFileName(e?.target?.files[0]?.name)

    ParameterDataBaseS3Doc([e?.target?.files[0]], DataTypeValues && DataTypeValues?.lable, setFileUrl, setDocLoader)
  };

  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };

  const browseFilesDoc = () => {
    return inputElRef2.current?.click?.();
  }
  const SaveHanlder = () => {

    var formData = new FormData();
    formData.append("info_doc", FileData)
    var testdata = /\S+/.test(Name);
    if (Name !== null && testdata == true) {
      setNameError(false)
      setLoader(true)
      if (Modaltype == "Edit" || Modaltype == "tableEdit") {

        if (DataTypeValues && DataTypeValues?.id == 17 && Number(TimingData) > 0) {


          API.put(`/api/db/parameter/${rows && rows?.id}/`, {



            allowed_values: Allowedvalues == null ? [] : Allowedvalues,
            data_type: DataTypeValues && DataTypeValues?.text,
            exceptions: Exceptions == null ? [] : Exceptions,
            default_value: Defaultvalues,
            name: Name.trim(),
            description: Description,
            not_allowed_values: NotAllowed == null ? [] : NotAllowed,
            unit: unit,
            parameter_info: null,
            info_doc: FileUrl,
            time: TimingData == null ? 0 : Number(TimingData),
            operation_param: 0
          }).then((res: any) => {
            setLoader(false)
            setSuccessStatus(true)
            setLoader(false)


            setTableCounter((prev: any) => prev + 1)

          }).catch((err: any) => {
            setLoader(false)
            const { data } = err?.response;


            setErrorModalFileMsg(data[0]);

            ErrorModalFiles.open()

          })
        }
        if (DataTypeValues && DataTypeValues?.id == 17 && Number(TimingData) == 0) {
          setTimingDataError(true)
        }

        else if (DataTypeValues && DataTypeValues?.id !== 17) {

          API.put(`/api/db/parameter/${rows && rows?.id}/`, {



            allowed_values: Allowedvalues == null ? [] : Allowedvalues,
            data_type: DataTypeValues && DataTypeValues?.text,
            exceptions: Exceptions == null ? [] : Exceptions,
            default_value: Defaultvalues,
            name: Name.trim(),
            description: Description,
            not_allowed_values: NotAllowed == null ? [] : NotAllowed,
            unit: unit,
            parameter_info: null,
            info_doc: FileUrl,
            time: TimingData == null ? 0 : Number(TimingData),
            operation_param: 0
          }).then((res: any) => {
            setLoader(false)
            setSuccessStatus(true)
            setLoader(false)


            setTableCounter((prev: any) => prev + 1)

          }).catch((err: any) => {
            setLoader(false)
            const { data } = err?.response;


            setErrorModalFileMsg(data[0]);

            ErrorModalFiles.open()

          })
        }

      }

      else if (Modaltype == "tableAdd") {
        API.post(`/api/db/parameter/`, {

          table_id: TableIds,

          allowed_values: Allowedvalues == null ? [] : Allowedvalues,
          data_type: DataTypeValues && DataTypeValues?.text,
          exceptions: Exceptions == null ? [] : Exceptions,
          default_value: Defaultvalues,
          name: Name.trim(),
          description: Description,
          not_allowed_values: NotAllowed == null ? [] : NotAllowed,
          unit: unit,
          parameter_info: null,
          info_doc: FileUrl,
          time: TimingData == null ? 0 : Number(TimingData),
          operation_param: 0
        }).then((res: any) => {
          setLoader(false)
          setSuccessStatus(true)
          setLoader(false)


          setTableCounter((prev: any) => prev + 1)

        }).catch((err: any) => {
          setLoader(false)
          const { data } = err?.response;


          setErrorModalFileMsg(data[0]);

          ErrorModalFiles.open()

        })
      }
      else {

        if (DataTypeValues && DataTypeValues?.id == 17 && Number(TimingData) > 0) {


          API.post("/api/db/parameter/", {
            allowed_values: Allowedvalues == null ? [] : Allowedvalues,
            data_type: DataTypeValues && DataTypeValues?.text,
            exceptions: Exceptions == null ? [] : Exceptions,
            default_value: Defaultvalues,
            name: Name.trim(),
            description: Description,
            not_allowed_values: NotAllowed == null ? [] : NotAllowed,
            unit: unit,
            parameter_info: null,
            info_doc: FileUrl,
            time: TimingData == null ? 0 : Number(TimingData),
            operation_param: 0
          }).then((res: any) => {
            setSuccessStatus(true)
            setLoader(false)
            setDataTypeCounter((prev: any) => prev + 1)

            setTableCounter((prev: any) => prev + 1)

          }).catch((err: any) => {
            setLoader(false)
            const { data } = err?.response;


            setErrorModalFileMsg(data[0]);

            ErrorModalFiles.open()

          })
        }

        if (DataTypeValues && DataTypeValues?.id == 17 && Number(TimingData) == 0) {
          setTimingDataError(true)
        }




        else if (DataTypeValues && DataTypeValues?.id !== 17) {
          API.post("/api/db/parameter/", {
            allowed_values: Allowedvalues == null ? [] : Allowedvalues,
            data_type: DataTypeValues && DataTypeValues?.text,
            exceptions: Exceptions == null ? [] : Exceptions,
            default_value: Defaultvalues,
            name: Name.trim(),
            description: Description,
            not_allowed_values: NotAllowed == null ? [] : NotAllowed,
            unit: unit,
            parameter_info: null,
            info_doc: FileUrl,
            time: TimingData == null ? 0 : Number(TimingData),
            operation_param: 0
          }).then((res: any) => {
            setSuccessStatus(true)
            setLoader(false)
            setDataTypeCounter((prev: any) => prev + 1)

            setTableCounter((prev: any) => prev + 1)

          }).catch((err: any) => {
            setLoader(false)
            const { data } = err?.response;


            setErrorModalFileMsg(data[0]);

            ErrorModalFiles.open()

          })

        }
      }



    }





    else {
      setNameError(true)
    }
  };

  const getList = (dataList: any) => {
    if (extraType == "filterList") {
      return DataTypeListTAble
    } else {
      return dataList
    }
  }

  return (
    <div>

      <Modal
        open={isOpen}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box>

          <input
            type="file"
            accept="application/pdf,.png, .jpg, .jpeg"
            style={{ display: "none" }}
            ref={inputElRef}
            onChange={(e: any) => Filechange(e)}


          />

          <input

            type="file"
            accept="application/pdf,.jpeg,.xlsx,.xls,.docx,.doc"
            style={{ display: "none" }}
            ref={inputElRef2}
            onChange={(e: any) => Filechange(e)}


          />



          {SuccessStatus


            ? <Box sx={style}>

              {/* <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                {Modaltype == "Edit" ? <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Edit Parameter
                </Typography> :
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      width: "100%",
                      textAlign: "center",
                      marginRight: "-2rem",
                    }}>
                    Add Parameter
                  </Typography>
                }

                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={onCloseModal}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              /> */}
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <img src={successIcon} style={{ width: '7rem' }} />
                <Typography sx={{ marginTop: '1rem', fontSize: '1rem', width: '100%', textAlign: 'center' }}> {Modaltype == "Edit" ? 'Parameter Updated Successfully!' : "Parameter Added Successfully!"}</Typography>
                <Button variant="contained" onClick={onCloseModal} size="small" sx={{ margin: '1rem auto' }}>
                  ok
                </Button>
              </Box>
            </Box> :
            <Box sx={style}>
              <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Add Parameter
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={onCloseModal}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", marginBottom: '0.5rem' }}>
                <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                  Data type<sup style={{ color: "red" }}>*</sup>{" "}
                </Typography>
                <Autocomplete
                  sx={{
                    width: '100%',
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    fontSize: '1rem !important',
                  }}
                  size="small"
                  id="combo-box-demo"
                  disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
                  value={DataTypeValues}
                  getOptionLabel={(option) =>
                    option?.lable == undefined ? "" : option?.lable?.toUpperCase()
                  }
                  onChange={(event, newValue, situation) =>
                    handleSelectdatatype(newValue)
                  }
                  options={getList(DataTypeList)}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                      {option?.lable?.toUpperCase()}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      sx={{
                        fontSize: '1rem',
                        borderBottom: "none",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      {...params}
                      placeholder="Data Type"
                    />
                  )}
                />
              </Box>

              {DataTypeValues?.lable == undefined ? (
                ""
              ) : (
                <Box>
                  {CellNameOne.includes(DataTypeValues?.lable) ?

                    (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Name<sup style={{ color: "red" }}>*</sup>{" "}
                          </Typography>
                          <TextField
                            error={NameError}
                            value={Name}
                            onChange={(e: any) => handlechange(e, "Name")}
                            variant="standard"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: NameError ? "red" : "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: NameError ? "red" : "primary.light",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Unit
                          </Typography>
                          <TextField
                            value={unit}
                            onChange={(e: any) => handlechange(e, "unit")}
                            variant="standard"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Description{" "}
                          </Typography>
                          <TextField
                            variant="standard"
                            value={Description}
                            onChange={(e: any) => handlechange(e, "Description")}
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Exceptions{" "}
                          </Typography>
                          <TextField
                            value={Exceptions}
                            onChange={(e: any) => handlechange(e, "Exceptions")}
                            variant="standard"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Allowed Values{" "}
                          </Typography>
                          <TextField
                            value={Allowedvalues}
                            variant="standard"
                            onChange={(e: any) => handlechange(e, "Allowedvalues")}
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Not allowed values{" "}
                          </Typography>
                          <TextField
                            value={NotAllowed}
                            variant="standard"
                            onChange={(e: any) => handlechange(e, "NotAllowed")}
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Default Values{" "}
                          </Typography>
                          <TextField
                            value={Defaultvalues}
                            onChange={(e: any) => handlechange(e, "Defaultvalues")}
                            variant="standard"
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: '0.5rem',
                          }}>
                          <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                            Guideline Doc <span> (pdf/jpeg/xlsx/doc)</span>
                          </Typography>
                          <Box sx={{ width: '100%' }}>
                            <LoadingButton size="small" sx={{ cursor: 'pointer' }} loading={DocLoader} onClick={() => browseFiles()}>
                              <Button size="small" variant="outlined" > {FileName !== null ? "Update Doc" : "Choose File"}</Button>
                              {FileName !== null && FileName}
                            </LoadingButton>
                          </Box>


                        </Box>
                      </Box>
                    ) : CellNameFive.includes(DataTypeValues?.lable) ?
                      (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Name<sup style={{ color: "red" }}>*</sup>{" "}
                            </Typography>
                            <TextField
                              error={NameError}
                              value={Name}
                              onChange={(e: any) => handlechange(e, "Name")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Description{" "}
                            </Typography>
                            <TextField
                              variant="standard"
                              value={Description}
                              onChange={(e: any) => handlechange(e, "Description")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Exceptions{" "}
                            </Typography>
                            <TextField
                              value={Exceptions}
                              onChange={(e: any) => handlechange(e, "Exceptions")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Allowed Values{" "}
                            </Typography>
                            <TextField
                              value={Allowedvalues}
                              variant="standard"
                              onChange={(e: any) => handlechange(e, "Allowedvalues")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Not allowed values{" "}
                            </Typography>
                            <TextField
                              value={NotAllowed}
                              variant="standard"
                              onChange={(e: any) => handlechange(e, "NotAllowed")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Default Values{" "}
                            </Typography>
                            <TextField
                              value={Defaultvalues}
                              onChange={(e: any) => handlechange(e, "Defaultvalues")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Guideline Doc <span> (pdf/jpeg/xlsx/doc)</span>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                              <LoadingButton size="small" sx={{ cursor: 'pointer' }} loading={DocLoader} onClick={() => browseFiles()}>
                                <Button size="small" variant="outlined" > {FileName !== null ? "Update Doc" : "Choose File"}</Button>
                                {FileName !== null && FileName}
                              </LoadingButton>
                            </Box>


                          </Box>
                        </Box>
                      )


                      : CellNameSecond.includes(DataTypeValues?.lable) ? (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Name<sup style={{ color: "red" }}>*</sup>{" "}
                            </Typography>
                            <TextField
                              error={NameError}
                              value={Name}
                              onChange={(e: any) => handlechange(e, "Name")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Description{" "}
                            </Typography>
                            <TextField
                              variant="standard"
                              value={Description}
                              onChange={(e: any) => handlechange(e, "Description")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Guideline Doc <span> (pdf/jpeg/xlsx/doc)</span>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                              <LoadingButton size="small" sx={{ cursor: 'pointer' }} loading={DocLoader} onClick={() => browseFiles()}>
                                <Button size="small" variant="outlined"> {FileName !== null ? "Update Doc" : "Choose File"}</Button>
                                {FileName !== null && FileName}
                              </LoadingButton>
                            </Box>



                          </Box>

                          {DataTypeValues && DataTypeValues?.id == 17 &&
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}>

                              <Typography sx={{ fontSize: "1rem", width: "14rem" }}>Time
                                <sup style={{ color: "red" }}>*</sup>
                              </Typography>

                              <TextField
                                variant="standard"
                                type="number"
                                error={TimingDataError}
                                value={TimingData}
                                onChange={(e: any) => handlechange(e, "TimingData")}
                                sx={{
                                  fontSize: "1rem",
                                  width: "100%",
                                  ".MuiInputBase-root.MuiInput-root": {
                                    fontSize: '1rem !important'
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: TimingDataError ? "red" : "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: TimingDataError ? "red" : "primary.light",
                                  },
                                }}
                              />



                            </Box>}
                        </Box>
                      ) : CellNameThird.includes(DataTypeValues?.lable) ? (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Name<sup style={{ color: "red" }}>*</sup>{" "}
                            </Typography>
                            <TextField
                              error={NameError}
                              value={Name}
                              onChange={(e: any) => handlechange(e, "Name")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Unit
                            </Typography>
                            <TextField
                              value={unit}
                              onChange={(e: any) => handlechange(e, "unit")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Description{" "}
                            </Typography>
                            <TextField
                              variant="standard"
                              value={Description}
                              onChange={(e: any) => handlechange(e, "Description")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Exceptions{" "}
                            </Typography>
                            <TextField
                              value={Exceptions}
                              onChange={(e: any) => handlechange(e, "Exceptions")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Allowed Values{" "}
                            </Typography>
                            <TextField
                              value={Allowedvalues}
                              variant="standard"
                              onChange={(e: any) => handlechange(e, "Allowedvalues")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Guideline Doc <span> (pdf/jpeg/xlsx/doc)</span>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                              <LoadingButton size="small" sx={{ cursor: 'pointer' }} loading={DocLoader} onClick={() => browseFiles()}>
                                <Button size="small" variant="outlined"> {FileName !== null ? "Update Doc" : "Choose File"}</Button>
                                {FileName !== null && FileName}
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Name<sup style={{ color: "red" }}>*</sup>{" "}
                            </Typography>
                            <TextField
                              error={NameError}
                              value={Name}
                              onChange={(e: any) => handlechange(e, "Name")}
                              variant="standard"
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: NameError ? "red" : "primary.light",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Description{" "}
                            </Typography>
                            <TextField
                              variant="standard"
                              value={Description}
                              onChange={(e: any) => handlechange(e, "Description")}
                              sx={{
                                fontSize: "1rem",
                                width: "100%",
                                ".MuiInputBase-root.MuiInput-root": {
                                  fontSize: '1rem !important'
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: '0.5rem',
                            }}>
                            <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                              Guideline Doc <span > (pdf/jpeg/xlsx/doc)</span>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                              <LoadingButton size="small" sx={{ cursor: 'pointer' }} loading={DocLoader} onClick={() => browseFilesDoc()}>
                                <Button size="small" variant="outlined" > {FileName !== null ? "Update Doc" : "Choose File"}</Button>
                                {FileName !== null && FileName}
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      )}
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}>
                <Button
                  disabled={DocLoader}
                  onClick={() => SaveHanlder()}
                  variant="contained"
                  size="small"
                  sx={{ cursor: "pointer" }}>
                  Save
                </Button>
              </Box>
            </Box>

          }
          <Box>
            <ErrorModalFilesMail
              isOpen={ErrorModalFiles.isOpen}
              onCloseModal={ErrorModalFiles.close}
              ErrorMsg={ErrorModalFileMsg}
            />
          </Box>
        </Box>

      </Modal>
    </div>
  );
};