import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button, ButtonProps, IconButton, Skeleton, Typography, styled } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LaunchIcon from "@mui/icons-material/Launch";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SubRevisionEditModal from "./SubRevisionEditModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import FetchRawMaterialModals from "./FetchRawMaterialModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import swal from "sweetalert";
import CreateRevisionDatabaseModal from "./CreateRevisionDatabaseDataModal";
import RawMaterialDatabaseInfoModal from "./RawMaterialDatabaseInfoModal";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import RawMaterialDBMove from "./RawMaterialDBMove";
import move from '../../Assets/images/right move.svg'

export interface IRawMaterialDatabaseProps { }

// const rows: any = [];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "primary.main" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const csvOptions: GridCsvExportOptions = {
  fileName: "Idea Approve Table User Session In CSV",
  delimiter: ",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width:{lg:'84%',xl:'88%'},
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',    
    marginTop:'-2.4rem', }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function RMDatabaseSearch(props: IRawMaterialDatabaseProps) {
	const { tab, year,month, revisionId, typeId, databaseName, status, search, categoryName } =
		useRouteParams<any>();
  const subRevisionEdit = useBaseParamsModal();
  const databaseMaterialInfo = useBaseParamsModal();
  const moveModal = useBaseParamsModal();
  const fetchRawMaterial = useBaseModal();
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName && categoryName);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [count, setCount] = React.useState<number>(0);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
  const inputElRefPdf = useRef<any>();
  const [tableData, setTableData] = React.useState<any>([]);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [useraction,setuseraction]=useState<any>(null)
  const [tabValue, setTabVale] = useState<any>();
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };



  const getCategoryList = () => {
    setAccordioinLoading(true);
    API.get("/api/db/cost_databases/",
      {
        database_type: "material",
        search:search
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };
  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };
  React.useEffect(() => {
    getCategoryList();
  }, [search]);


  React.useEffect(() => {
		if (categoryList && categoryList.length > 0) {
		  // Set the selected tab to the first item in categoryList when the component loads
      history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryList[0].category}/${status}/${search}`);
		  // history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryList[0].category}/${status}/${search}`);
		}
	  }, [categoryList]);


  const isUrl = (url:any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  const editSubRevision = (item: any) => {
    subRevisionEdit.open(item);
  };
  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    API.get(
      "/api/db/material/",
      {
        category: category,
        search:search
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);
  const columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 165 : width < 1200 ? 165 : 170,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.mapped_status ? <IconButton title="Material Info" sx={{ cursor: 'pointer', color: 'primary.main', padding: '0' }} onClick={() => databaseMaterialInfo.open(cellValues.row.id)}><InfoIcon sx={{ cursor: "pointer" }} /></IconButton> :
              useraction && useraction?.includes("D") &&<IconButton title="Delete" sx={{ cursor: 'pointer', color: 'primary.main', padding: '0' }}
                onClick={() => {
                  swal({
                    title: `Delete material`,
                    text: `Are you sure you want to delete ?`,
                    icon: "error",
                    buttons: ["Cancel", "Yes, Delete"],
                  }).then((confirm) => {
                    if (confirm) {
                      API.delete(`/api/db/material/${cellValues.row.id}/`, {}, 0).then((res: any) => {
                        getMaterialData(categoryName)
                      }).catch((err: any) => { })
                    }
                  })
                }}> <DeleteIcon />
              </IconButton>}

            { useraction && useraction?.includes("U") && <IconButton title="Move" sx={{ cursor: 'pointer', color: 'primary.main', padding: '0' }} onClick={() => moveModal.open(cellValues.row)}  > <img src={move} style={{ height: '1.3rem' }} /></IconButton>}
            {useraction && useraction?.includes("U") &&<IconButton title="Edit" sx={{ cursor: 'pointer', color: 'primary.main', padding: '0' }} onClick={() => editSubRevision(cellValues.row)} ><EditIcon /></IconButton>}
          </>
        );
      },
    },
    { field: "sno", headerName: "S.No", width: width > 1370 ? 50 : width < 900 ? 50 : 50 },
    {
      field: "material_code",
      headerName: "Material Code",
      minWidth: width > 1370 ? 140 : width < 900 ? 140 : 200,
      flex: 1,
    },
    {
      field: "material_grade",
      headerName: "Grade",
      width: width > 1370 ? 120 : 100,
      flex: 1,
    },
    {
      field: "material_subgrade",
      headerName: "Sub Grade",
      type: "number",
      minWidth: width > 1370 ? 120 : 80,
    },
    {
      field: "shape",
      headerName: "Initial Shape",

      sortable: false,
      width: width > 1370 ? 100 : 80,
    },
    {
      field: "density",
      headerName: "Density (kg/m3)",

      sortable: false,
      minWidth: width > 1370 ? 150 : 120,
    },
    {
      field: "description",
      headerName: "Remarks",

      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 160,
    },
    {
      field: "material_cost",
      headerName: "Material Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: 'right',
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
    },

    {
			field: 'currency_code',
			headerName: 'Currency/Unit',
			sortable: false,
			minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
			renderCell: (cellValues: any) => {
				
				
				return (
					<Box sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',columnGap:'1rem',}}> 
					{`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`} 
					 </Box>
				);
			},
		},
    {
      field: "material_source",
      headerName: "Material Source",
      sortable: false,
      minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    },


    {
      field: "material_reference",
      headerName: "Material Ref",
      sortable: false,
      minWidth: width > 1370 ? 100 : width < 1200 ? 100 : 140,
      renderCell: (cellValues: any) => {
        return (
          <>
           {isUrl(cellValues?.row?.material_reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(cellValues?.row?.material_reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {cellValues?.row?.material_reference}
            </span>}
          </>
        );
      },
    },
    {
      field: "scrap_cost",
      headerName: "Scrap Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: 'right',
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
    },
    {
      field: "scrap_source",
      headerName: "Scrap Source",
      sortable: false,
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
    },
    {
      field: "scrap_reference",
      headerName: "Scrap Ref",

      sortable: false,
      width: width > 1370 ? 50 : width < 900 ? 50 : 50,
      renderCell: (cellValues: any) => {
        return (
          <>
            {isUrl(cellValues?.row?.scrap_reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}>
              Link
            </span>:
            <span
            // style={{ textDecoration: "underline", cursor: "pointer" }}
            // onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}
            >
            {cellValues?.row?.scrap_reference}
          </span>}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date Added",

      sortable: false,
      minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    },
  ];

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     getMaterialData(panel);
  //     // typeId, databaseName,categoryName
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`);
  //   }
  // };
  const handleChange = (categoryName: any) => {
		getMaterialData(categoryName);
		setTabVale(categoryName);		
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}`);
	};
  React.useEffect(() => {
    if (categoryName) {
      getMaterialData(categoryName);
    }
  }, [categoryName]);

  let rows: any = [];
  let dataGridDataGenerator: any =
    tableData &&
    tableData?.map((item: any, index: any) => {
     
      
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        material_code: item?.material_code,
        material_grade: item?.material_grade,
        material_subgrade: item?.material_subgrade,
        shape: item?.shape,
        density: item?.density,
        description: item?.description,
        material_cost: ` ${item?.material_cost}`,
        material_source: item?.material_source,
        material_reference: item?.material_reference,
        scrap_cost: ` ${item?.scrap_cost}`,
        scrap_source: item?.scrap_source,
        scrap_reference: item?.scrap_reference,
        mapped_status: item?.mapped_status,
        category: item?.category,
        currency_id: item?.currency,
				currency_code: item?.currency_code,
				unit: item?.unit,
      };
      rows.push(objShared);
    });

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows && rows,
  };
  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_material/", formData, {},0)
      .then((res: any) => {
        // setParentsCounter((prev: any) => prev + 1);
        getCategoryList();
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">"${res?.data}"</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        const { data } = err?.response
        Swal.fire({
          icon: "error",
          html: `<div>
        <br />
        <p style="color:red;">${data[0]}</p>   
         </div>`,
        });
        setReverseUpload(false);
      });

  };


  const DownLoadFile = (e: any) => {
    e?.stopPropagation();
    setLoader(true);
    API.get("/api/db/database/download/material/")
      .then((res: any) => {
        window.open(res?.data, "_self", " noopener");
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Sever Error");
      });
  };

  
  
  return (
    <div
      style={{ width: "100%", height: "90vh" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      <input
        id="PDF Upload"
        accept=".csv,.xlsx"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        onClick={handleInputClick}
        ref={inputElRefPdf}
      />
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (<>
          <Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}}>
            {categoryList &&
              categoryList?.map((item: any, index: any) => {
                return (<>   
                    <Box key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor: item?.category == categoryName  ? 'primary.main' : 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color: item?.category == categoryName  ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => handleChange(item?.category)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>	
                    </> );
              })}
          </Box>
          <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent:'flex-end',
            marginLeft: "0.5rem",
            columnGap: "0.5rem",
          }}>
            {useraction && useraction?.includes("C") && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem', columnGap: '0.5rem' }}>
                          <LoadingButton
                            loading={resverUpload}
                            title="Upload"
                            onClick={() => ReverseUpload()}
                            sx={{
                              cursor: "pointer", fontSize: { lg: '1.8rem', xl: '1.6rem' }, minWidth: '1rem',
                              '.MuiCircularProgress-root': {
                                color: 'primary.main',
                              },
                            }}
                            startIcon={<UploadIcon sx={{ marginRight: '-1rem' }} />}
                          />



                          <LoadingButton
                            title="Download"
                            sx={{
                              color: "primary.main", fontSize: { lg: '1.8rem', xl: '1.6rem' }, minWidth: '1rem',
                              '.MuiCircularProgress-root': {
                                color: 'primary.main',
                              },
                            }}
                            loading={Loader}
                            onClick={(e: any) => DownLoadFile(e)}
                            startIcon={<GetAppIcon sx={{ marginRight: '-1rem' }} />}
                          />

                          {useraction && useraction?.includes("C") &&<Button
                            size="small"
                            sx={{ width: '10rem' }}
                            startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
                            onClick={() => subRevisionEdit.open("add")}
                          >
                            Add Material
                          </Button>}
                        </Box>
                      )}
          </Box>
           <Box
          sx={{
            height: {lg:'78vh',xl:'83vh'},
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "#ddefff !important",
              textAlign: "center !important",
            },
          }}>
          <DataGrid
            headerHeight={42}
            rowHeight={36}
            {...dataStructureDataGrid}
            loading={tableDataLoading}
            // checkboxSelection
            // disableRowSelectionOnClick
            components={{
              Toolbar: () => {
                return CustomToolbar();
              },
            }}
            sx={{
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
            }}
          />
        </Box>
      </>)}
     
      {subRevisionEdit.isOpen && (
        <CreateRevisionDatabaseModal
          isOpen={subRevisionEdit.isOpen}
          onCloseModal={subRevisionEdit.close}
          actions={subRevisionEdit.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryList={getCategoryList}
        />
      )}
      {fetchRawMaterial.isOpen && (
        <FetchRawMaterialModals
          isOpen={fetchRawMaterial.isOpen}
          onCloseModal={fetchRawMaterial.close}
          categorydata={categoryList && categoryList}
        />
      )}
      {databaseMaterialInfo.isOpen && <RawMaterialDatabaseInfoModal
        isOpen={databaseMaterialInfo.isOpen}
        onCloseModal={databaseMaterialInfo.close}
        itemId={databaseMaterialInfo.propsId}
      />}
      {moveModal.isOpen &&
        <RawMaterialDBMove
          isOpen={moveModal.isOpen}
          onCloseModal={moveModal.close}
          rowItems={moveModal.propsId}
          getMaterialData={getMaterialData}
          expanded={expanded}
        />
      }
    </div>
  );
}
