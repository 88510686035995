// react

import React, { useContext, useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from "classnames";
import { ID } from '../../../../../utlis/commonInterfaces';
import { useAppDispatch } from '../../../../../../AppRouter';
import { RootState } from '../../../../../Redux/Store/storeConfigurations';
import { rawMaterialCardSelectors } from '../../../../../Redux/Reducers/rawMaterialCard.reducer';
import { deleteSwal } from '../../../../../utlis/deleteSwal';
import { deleteRawMaterialCardCost, updateRawMaterialCardCost } from '../../../../../Redux/Actions/rawMaterialCard.actions';
import { toFixed } from '../../../../../utlis/toFixed';
import { useBaseModal } from '../../../../SearchPage/useBaseModal';
import SaveIcon from '@mui/icons-material/Save';
import { joinClsx } from '../../../../../utlis/joinClsx';
import { Box, TableCell, TableRow, TextField } from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useRouteParams } from '../../../../../ui-reusable-component/useRouteParams';
import { LoadingButton } from '@mui/lab';
import styles from "./RawMaterialCardCostRow.module.scss";
import { processCardSelectors } from '../../../../../Redux/Reducers/processCard.reducer';
import { deleteProcessCardCost, updateProcessCardCost } from '../../../../../Redux/Actions/processCard.action';
import { userPermission } from '../../CostigngCalculatorSection';


// services
// utils


// constants
// import { CALCULATOR_CATEGORY } from 'app/constants/calculator.constants';

// factories
// core
// redux

// import { rawMaterialCardSelectors } from 'app/Redux/Reducers/rawMaterialCard.reducer';
// import { deleteRawMaterialCardCost, updateRawMaterialCardCost } from 'app/Redux/Actions/rawMaterialCard.actions';

// // ui-components
// // others
// import { useAppDispatch } from 'AppRouter';
// import RawMaterialCostRowInfo from './RawMaterialCostRowInfo/RawMaterialCostRowInfo';
// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';

// // styles

// import MaterialCopyModal from './MaterialCopyModal/MaterialCopyModal';
// import { useBaseModal } from 'app/Shared/useBaseModal';
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface RawMaterialCardCostRowProps {
    costId: ID;
    showCode?: boolean;
    handleChangeMaterial?: () => void;
    handleChangeFastener?: () => void;
    handleChangePurchasingItem?: () => void;
    setActiveTab?: any;
    activeTab?: any;

}

const ProcessCardCostRow: React.FC<RawMaterialCardCostRowProps> = (props) => {
    const { costId, showCode, handleChangeMaterial, handleChangeFastener, handleChangePurchasingItem, setActiveTab, activeTab } = props;
    const dispatch = useAppDispatch();

    const { vault, topVault, projectId, projectname, productId, abbrebation, cardtype, tableId, costEstimationId, tableCounter, condition, calctype, calcultorId,scenarioId } =
        useRouteParams<any>();
    const [Counter, setCounter] = useState<any>(0)
    const history = useHistory();
    const { url } = useRouteMatch();
    // const readonly = useCostingReadonly();
    const [highlightTab, setActive] = React.useState<boolean>(false);
    const [Color, setColor] = useState<any>(false)
    const [Loader, setLoader] = useState<any>(false)
    const useraction=useContext<any>(userPermission)
    var vc= useraction && useraction?.action?.includes("U") 
    // ----------------------------------------------------------------------------------
    // state

    const [alias, setAlias] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [sequence,setSequence]=useState<any>(0)
    const [type, setType]: any = useState();

    // ----------------------------------------------------------------------------------
    // selectors

    const cost = useSelector((state: RootState) => {
        return processCardSelectors.selectById(state, costId);
    });


    React.useEffect(()=>{
        if(cost){
            setSequence(cost?.sequence)
        }
    },[cost])

    // ----------------------------------------------------------------------------------
    // handlers

    const handleChange = (e: any,) => {
        //@ts-ignore 
        setColor(true)
        if (e?.target?.name === "alias") {

            setAlias(e?.target?.value);
        } else if(e?.target?.name === "sequence"){
            setSequence(+e?.target?.value);
        }else {
            setQuantity(e?.target?.value as any);
        }

    };

    const handleDelete = async () => {
        const confirm = await deleteSwal("Delete Process Data?");
        if (confirm) await dispatch(deleteProcessCardCost({ id: costId }));
        setCounter((prev: any) => prev + 1)
        var random=Math?.random()
        history.push(
            `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${0}/${0}/${Counter == 0 ? random : Counter}/0/${calctype}/0/${scenarioId}`)
        // @ts-ignore
        // sessionStorage.setItem("calCounter", Math?.random())

        // history.push( url );
    };

    const handleOpenCalculator = () => {
        // column for which the calculations are done
        // const column = "cost";
        // let link = `${url}/${costId}/${column}`;

        // if ( cost?.link?.commodity_mapping && cost.link.process ) {
        //     link += `/${cost.link.commodity_mapping}/${cost.link.process}/calc/${CALCULATOR_CATEGORY.RAW_MATERIAL}`;
        // }

        // history.push( link );
    };

    const handleSave = async () => {
        setCounter((prev: any) => prev + 1)
        setLoader(true)
        const action = await
            dispatch(updateProcessCardCost({
                id: costId,
                updates: { alias, quantity,sequence },
            }));


        if (updateProcessCardCost.fulfilled.match(action)) {
            setLoader(false)
            setColor(false)
            history.push(
                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${Counter == 0 ? Math.random() : Counter}/0/${calctype}/0/${scenarioId}`)
            // @ts-ignore
            // sessionStorage.setItem("calCounter", Math?.random())
        }
        if (updateProcessCardCost.fulfilled.match(action) == false) {
            setLoader(false)
            setColor(false)
        }
        // dispatch( updateRawMaterialCardCost( {
        //     id: costId,
        //     updates: { alias, quantity }
        // } ) );
    };

    const handleCopy = () => {
        materialCopyModal.open();
    };
    // ----------------------------------------------------------------------------------
    // side effects

    // sync alias key w/ state
    useEffect(() => {
        if (cost?.alias) setAlias(cost.alias);
        if (cost?.quantity) setQuantity(cost.quantity);
    }, [cost]);
    useEffect(() => {

        if (activeTab && activeTab === costId) {
            setActive(true);

        }
        else {
            setActive(false);
        }

    }, [activeTab]);

    // ----------------------------------------------------------------------------------
    // jsx helpers

    const getCost = (quantity = 1) => {
        if (!cost) return 0;
        return toFixed((cost?.machine_cost+cost?.labour_cost+cost?.tool_cost+cost?.setup_cost) * quantity);
        // return toFixed( ( cost.material_cost + cost.bop_cost ) * quantity );
    };

    //----------------------------------------------------------------------------------
    //popup
    const materialCopyModal = useBaseModal();
    // ----------------------------------------------------------------------------------
    // jsx abstraction
    let handle: any = { title: '', control: '' };

    // const renderCodeOrName = () => {
    //     if ( !cost ) return "-";
    //     if ( cost.material ) { handle.control = handleChangeMaterial; handle.title = "Change Material Info"; return cost.material[showCode ? "material_code" : "category"]; };
    //     if ( cost.fastener ) { handle.control = handleChangeFastener; handle.title = "Change Fastener Info"; return cost.fastener[showCode ? "fastener_code" : "fastener_type"]; };
    //     if ( cost.purchasing_item ) { handle.control = handleChangePurchasingItem; handle.title = " Change Purchasing Info"; return cost.purchasing_item[showCode ? "code" : "name"]; };

    //     return "-";
    // };

    // ----------------------------------------------------------------------------------
    // jsx

    if (!cost) return null;
    return (
        <>
            {/* <MaterialCopyModal
                isOpen={ materialCopyModal.isOpen }
                onClose={ materialCopyModal.close }
                costId={ costId }
                onSelect={ handleCopy }
            /> */}
           { <TableRow
                data-testid="raw-material-card-cost-row"
                sx={{ borderLeft: '1px solid', borderColor: 'primary.main' }}
            // className={ joinClsx( highlightTab ? styles.active : styles.inactive, styles.container, "fit-input-childeren" ) }
            // active={ highlightTab }
            >
                <TableCell sx={{ padding: "0rem 0.5rem", borderLeft: '1px solid', borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                <TextField
                        placeholder="Sequence"
                        name="sequence"
                        value={sequence}
                        variant="standard"
                        onChange={handleChange}
                        sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                    />
                </TableCell>
                <TableCell sx={{ padding: "0rem 2px", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    <TextField
                        placeholder="Sub Partname"
                        name="alias"
                        title={alias}
                        value={alias}
                        disabled={cost && cost?.mirror_id == null ?false:true}
                        variant="standard"
                        onChange={handleChange}
                        sx={{
                            width:'100%',
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                    />
                </TableCell>

                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.commodity_name}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.process_name}
                </TableCell>
             
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.machine_cost?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.labour_cost?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.tool_cost?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {cost?.setup_cost?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {(cost?.machine_cost+cost?.labour_cost+cost?.tool_cost+cost?.setup_cost)?.toFixed(2)}
                </TableCell>

                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>

                    <TextField
                        type="number"
                        placeholder="Qty."
                        name="quantity"
                        value={quantity}
                        variant="standard"
                        onChange={handleChange}
                        sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                    // className={ styles.quantityInput }
                    />
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {getCost(quantity)}
                </TableCell>
                <TableCell sx={{ padding: "0rem 0.5rem", borderRight: '1px solid', borderColor: 'primary.main', textAlign: 'center' }}>
                    {/* <CalculateIcon sx={{cursor:"pointer"}} titleAccess="calculator"  onClick={ () => { handleOpenCalculator(); setActiveTab( costId ); } } /> */}
                    {
                        <>
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', columnGap: '0.2rem', width: '100%' }}>

                                {
                                 useraction && useraction?.action?.includes("U") &&
                                
                                <LoadingButton loading={Loader} title='Save' onClick={handleSave} sx={{ cursor: "pointer", minWidth: '1rem', color: Color ? "red" : "" }} startIcon={<SaveIcon sx={{ fontSize: '1.7rem', marginRight: '-1rem' }} />} />
}

{
       useraction && useraction?.action?.includes("D") &&

                                <DeleteIcon sx={{ cursor: "pointer" }} titleAccess="trash" onClick={handleDelete}


                                // className={ clsx( { [styles.hide]: readonly } ) }
                                />
}
                            </Box>
                        </>

                    }
                    {/* <RawMaterialCostRowInfo cost={ cost } />
                    { !readonly ?
                        <Icon name="copy" link onClick={ handleCopy } />
                        : ""
                    } */}
                </TableCell>
            </TableRow>}
        </>
    );
};

// ----------------------------------------------------------------------------------

export default ProcessCardCostRow;
