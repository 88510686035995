import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import { isNull, isUndefined, result, set } from "lodash";
import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./BOMTreeViewCreate.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {
  Box,
  Typography,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";

import swal from "sweetalert";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { API } from "../../../api-services";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCompareCount,
  setIsCompare,
} from "../../../Redux/Actions/compareopen.action";
import { DialogComponent } from "../../../utlis/DialogComponent";
import { ControlledMenu } from "@szhsin/react-menu";
import BOMEntryAddBOMPartModal from "./BOMEntryAddBOMPartModal";
import BOMEntryCopytoOtherModal from "./BOMEntryCopytoOtherModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import AgentIcon from "../../../Assets/images/AgentIcon.svg";
import BOMEntryAddSystemModal from "./BOMEntryAddSystemModal";
import BOMEntryWeightAlertModal from "./BOMEntryWeightAlertModal";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";
import SheetViewModal from "./SheetViewModal";
import AgentModal from "./AgentModal";
import BOMCopyModal from "./BOMCopyModal";
import { Button } from "devextreme-react";
import { Opacity } from "@mui/icons-material";
import BOMFlagModal from "./BOMFlagModal";
import FlagIcon from "@mui/icons-material/Flag";
import NestedMenuItem from "material-ui-nested-menu-item";
import MovePartModal from "./MovePartModal";

import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import { userPermissionAction } from "./BOMEntryLandingPage";
function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setrecyclebinCounter: any;
  setParentsCounter?: any;
}
const BOMTreeViewCreate = (props: TreeViewComponentsProps) => {
  const {
    HierarchyData,
    onSelectCallback,
    setParentsCounter,
    setrecyclebinCounter,
  } = props;
  const {
    projectId,
    topVault,
    vault,
    leafNode,
    treeabbr,
    scenarioId,
    projectName,
    workstation,
    isLeaf,
    copyStatus,
  } = useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [opendailog, setOpendailog] = React.useState<any>(false);
  const [TopVaultsfetched, setTopVaultsfetch] = useState<any>([]);
  const [menuItems, setMenuItems] = React.useState<any>();
  const [validatedModules, setValidatedModules] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ModalData, setModalData] = useState<any>(null);
  const [PartAssemblyData, setPartAssemblyData] = React.useState<any>([]);
  const [newVault, setNewvault] = useState<any>();
  const [PartLoader, setPartLoader] = React.useState<any>(false);
  const imageIframeRef = useRef<any>(null);
  const [contentRef, setContentRef] = React.useState<any>();
  const sheetview = useBaseModal();
  const AddBOMPartModal = useBaseModal();
  const [QRLoader, setQRLoader] = useState<any>(false);
  const [SystemData, setSystemData] = useState([]);
  const movemodal = useBaseModal();
  const userAction = React.useContext(userPermissionAction);

  let ActionUser = userAction && userAction?.action;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
    // setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
    // setOpenContext(true);
    // getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };

  const getVault = (treeabbr: any, idd: any) => {
    if (treeabbr == "product") {
      return { top_vault: idd };
    } else {
      return { vault: idd };
    }
  };

  const [expanded, setExpanded] = React.useState<any>([topVault]);
  const dispatch = useDispatch();
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  const store: any = useSelector((state) => state);
  const [TopLevel, setTopLevel] = React.useState();
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>([topVault]);
  const history = useHistory<any>();
  const AgentDataModal = useBaseModal();
  const FlagModal = useBaseModal();
  const [copytype, setcopytype] = useState<any>();
  const [systemloader, setSystemLoader] = useState<any>(false);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [routeLoader, setrouteLoader] = useState<any>(false);
  const [mastervalidationloader, setmastervalidationloader] =
    useState<any>(false);
  const copymodal = useBaseModal();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const handleCompareClick = (abbr: any, idd: any) => {
    API.post(
      "/customer/compare_cart/",
      {
        vault: parseInt(idd),
        project: projectId,
      },
      {},
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("is_compare", "true");
        dispatch(setIsCompare("true"));
        dispatch(setAddCompareCount(store.is_compare?.add_count + 1));
      })
      .catch((err: any) => {
        if (err.response.status === 400) {
          // swal("Selection Exceeds",'warning');
          swal({
            // title: "Good job!",
            text: "Selection Exceeds!",
            icon: "warning",
            //@ts-ignore
            button: "OK",
          });
        }
      });
  };
  const hoverData = (title: any, id: any) => {
    if (url.includes("costing")) {
      if (id == topVault) {
        setTitleString("");
        API.get(
          "/customer/system_cost/",
          {
            top_vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      } else {
        setTitleString("");
        API.get(
          "/customer/system_cost/",
          {
            vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      }
    }
  };

  // useEffect(()=>{return sessionStorage.setItem("BOMCreateNode",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (e: any, nodeId: any, abbreviation: any) => {
    let a: any = sessionStorage.getItem("BOMCreateNode");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item !== nodeId;
      });
      let b: any = sessionStorage?.setItem(
        "BOMCreateNode",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(nodeId);
      let b: any = sessionStorage?.setItem(
        "BOMCreateNode",
        JSON.stringify(val)
      );
    }
    setActiveId(nodeId);

    onSelectCallback(
      nodeId,
      abbreviation,
      HierarchyAllData,
      HierarchyAllData.id
    );
    const index = expanded?.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(nodeId);
    } else {
      copyExpanded?.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
    if (url?.includes("false")) {
      if (topVault == nodeId) {
        API.get(
          "customer/view_data_activity/",
          {
            top_vault: nodeId,
          },
          0
        ).then((res: any) => { });
      } else {
        API.get(
          "customer/view_data_activity/",
          {
            vault: nodeId,
          },
          0
        ).then((res: any) => { });
      }
    }
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("BOMCreateNode");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [topVault, sessionStorage.getItem("BOMCreateNode")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  useEffect(() => {
    setTimeout(
      () =>
        document.getElementById(vault)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        }),
      1000
    );
    // @ts-ignore
  }, [vault]);
  const CopyPart = () => {
    setModalData(ModalData);
    sessionStorage.setItem("copyId", ModalData?.id);
    setcopytype("pastenew");
    setModalData(ModalData);
    copymodal.open();

    setAnchorEl(null);
  };
  const copypaste = (type: any) => {
    if (type == "pastenew") {
      setcopytype("pastenew");
      setModalData(ModalData);
      setAnchorEl(null);
      copymodal.open();
    } else if (type == "paste") {
      setcopytype("paste");
      setModalData(ModalData);
      setAnchorEl(null);
      copymodal.open();
    }
  };
  const copypartmsg = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Part/Assembly Added Successfully.
</p>
<p style="color:#007fff;">Due to  this action, parents is no longer a copy, and the part number will change to update the details.
</p>
<br/>   
</div>`,
      });
    }
  };

  const DeleteHanlder = () => {
    if (ModalData?.cost_status >= 1) {
      swal({
        // text: `Are you sure ?`,
        title: `This action will delete the BOM Parameter and link Costing`,
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${ModalData?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);
              setAnchorEl(null);
              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
              });
            });
        }
      });
    } else {
      swal({
        // text: `Are you sure ?`,
        title: `This action will  delete ${ModalData?.name}`,

        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${ModalData?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);
              setAnchorEl(null);
              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
              });
            });
        }
      });
    }
  };

  const getColor = (status: any) => {
    switch (status) {
      case 0:
        return "red";
      case 1:
        return "#007fff";
      case 2:
        return "#007fff";
      default:
        return "green";
    }
  };

  const AddBOMPartHandler = () => {
    {
      // setPartLoader(true);
      AddBOMPartModal.open();
      setAnchorEl(null);
      // API.get("/api/db/part_name/", { vault: ModalData?.id ,search:true}, 0)
      //   .then((res: any) => {
      //     setPartAssemblyData(res?.data);
      //     AddBOMPartModal.open();
      //     setAnchorEl(null);
      //     setPartLoader(false);
      //   })
      //   .catch((err: any) => {
      //     setPartLoader(false);
      //     console.log("Server Error");
      //   });
    }
  };

  const AddBOMPartHandlerSystem = (e: any, data: any) => {
    {
      e?.stopPropagation();
      // setSystemLoader(true);

      setModalData(data);
      AddBOMPartModal.open();
      // API.get("/api/db/part_name/", { vault: data?.id }, 0)
      //   .then((res: any) => {
      //     setPartAssemblyData(res?.data);
      //     setSystemLoader(false);
      //     setModalData(data);
      //     AddBOMPartModal.open();
      //   })
      //   .catch((err: any) => {
      //     setSystemLoader(false);
      //     console.log("Server Error");
      //   });
    }
  };

  const CopytoOtherModal = useBaseModal();
  const CopytoOtherHandler = () => {
    CopytoOtherModal.open();
  };

  const AddSystemModal = useBaseModal();
  const AddSystemHandler = (e: any, data: any) => {
    e?.stopPropagation();

    setSystemLoader(true);

    API.get(
      `/api/db/sub_system/`,
      {
        project: projectId,
        vehicle_types: data?.vechile_type,
        top_vault: data?.id,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res?.data);
        AddSystemModal.open();
        setSystemLoader(false);
        setModalData(data);
      })
      .catch((err: any) => {
        setSystemLoader(false);
        console.log("Server Error");
      });
  };
  const Sheetviewhandler = () => {
    sheetview?.open();
  };
  const WeightAlertModal = useBaseModal();
  const WeightAlertHandler = (e: any, data: any) => {
    e?.stopPropagation();
    setModalData(data);
    WeightAlertModal.open();
  };

  React.useEffect(() => {
    setNewvault(sessionStorage?.getItem("newvault"));
    if (newVault !== null) {
      setTimeout(function () {
        setNewvault(null);
        sessionStorage.removeItem("newvault");
      }, 10000);
    }
  }, [newVault, sessionStorage?.getItem("newvault")]);
  const handlePrint = useReactToPrint({
    content: () => imageIframeRef.current,
  });

  const PrintQRCode = () => {
    setQRLoader(true);
    API.get("/xcpep/vault_info/", { vault: ModalData?.id })
      .then((res1: any) => {
        setContentRef(res1?.data[0]?.qr_link);
        setTimeout(() => {
          setQRLoader(false);
          handlePrint();
          setAnchorEl(null);
        }, 1000);
      })
      .catch((err: any) => {
        setQRLoader(false);
        console.log("Server Error");
      });
  };

  const FlagHandler = (e: any, data: any) => {
    FlagModal.open();
    setModalData(data);
  };
  const AgentPartModalHandler = (e: any, data: any, isProduct: boolean) => {
    e?.stopPropagation();
    AgentDataModal.open();
    setModalData(data);
    setIsProduct(isProduct);
  };
  const costroute = () => {
    setAnchorEl(null);
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${ModalData?.name}/${topVault}/${ModalData?.id}/0/0/0/0/0/0/0/0/0`
    );
  };

  const SucessAndErrorMsg = (type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> System Added Successfully
</p>   
</div>`,
      });
    }
    if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
<br />
<p style="color:red;">Server Error
</p>   
 </div>`,
      });
    }
  };

  const SucessAndErrorMsgVault = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Part/Assembly Added Successfully
</p>   
</div>`,
      });
    }
  };

  const costcomapreroute = () => {
    setrouteLoader(true);

    API.get("/xcpep/vault_info/", { vault: ModalData?.id })
      .then((res: any) => {
        if (res?.data[0]) {
          sessionStorage.setItem(
            "Comparenode",
            JSON.stringify(res?.data[0]?.ansectors_name)
          );
          window.open(
            `/#/newcompare/${projectId}/${1}/${res?.data[0]?.product_list?.toString()}/${res?.data[0]?.abbreviation
            }/${res?.data[0]?.s_name}/${res?.data[0]?.part_name}/1/vehicle/${res?.data[0]?.is_leaf
            }/${res?.data[0]?.depth}/${res?.data[0]?.path}`
          );
        }
        // if (abbreviation == "product") {
        //   history.push(
        //     `
        //   );
        // } else {
        //   history.push(
        //     `/newcompare/${projectId}/${1}/${res?.data[0]?.product_list}/${res?.data[0]?.abbreviation}/${value}/${name}/${Count}/${TopId}/${leafNode}/${depth}/${path}`
        //   );
        // }
        setrouteLoader(false);
      })
      .catch((err: any) => {
        setrouteLoader(false);
        console.log("Server Error");
      });
  };
  const CutPart = () => {
    setModalData(ModalData);
    movemodal.open();
    sessionStorage.setItem("moveId", ModalData?.id);

    setAnchorEl(null);
  };

  let CopyId = sessionStorage?.getItem("copyId");
  let MoveId = sessionStorage?.getItem("moveId");
  // @ts-ignore
  let MoveData = JSON?.parse(MoveId);
  // @ts-ignore
  let matchData = JSON?.parse(CopyId);

  let matchquery = sessionStorage?.getItem("TargetIds");
  // @ts-ignore
  let matchDatasec = JSON?.parse(matchquery);
  const SucessAndCoptpart = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> You will be notified once a part/assembly is successfully copied.
</p>   
</div>`,
      });
    }
  };
  const SucessAndMovepart = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> You will be notified once a part/assembly is successfully moved.
</p>   
</div>`,
      });
    }
  };
  const SucessAndCoptpartToOther = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> You will be notified once a part/assembly is successfully copied.
  </p>   
  </div>`,
      });
    }
  };

  const MasterValodationhandler = () => {
    setmastervalidationloader(true);
    API.get(`xcpep/bom_hirearchy_function/`, {
      master_validation: true,
      top_vault: topVault,
    })
      .then((res: any) => {
        setmastervalidationloader(false);
        {
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;"> All subsystems/assemblies/parts have been validated successfully.
    </p>   
    </div>`,
          });
        }

        setParentsCounter((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        {
          Swal.fire({
            icon: "error",
            html: `<div>
    <br />
    <p style="color:#007fff;"> Server Error.
    </p>   
    </div>`,
          });
        }
        setmastervalidationloader(false);
        console.log("Sever error");
      });
  };
  const handleRedirect = (module: any, treeabbr: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          ...getVault(treeabbr, id),
          with_image: true,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              ...getVault(treeabbr, id),
              with_image: true,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          //  openStatusContent.open();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          ...getVault(treeabbr, id),
          with_image: false,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              ...getVault(treeabbr, id),
              with_image: false,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          //  openStatusContent.open();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleRedirectSingle = (id: any) => {
    setIconText("firstdownload");
    setContentText(
      "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    );
    openStatusContent.open();
    API.get(
      "customer/individual_report/",
      { vault: id, individual_type: "BOM" },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch((err: any) => { });
  };

  

  return (
    <>
      {contentRef && (
        <>
          <Box className={styles.centeredContent}>
            <img
              ref={imageIframeRef}
              src={contentRef}
              height={200}
              width={200}
            />
          </Box>
        </>
      )}
      {AddBOMPartModal.isOpen && (
        <BOMEntryAddBOMPartModal
          onCloseModal={AddBOMPartModal.close}
          isOpen={AddBOMPartModal.open}
          getData={ModalData}
          copypartmsg={copypartmsg}
          AssemblyData={PartAssemblyData}
          setParentsCounter={setParentsCounter}
          SucessAndErrorMsgVault={SucessAndErrorMsgVault}
        />
      )}

      {FlagModal.isOpen && (
        <BOMFlagModal
          onCloseModal={FlagModal.close}
          isOpen={FlagModal.open}
          getData={ModalData}
        />
      )}
      {AgentDataModal?.isOpen && (
        <AgentModal
          onCloseModal={AgentDataModal.close}
          isOpen={AgentDataModal.open}
          getData={ModalData}
          isProduct={isProduct}
        />
      )}

      {sheetview?.isOpen && (
        <SheetViewModal
          onCloseModal={sheetview.close}
          isOpen={sheetview.open}
          getData={ModalData}
        />
      )}

      {CopytoOtherModal.isOpen && (
        <BOMEntryCopytoOtherModal
          onCloseModal={CopytoOtherModal.close}
          isOpen={CopytoOtherModal.open}
          getData={ModalData}
          setParentsCounter={setParentsCounter}
          SucessAndCoptpartToOther={SucessAndCoptpartToOther}
        />
      )}

      {AddSystemModal.isOpen && (
        <BOMEntryAddSystemModal
          onCloseModal={AddSystemModal.close}
          isOpen={AddSystemModal.open}
          getData={ModalData}
          SystemData={SystemData}
          SucessAndErrorMsg={SucessAndErrorMsg}
          setParentsCounter={setParentsCounter}
        />
      )}

      {WeightAlertModal.isOpen && (
        <BOMEntryWeightAlertModal
          onCloseModal={WeightAlertModal.close}
          isOpen={WeightAlertModal.open}
          queryData={ModalData}
        />
      )}
      {copymodal.isOpen && (
        <BOMCopyModal
          onCloseModal={copymodal.close}
          isOpen={copymodal.open}
          getData={ModalData}
          copytype={copytype}
          SucessAndCoptpart={SucessAndCoptpart}
          setParentsCounter={setParentsCounter}
        />
      )}

      {movemodal.isOpen && (
        <MovePartModal
          onCloseModal={movemodal.close}
          isOpen={movemodal.open}
          getData={ModalData}
          SucessAndMovepart={SucessAndMovepart}
          setParentsCounter={setParentsCounter}
        />
      )}

      {
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {userProfilePic && userProfilePic[0]?.is_superuser && (
            <>
              {ModalData?.abbreviation == "product" && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => MasterValodationhandler()}
                >
                  Master Validation{" "}
                  <p style={{ textAlign: "center" }}>
                    {mastervalidationloader && <CircularProgress size={20} />}
                  </p>
                </MenuItem>
              )}
            </>
          )}

          {ActionUser && ActionUser.includes("C") && (
            <>
              {ModalData?.abbreviation == "product" ? (
                <>
                  {PartLoader ? (
                    <MenuItem sx={{ color: "primary.main", fontSize: "1rem" }}>
                      Add Part
                      <p style={{ textAlign: "center" }}>
                        <CircularProgress size={20} />
                      </p>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      onClick={(e: any) => AddSystemHandler(e, ModalData)}
                    >
                      Add System
                    </MenuItem>
                  )}
                </>
              ) : (
                <>
                  {ModalData?.is_leaf == false ? (
                    <>
                      {PartLoader ? (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                        >
                          Add Part
                          <p style={{ textAlign: "center" }}>
                            <CircularProgress size={20} />
                          </p>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          onClick={() => AddBOMPartHandler()}
                        >
                          Add Part
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}

          {ActionUser && ActionUser?.includes("D") && (
            <>
              {ModalData?.abbreviation !== "product" && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => DeleteHanlder()}
                >
                  Delete Part
                </MenuItem>
              )}
            </>
          )}
          {ActionUser && ActionUser?.includes("C") && (
            <>
              {
                <MenuItem
                  sx={{
                    color: "primary.main",
                    fontSize: "1rem",
                    opacity: ModalData?.abbreviation == null ? "1" : "0.5",
                  }}
                  onClick={() => ModalData?.abbreviation == null && CopyPart()}
                >
                  Copy Part
                </MenuItem>
              }
              {
                <MenuItem
                  sx={{
                    color: "primary.main",
                    fontSize: "1rem",
                    opacity: ModalData?.abbreviation == null ? "1" : "0.5",
                    // opacity: ModalData?.status == 3 ? "1" : "0.5",
                  }}
                  onClick={() => ModalData?.abbreviation == null && CutPart()}
                >
                  Cut
                </MenuItem>
              }

              {/* {ModalData?.is_leaf == false && (
              <>
                {matchData !== matchDatasec && (
                  <>
                    <MenuItem
                      sx={{
                        color: "primary.main",
                        fontSize: "1rem",
                        opacity: matchData !== null ? "1" : "0.5",
                        
                      }}
                      onClick={() => copypaste("paste")
                      
                    }
                    >
                      <Button
                        disabled={matchData !== null ? false : true}
                     
                      >
                        Paste
                      </Button>
                    </MenuItem>

                    <MenuItem
                      sx={{
                        color: "primary.main",
                        fontSize: "1rem",
                        opacity: matchData !== null ? "1" : "0.5",
                      }}
                      onClick={() => copypaste("pastenew")}
                    >
                      <Button
                        disabled={matchData !== null ? false : true}
                
                      >                        
                        Paste as New
                      </Button>
                    </MenuItem>
                  </>
                )}
              </>
            )} */}
            </>
          )}

          {ActionUser && ActionUser?.includes("C") && (
            <>
              {ModalData?.abbreviation == null && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => CopytoOtherHandler()}
                >
                  Copy to other Project/Product
                </MenuItem>
              )}
            </>
          )}
          {ModalData?.is_leaf == false && (
            //@ts-ignore
            <NestedMenuItem
              style={{
                color: "primary.main",
              }}
              label={`Download Report`}
              parentMenuOpen={open}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                  color: "primary.main",
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                    // borderBottom:'1px solid #8080801c !important'
                  }}
                  onClick={() =>
                    handleRedirect(
                      "w",
                      HierarchyAllData?.abbreviation,
                      HierarchyAllData?.id
                    )
                  }
                >
                  Consolidated with images
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                  }}
                  onClick={() =>
                    handleRedirect(
                      "w/o",
                      HierarchyAllData?.abbreviation,
                      HierarchyAllData?.id
                    )
                  }
                >
                  Consolidated w/o images
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                    // borderBottom:'1px solid #8080801c !important'
                  }}
                  onClick={
                    () => {
                      setIconText("firstdownload");
                      setContentText(
                        "We’ve got this report downloading faster than a cheetah running.But in the meantime, keep using xcPEP like the productivity champion !"
                      );
                      openStatusContent.open();
                      API.get(
                        "/xcpep/download_part_image/",
                        {
                          top_vault:
                            HierarchyAllData?.abbreviation === "product"
                              ? HierarchyAllData?.id
                              : undefined,
                          vault:
                            HierarchyAllData?.abbreviation !== "product"
                              ? HierarchyAllData?.id
                              : undefined,
                        },
                        0
                      )
                        .then((res: any) => {
                          window.open(res?.data, "_self");
                        })
                        .catch((err: any) => {
                          Swal.fire({
                            icon: "error",
                            html: `<div>
                                 <br />
                                 <p style="color:"red">Server Error</p>   
                                  </div>`,
                          });
                        });
                    }
                    // handleRedirect(
                    //   "w",
                    //   HierarchyAllData?.abbreviation,
                    //   HierarchyAllData?.id
                    // )
                  }
                >
                  Download vault Images as ZIP
                </MenuItem>
              </Box>
            </NestedMenuItem>
          )}
          {ModalData?.is_leaf == true && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => handleRedirectSingle(HierarchyAllData?.id)}
            >
              Part BOM excel Download
            </MenuItem>
          )}
          {ModalData?.is_leaf == true && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                setIconText("firstdownload");
                setContentText(
                  "We’ve got this report downloading faster than a cheetah running.But in the meantime, keep using xcPEP like the productivity champion !"
                );
                openStatusContent.open();
                API.get(
                  "/xcpep/download_part_image/",
                  {
                    top_vault:
                      HierarchyAllData?.abbreviation === "product"
                        ? HierarchyAllData?.id
                        : undefined,
                    vault:
                      HierarchyAllData?.abbreviation !== "product"
                        ? HierarchyAllData?.id
                        : undefined,
                  },
                  0
                )
                  .then((res: any) => {
                    window.open(res?.data, "_self");
                  })
                  .catch((err: any) => {
                    Swal.fire({
                      icon: "error",
                      html: `<div>
                           <br />
                           <p style="color:"red">Server Error</p>   
                            </div>`,
                    });
                  });
              }}
            >
              Download vault Images as ZIP
            </MenuItem>
          )}
          {ModalData?.abbreviation !== "product" && (
            <>
              {ModalData?.is_leaf == false && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => Sheetviewhandler()}
                >
                  Sheet View
                </MenuItem>
              )}
            </>
          )}
          {userAction && userAction?.costing && (
            <>
              {ModalData?.cost_status >= 1 && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => costroute()}
                >
                  Go to Costing Create
                </MenuItem>
              )}
            </>
          )}
          {ModalData?.is_leaf == true &&
            (QRLoader ? (
              <MenuItem
                sx={{
                  color: "primary.main",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                }}
              >
                Print QR Code
                <p style={{ textAlign: "center" }}>
                  <CircularProgress size={16} />
                </p>
              </MenuItem>
            ) : (
              <MenuItem
                sx={{ color: "primary.main", fontSize: "1rem" }}
                onClick={() => PrintQRCode()}
              >
                Print QR Code
              </MenuItem>
            ))}

          {ModalData?.abbreviation !== "product" &&
            (routeLoader ? (
              <p style={{ textAlign: "center" }}>
                <CircularProgress size={16} />
              </p>
            ) : (
              <MenuItem
                sx={{ color: "primary.main", fontSize: "1rem" }}
                onClick={() => costcomapreroute()}
              >
                Go to Compare Page
              </MenuItem>
            ))}
        </Menu>
      }

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={
          <FolderIcon sx={{ color: HierarchyAllData?.color }} />
        }
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <FolderOpenIcon style={{ color: HierarchyAllData?.color }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && (
            <FolderIcon sx={{ color: HierarchyAllData?.color }} />
          )
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon sx={{ color: HierarchyAllData?.color }} />
          ) : (
            <FolderIcon sx={{ color: HierarchyAllData?.color }} />
          )
        }
        onNodeSelect={(e: any) =>
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation)
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == parseInt(vault)
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          id={HierarchyAllData?.id}
          // onMouseEnter={() =>
          //   hoverData(
          //     `${HierarchyAllData?.part_no}  ${HierarchyAllData?.name}`,
          //     HierarchyAllData.id
          //   )
          // }
          title={titleString && url.includes("costing") && titleString}
          label={
            <Typography className={styles.treeViewData}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: getColor(HierarchyAllData?.status),
                }}
                className={styles.treeViewText}
                onContextMenu={(event: any) =>
                  handleClick(
                    event,
                    HierarchyAllData.id,
                    HierarchyAllData.is_leaf,
                    HierarchyAllData
                  )
                }
              >
                {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                <br /> {HierarchyAllData?.name}
              </Typography>

              {newVault == undefined || newVault == null ? (
                ""
              ) : (
                <>
                  {newVault == HierarchyAllData?.id && (
                    <span className={styles.blink}>New</span>
                  )}
                </>
              )}
              <Typography className={styles.treeViewText}>
                {HierarchyAllData?.is_copy && (
                  <CopyrightIcon sx={{ color: "red" }} />
                )}

                {HierarchyAllData?.is_copy == false && (
                  <>
                    {HierarchyAllData?.is_leaf == false && (
                      <>
                        {HierarchyAllData?.status !== 3 &&
                          HierarchyAllData?.abbreviation != "product" && (
                            <img
                              title="Agent"
                              src={AgentIcon}
                              style={{ height: "1.5rem" }}
                              onClick={(e: any) =>
                                AgentPartModalHandler(
                                  e,
                                  HierarchyAllData,
                                  false
                                )
                              }
                            />
                          )}
                        {HierarchyAllData?.abbreviation == "product" && (
                          <img
                            title="Agent"
                            src={AgentIcon}
                            style={{ height: "1.5rem" }}
                            onClick={(e: any) =>
                              AgentPartModalHandler(e, HierarchyAllData, true)
                            }
                          />
                        )}
                      </>
                    )}

                    {HierarchyAllData?.weight_validation !== 0 && (
                      <WarningRoundedIcon
                        titleAccess={`The Difference is (${HierarchyAllData?.weight_validation})gm`}
                        color="error"
                        onClick={(e: any) =>
                          WeightAlertHandler(e, HierarchyAllData)
                        }
                      />
                    )}

                    {HierarchyAllData?.flag == true && (
                      <FlagIcon
                        color="error"
                        sx={{ cursor: "pointer" }}
                        onClick={(e: any) => FlagHandler(e, HierarchyAllData)}
                      />
                    )}
                  </>
                )}

                {ActionUser && ActionUser?.includes("C") && (
                  <>
                    {HierarchyAllData?.is_leaf == false && (
                      <>
                        {systemloader ? (
                          <p style={{ textAlign: "center" }}>
                            <CircularProgress size={20} />
                          </p>
                        ) : (
                          <AddCircleOutlineIcon
                            titleAccess={
                              HierarchyAllData?.abbreviation == "product"
                                ? "Add Systems"
                                : "Add Assembly/Part"
                            }
                            onClick={(e: any) =>
                              HierarchyAllData?.abbreviation == "product"
                                ? AddSystemHandler(e, HierarchyAllData)
                                : AddBOMPartHandlerSystem(e, HierarchyAllData)
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Typography>
            </Typography>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.subsystem?.map(
              (subsystemData: any, index: any) => {
                return (
                  <>
                    <BOMTreeViewCreate
                      HierarchyData={subsystemData}
                      key={subsystemData?.id}
                      onSelectCallback={onSelectCallback}
                      setParentsCounter={setParentsCounter}
                      setrecyclebinCounter={setrecyclebinCounter}
                    />
                  </>
                );
              }
            )}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
};

export default BOMTreeViewCreate;
