import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { Form, useFormik } from "formik";
import {
  Backdrop,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { API } from "../../../../api-services";
import { AxiosError, AxiosResponse } from "axios";
import uploadS3OperationFlag from "./uploadS3OperationFlag";
import { useBaseModal } from "../../../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFileCosting from "../../ErrorModalFileCosting";
import SuccessModalFileCosting from "../../SuccessModalFileCosting";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  rate: yup
    .number()
    .min(0, "Must be a positive integer")
    .required("Please enter Rate/Unit (Currency)"),
  // unit: yup.string().required("Please enter Unit"),
 
  // currency_id: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IEditOperationRevisionTableModel {
  isOpen: any;
  onCloseModal: any;
  id: any;
  flagEdit: any;
  unitGet: any;
  apiCallAccordianInsideData: any;
  setApiCallAccordianInsideData: any;
  valueEdit: any;
  operationEdit:any;
  // unit:any;
  // currency_code:any;
}

function EditOperationRevisionTableModel({ isOpen, onCloseModal, id, flagEdit, unitGet, apiCallAccordianInsideData, setApiCallAccordianInsideData, valueEdit , operationEdit, currency_code, unit}:any) {
  //const { isOpen, onCloseModal, id, flagEdit, unitGet, apiCallAccordianInsideData, setApiCallAccordianInsideData, valueEdit , operationEdit} = props;
  const { categoryName } = useRouteParams<any>();
        // ----------------------------------------------

        const [countryList, setCountryList] = React.useState<any[]>([]);
        const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
        const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
        const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
          React.useState<any>("");
        // ----------------------------------------------
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [flag, setFlag] = React.useState<any>(); const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [SuccessModalFileMsg, setSuccessModalFileMsg] = React.useState<any>();

  const ErrorModalFiles = useBaseModal();
  const SuccessModalFiles = useBaseModal();

  React.useEffect(() => {
    if (currency_code) {
      setSelectedCurrencyCodes([currency_code] || []);
    } else {
      setSelectedCurrencyCodes([]);
    }
    formik.setFieldValue("rate", valueEdit);
    formik.setFieldValue("unit", unitGet)
   ;
    formik.setFieldValue("currency_id", operationEdit?.currency_id);
    formik.setFieldValue("currency_code", currency_code);
  }, [isOpen]);

  const formik = useFormik({
    initialValues: {
      rate: 0,
      unit: '',
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
      if (flag) {
        await uploadS3OperationFlag(
          id,
          Number(values?.rate),
          values?.unit,
          flag,
          setIsLoadingSave,
          'operationRevision',
          setApiCallAccordianInsideData,
          apiCallAccordianInsideData,
          onCloseModal,
          setSuccessModalFileMsg,
          setErrorModalFileMsg,
          SuccessModalFiles,
          ErrorModalFiles
        )
      }
      else {
        API.put(`/api/db/stroke_rate_revision/${id}/`, { rate: Number(values?.rate), operation_unit: values?.unit }, 0).then((res: AxiosResponse) => {
          setIsLoadingSave(false);
          setSuccessModalFileMsg('Operation edited successfully!')
          SuccessModalFiles.open();
          setApiCallAccordianInsideData(!apiCallAccordianInsideData);
        }).catch((err) => {
          setIsLoadingSave(false);
          setErrorModalFileMsg(err.response.data);
          ErrorModalFiles.open();
        })
      }
    }
  });

  const viewFile = (file: string) => {
    window.open(file);
  };

  const handleChange = (e: any) => {
    setFlag(e.currentTarget.files);
  };

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
console.log(operationEdit);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});


  
  

  return (
    <div>
      {ErrorModalFiles.isOpen && 
          <ErrorModalFileCosting
           isOpen={ErrorModalFiles.isOpen}
           onCloseModal={ErrorModalFiles.close}
           ErrorMsg={ErrorModalFileMsg}
           onCloseParentModel={onCloseModal}
         />
         }
        {SuccessModalFiles.isOpen &&
        <SuccessModalFileCosting
           isOpen={SuccessModalFiles.isOpen}
           onCloseModal={SuccessModalFiles.close}
           successMsg={SuccessModalFileMsg}
           onCloseParentModel={onCloseModal}
         />
        }
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Operation 
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "2rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", alignItems: "100%", columnGap: "1rem" }}>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    type="number"
                    label="Rate/Unit (Currency)"
                    inputProps={{
                      step:'any',
                      style: { color: "#007fff" },
                      min: 0
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="rate"
                    name="rate"
                    onChange={formik.handleChange}
                    value={formik.values.rate}
                    error={formik.touched.rate && Boolean(formik.errors.rate)}
                    helperText={formik.touched.rate && formik.errors.rate}
                  />
                </FormControl>
                {/* <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Unit"
                    disabled={true}
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="unit"
                    name="unit"
                    onChange={formik.handleChange}
                    value={formik.values.unit}
                    multiline
                    error={
                      formik.touched.unit && Boolean(formik.errors.unit)
                    }
                    helperText={
                      formik.touched.unit && formik.errors.unit
                    }
                  />
                </FormControl> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "100%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      disabled
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      disabled
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box>
                {flagEdit && (
                  <FormControl fullWidth sx={{ marginTop: '0.5rem' }}>
                    <div style={{ marginBottom: "1rem", width: "100%", textAlign: 'left' }}>
                      <div
                        style={{
                          color: "#007fff",
                          fontSize: "0.8rem",
                          // paddingLeft: "0.5rem",
                          lineHeight: "1.4375em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Previous Uploaded File View
                      </div>
                      <div
                        onClick={() => viewFile(flagEdit)}
                        style={{
                          cursor: "pointer",
                          // paddingLeft: "0.5rem",
                          textDecoration: "underline",
                        }}
                      >
                        {flagEdit && flagEdit}
                      </div>
                    </div>
                  </FormControl>
                )}
                <FormControl fullWidth>
                <InputLabel shrink
                    id="demo-simple-select-label-Activity"
                    sx={{color: "primary.main",marginLeft:'-1rem',marginTop:'0.5rem' }}>
                    Flag
                  </InputLabel>
                  <Input type="file" style={{fontSize:'1rem'}} onChange={handleChange} />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
               
              </Box>
              <Box sx={{ width: '100%', textAlign: 'right' }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    }
                  }}
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default EditOperationRevisionTableModel;
