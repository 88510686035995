import {
  Box,
  Divider,
  Drawer,
  Slide,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./CostingCreateSystemLandingPage.module.scss";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CompressIcon from "@mui/icons-material/Compress";
import ExpandIcon from "@mui/icons-material/Expand";
import InsightsIcon from "@mui/icons-material/Insights";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { CostingCreateDrawer } from "./CostingCreateDrawer";
import CostingCreateSystemTable from "./CostingCreateSystemTable";
import CostingCreateSystemTableCompressed from "./CostingCreateSystemTableCompressed";
import { AxiosError, AxiosResponse } from "axios";
import { API } from "../../api-services";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import { TransitionProps } from "@mui/material/transitions";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AUTH } from "../../Redux/Services/auth.service";
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
}

interface ICostingCreateSystemLandingPageProps {}

const CostingCreateSystemLandingPage: React.FC<
  ICostingCreateSystemLandingPageProps
> = (props) => {
  const { projectId, projectName, topVaultIds, expandTable, initialTopVault } =
    useRouteParams<Params>();
  useDocumentTitle("Zero-based Costing");
  const [compactViewInfo, setCompactViewInfo] = React.useState<any>({});
  const [costStatusLoader, setCostStatusLoader] = React.useState<any>(true);
  const [openCostStatus, setOpenCostStatus] = React.useState(false);
  const [costStatusData, setCostStatusData] = React.useState<any>();
  const [syncLoading,setSyncLoading] = useState<any>(false);
  const [costSync, setCostSync] = useState<any>();
  const [productList, setProductList] = useState<any>([]);
  const [topVaultSelected, setTopVaultSelected] = useState<any>();
  
  const handleCostStatusClose = () => {
    setOpenCostStatus(false);
  };

  const handleCostStatusOpen = () => {
    setCostStatusLoader(true);
    setOpenCostStatus(true);
    API.get(
      `/xcpep/bom_project_status/`,
      { project: projectId, cost_status: true },
      0
    )
      .then((res: AxiosResponse) => {
        setCostStatusData(res.data);
        setCostStatusLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  useEffect(() => {
    setCostStatusLoader(true);
    API.get(`/cost/costing_create_ui/`, { get_collapse_project: projectId }, 0)
      .then((res: AxiosResponse) => {
        setCompactViewInfo(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [projectId, topVaultIds]);

  const redirectToBOMInnerPage = (
    internalData: any,
    dataInternalFilter: any
  ) => {
    console.log(internalData);
    console.log(dataInternalFilter);
    let topVaultList = dataInternalFilter?.list_top_vault;
    topVaultList = topVaultList.join(",");
    console.log(topVaultList);

    sessionStorage.setItem(
      "CostCreateNode",
      JSON.stringify([dataInternalFilter?.top_vault_id, dataInternalFilter?.id])
    );
    window.open(
      `/#/costingabbrlanding/${projectId}/${projectName}/${dataInternalFilter?.top_vault_id}/${topVaultList}/true/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData.abbreviation}/${dataInternalFilter?.id}`
    );
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <CostingCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );

  const handleClickBack = () => {
    history.push(`/internallanding`);
  };

  const handleClickExpand = () => {
    expandTable == "true"
      ? history.push(
          `/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list?.toString()}/${
            expandTable == "true" ? "false" : "true"
          }`
        )
      : history.push(
          `/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list
            ?.slice(0, 4)
            ?.toString()}/${expandTable == "true" ? "false" : "true"}`
        );
  };

  useEffect(() => {
    setCostStatusLoader(true);
    API.get(`/cost/cost_info_total_count/`, { project: projectId,scenario: 0,top_vault:topVaultIds
    }, 0)
      .then((res: AxiosResponse) => {
        setProductList(res.data);
        setCostStatusLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [projectId, topVaultIds]);


  // const CostSyncHandler = (event: SelectChangeEvent) => {
  //   setCostSync(event.target.value);
  //   // console.log(costSync, "costSync");
  //   if(costSync !== '' && event.target.value !== undefined){
  //     setSyncLoading(true);
  //     API.get(`/customer/update_costing/`, { top_vault: +event.target.value },0)
  //     .then((res: AxiosResponse) => {
  //       // setCompactViewInfo(res.data);
  //       setSyncLoading(false);
  //     })
  //     .catch((err: AxiosError) => {
  //       console.log(err, "error");
  //     });
  //   }    
  // };

  const CostSyncHandler = (selectItem: any) => {
    setCostSync(selectItem);
    // console.log(costSync, "costSync");
    if(costSync !== '' && selectItem !== undefined){
      setSyncLoading(true);
      API.get(`/customer/update_costing/`, { top_vault: +selectItem },0)
      .then((res: AxiosResponse) => {
        // setCompactViewInfo(res.data);
        setSyncLoading(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
    }    
  };

  

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElHierarchy, setAnchorElHierarchy] = React.useState<null | HTMLElement>(null);
  const openHierarchy = Boolean(anchorElHierarchy);
	const open = Boolean(anchorEl);
  const handleClickHierarchy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElHierarchy(event.currentTarget);
  }
  const handleCloseHierarchy = () => {
    setAnchorElHierarchy(null);
  }
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
  const handleClose = () => {
		setAnchorEl(null);
	};


  return (
    <>
    
      <Dialog
        open={openCostStatus}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            maxWidth: "93vw",
            borderRadius: "10px",
            maxHeight:'94vh',
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "93vw" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Validation Status
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCostStatusClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
           {costStatusLoader ? (
                <Box sx={{padding:'0 2rem'}}>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </Box>
              ): <>
          <Box sx={{ Width: "100%" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                  costStatusData && costStatusData[0]?.data_by_vehicle?.length
                }, 1fr)`,
                width: "100%",
                maxHeight: { lg: "85vh", xl: "90vh" },
                padding: "0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!costStatusLoader &&
                costStatusData &&
                costStatusData[0]?.data_by_vehicle?.map(
                  (item: any, index: any) => {
                    return (
                      <Table className={styles.CoststatusTable}
                        sx={{
                          borderRight: "1px solid",
                          borderColor: "primary.light",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            {index == 0 && (
                              <TableCell
                                rowSpan={2}
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  backgroundColor: "primary.light",
                                }}
                              >
                                Subsystem
                              </TableCell>
                            )}
                            <TableCell
                              colSpan={2}
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              {item?.top_vault_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              Total Parts
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              Validated Parts
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {costStatusData &&
                            costStatusData?.map((itemInside: any) => {
                              const data = itemInside?.data_by_vehicle?.filter(
                                (data: any) =>
                                  data?.top_vault_name == item?.top_vault_name
                              );
                              return (
                                <TableRow>
                                  {index == 0 && (
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        textAlign: "left",
                                        fontWeight: "500",
                                        whiteSpace: "nowrap",
                                      }}
                                      title={data?.name}
                                    >
                                      {itemInside?.name}
                                    </TableCell>
                                  )}
                                  {data[0]?.id != undefined && (
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data[0]?.total_parts}
                                    </TableCell>
                                  )}
                                  {
                                    <TableCell
                                      colSpan={data[0]?.id == undefined ? 2 : 1}
                                      onClick={() =>
                                        data[0]?.id != undefined &&
                                        data[0]?.total_parts !=
                                          data[0]?.validated &&
                                        redirectToBOMInnerPage(
                                          itemInside,
                                          data[0]
                                        )
                                      }
                                      sx={
                                        data[0]?.id != undefined
                                          ? data[0]?.total_parts ==
                                            data[0]?.validated
                                            ? {
                                                padding: "0.2rem 0.5rem",
                                                borderBottomColor:
                                                  "primary.light",
                                                textAlign: "center",
                                                color: "green",
                                              }
                                            : {
                                                padding: "0.2rem 0.5rem",
                                                borderBottomColor:
                                                  "primary.light",
                                                cursor: "pointer",
                                                textAlign: "center",
                                                color: "red",
                                                textDecoration: "underline",
                                              }
                                          : {
                                              padding: "0.2rem 0.5rem",
                                              borderBottomColor:
                                                "primary.light",
                                              textAlign: "center",
                                            }
                                      }
                                    >
                                      {data[0]?.id == undefined
                                        ? "Not Available"
                                        : data[0]?.validated}
                                    </TableCell>
                                  }
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    );
                  }
                )}             
            </Box>
          </Box></>}
        </DialogContent>
      </Dialog>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClickBack}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Estimate Cost ( {projectName} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
          {syncLoading && <CircularProgress size={20} sx={{color:'primary.main'}} />}
          {/* {AUTH.isSuperUser && <FormControl>
          {costSync == '' || costSync == null && <Typography sx={{    marginBottom: '-2rem',
              marginLeft: '0.5rem',
              zIndex: 1,
              width:'fit-content',
              color: 'white'}}>Productwise Sync</Typography>}
          <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={costSync  || ''} 
          // label="Cost Sync"
          placeholder="Productwise Sync"
          onChange={CostSyncHandler}
          sx={{
            width:'12rem',
            "&:before": {
              borderBottomColor: "transparent !important",
              borderRadius: "5px",
            },
            "&:after": {
              borderBottomColor: "transparent !important",
              borderRadius: "5px",
            },
            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
              {
                fontSize: "1rem",
                // border: "2px solid",
                borderRadius: "5px",
                height: "1rem",
                padding: "0.4rem 0.5rem",
                backgroundColor:'primary.main',
                color:"white",
              },            
            ".MuiSvgIcon-root.MuiSelect-icon": {
              color: "white",
            },
        }}
        >                 
          {productList && productList?.map((item: any) => {
            return (
              <MenuItem sx={{fontSize:'1rem'}} value={item.top_vault_id}>{item.name}</MenuItem>
            )
          })}
          <MenuItem value="" style={{ display: "none" }}>Select Product</MenuItem> 
          
        </Select>  
        </FormControl>} */}

                

        {AUTH.isSuperUser && <>
        <Button
        size='small'
        variant='contained'
        endIcon={<ArrowDropDownIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        title="Cost Prodcutwise Sync"
        sx={{borderRadius: "1.5rem", }}
        onClick={handleClick}>Productwise Sync</Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper':{
              width:{lg:'13rem',xl:'11rem'},             
            },
            '.MuiButtonBase-root.MuiMenuItem-root':{
              whiteSpace:'normal',
            }
          }}
          >
         {!costStatusLoader ? <> {productList && productList?.map((item: any) => {
            return (
              <MenuItem sx={{fontSize:'1rem',textOverflow:'wrap'}} value={item.top_vault_id} onClick={()=>CostSyncHandler(item.top_vault_id)}>{item.name}</MenuItem>
            )
          })}</> : <Box sx={{padding:'0 1rem'}}><Skeleton></Skeleton>
          <Skeleton></Skeleton>
          </Box>}
          
          </Menu></>}

       

            <Button
              className={styles.bomStatus}
              size="small"
              variant={"contained"}
              sx={{
                borderRadius: "1.5rem",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
              }}
              onClick={handleCostStatusOpen}
            >
              Cost Status
            </Button>
            {compactViewInfo?.visibility && (
              <IconButton onClick={handleClickExpand}>
                {" "}
                {expandTable == "true" ? (
                  <CompressIcon
                    sx={{ color: "#007fff", transform: "rotate(90deg)" }}
                    titleAccess="Compact View"
                  />
                ) : (
                  <ExpandIcon
                    sx={{ color: "#007fff", transform: "rotate(90deg)" }}
                    titleAccess="Normal View"
                  />
                )}{" "}
              </IconButton>
            )}
          
            <IconButton title="Scan QR">
              <QrCodeScannerIcon
                onClick={() =>
                  history.push(`/costingscanner/${projectId}/${projectName}`)
                }
                sx={{ color: "#007fff" }}
              />
            </IconButton>
            <IconButton
              title="Analyse"
              onClick={() =>
                window.open(`/#/analysis_costing/${projectId}/${topVaultIds}`)
              }
            >
              <InsightsIcon sx={{ color: "#007fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={
            String(topVaultIds)?.includes(",")
              ? topVaultIds?.split(",")?.length == 2
                ? {
                    display: "grid",
                    gridTemplateColumns: `1.2fr 1fr`,
                    width: "98%",
                    maxHeight: { lg: "86vh", xl: "90vh" },
                  }
                : {
                    display: "grid",
                    gridTemplateColumns: `1.2fr repeat(${
                      topVaultIds?.split(",")?.length - 1
                    }, 1fr)`,
                    width: "98%",
                    maxHeight: { lg: "86vh", xl: "90vh" },
                  }
              : {
                  display: "grid",
                  gridTemplateColumns: `1fr`,
                  width: "98%",
                  maxHeight: { lg: "86vh", xl: "90vh" },
                }
          }
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {expandTable == "true" ? (
            <CostingCreateSystemTable />
          ) : (
            <CostingCreateSystemTableCompressed />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CostingCreateSystemLandingPage;
