import { Skeleton, Tab, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import styles from "./IdeaViewpage.module.scss";
import { IdeaViewCostModalComponents } from "./IdeaViewCostModalComponents";
import { API } from "../../../api-services";
import { useHistory, useParams } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import IdeaWorkflow from "./IdeaWorkflow";
import Ideadetails from "./IdeaDetails";
import IdeagenImagesandVideosCombine from "./IdeaGenerationImageandVideo/IdeagenImagesandVideosCombine";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ViewTabData from "./IdeaTabData/ViewTabData";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import IdeaGenartionInfoModal from "./IdeaGenartionInfoModal";
import IdeaFilterOptions from "./IdeaFilterOptions";
// import IdeaDetailsSmallScreen from './IdeaDetailsSmallScreen';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import MergeIcon from "@mui/icons-material/Merge";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import MergeIdeaModal from "./MergeIdeaModal";
import { get } from "lodash";
import { UserAction } from "./IdeaGenerationViewLanding";

export interface IIdeaViewpageProps {
  workflow: any;
  ideaDetails: any;
  StatusCounter: any;
  setStatusCounter: any;
  ideaAllDetails: any;
  setWorkflowState: any;
  setIdeaDetails: any;
  updateIdeaList: any;
  counter: any;
}
export const rejectedStatus = React.createContext<any>(null);

export default function IdeaViewpage(props: IIdeaViewpageProps) {
  const {
    workflow,
    ideaDetails,
    ideaAllDetails,
    setStatusCounter,
    StatusCounter,
    setWorkflowState,
    setIdeaDetails,
    updateIdeaList,
    counter,
  } = props;
  const IdeaFilterModal = useBaseModal();
  const [IdeaList, setIdeaList] = React.useState<any>([]);
  const [isShowCancelFilter, setIsShowCancelFilter] =
    React.useState<any>(false);
  const history = useHistory();

  const {
    projectId,
    projectName,
    topVault,
    subSystem,
    subSystemId,
    expandTable,
    abbr,
    partId,
    depth,
    vault_id,
  } = useParams<any>();
  const mergeIdeaModal = useBaseParamsModal();
  const [tableDataGetCount, setTableDataGetCount] = React.useState<any>(1);
  const [Loader, setLoader] = React.useState<any>(false);
  const IdeaGenartionModal = useBaseModal();
  const [InfoIds, setInfoIds] = React.useState<any>();
  const [activeTab, setActiveTab] = React.useState<any>(vault_id);
  const [savingData, setsavingData] = React.useState<any>("");
  const useraction = React.useContext<any>(UserAction);
  const IdearouteHandler = (e: any, item: any) => {
    if (!item?.rejected && item?.merge) {
    } else {
      // @ts-ignore
      sessionStorage.setItem("tabParameter", 1);
      setActiveTab(item?.id);
      history.push(
        `/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/${expandTable}/${abbr}/${partId}/${0}/${
          item?.id
        }/0/0`
      );
    }
  };
  const toggleQuery = () => {
    if (subSystem == "PRODUCT") {
      return {
        top_vault: topVault,
        module: 15,
        page: 1,
        hierarchy: true,
        search_id: "",
      };
    } else {
      return {
        vault: partId,
        top_vault: topVault,
        module: 15,
        page: 1,
        hierarchy: true,
        search_id: "",
      };
    }
  };
  const getIdeaList = () => {
    setLoader(true);
    API.get(
      `/idea/pool/`,
      {
        ...toggleQuery(),
      },
      0
    )
      .then((res: any) => {
        setIdeaList(res.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    if (partId != 0) {
      getIdeaList();
    }
  }, [partId, StatusCounter, updateIdeaList, counter]);

  const { height, width } = useWindowDimensions();
  const [IdeaCloseState, setIdeaCloseState] = React.useState<any>();
  let tabType = sessionStorage.getItem("tabParameter");
  const [tabParameter, setTabParameter] = React.useState<any>(tabType);
  const [value, setValue] = React.useState(tabType !== null ? tabType : "1");

  const [tabValue, setTabValue] = React.useState<any>(
    tabType !== null ? tabType : "1"
  );
  const handleChange = (newValue: any) => {
    // setValue(newValue);
    sessionStorage.setItem("tabParameter", newValue);
    // setTabParameter("booleanpara");
    setTabValue(newValue);
  };
  const handleTabClicked = (value: any) => {
    // setImageParameterSelect([]);
    setTabValue(value);
  };
  const InfoHandler = (e: any, Id: any) => {
    e?.stopPropagation();
    setInfoIds(Id);
    IdeaGenartionModal.open();
  };

  const mergeIdeaClick = (e: any, item: any) => {
    e?.stopPropagation();
    mergeIdeaModal.open({ stage: item?.stage, id: item?.id });
  };

  const getBackgroundColor = (item: any) => {
    if (item?.stage == 0 && item?.rejected == false) {
      return "#ffffff";
    } else if (item?.stage == 0 && item?.rejected == true) {
      return "#fbe5d6";
    } else if (item?.stage == 1 && item?.rejected == false) {
      return "#ffe699";
    } else if (item?.stage == 1 && item?.rejected == true) {
      return "#fbe5d6";
    } else if (item?.stage == 2 && item?.rejected == false) {
      return "#bbdbf7";
    } else if (item?.stage == 2 && item?.rejected == true) {
      return "#eb9ba0";
    } else if (item?.stage == 3 && item?.rejected == false) {
      return "#7afba9";
    } else if (item?.stage == 3 && item?.rejected == true) {
      return "#eb9ba0";
    }
  };



  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {IdeaGenartionModal.isOpen && (
        <IdeaGenartionInfoModal
          onCloseModal={IdeaGenartionModal.close}
          isOpen={IdeaGenartionModal.isOpen}
          Item={InfoIds}
        />
      )}

      {IdeaFilterModal?.isOpen && (
        <IdeaFilterOptions
          isOpen={IdeaFilterModal.isOpen}
          onCloseModal={IdeaFilterModal.close}
          setIdeaList={setIdeaList}
          setLoader={setLoader}
          IdeaList={IdeaList && IdeaList?.results}
          setIsShowCancelFilter={setIsShowCancelFilter}
        />
      )}
      {mergeIdeaModal.isOpen && (
        <MergeIdeaModal
          isOpen={mergeIdeaModal.isOpen}
          onCloseModal={mergeIdeaModal.close}
          item={mergeIdeaModal.propsId}
          getIdeaList={getIdeaList}
        />
      )}
      <Box
        sx={{
          minWidth: "15vw",
          height: { xs: "87vh", lg: "86vh", xl: "90vh" },
          borderRight: "1px solid #ddefff",
        }}
      >
        <Box
          sx={{
            // backgroundColor: "primary.main",
            // color: "white",
            borderBottom: "2px solid #007fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 0.4rem",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "500",
            }}
           
          >
            Idea ID
          </Typography>
          <FilterAltIcon
            sx={{ cursor: "pointer" }}
            titleAccess="filter"
            onClick={() => IdeaFilterModal.open()}
          />
          {isShowCancelFilter && (
            <FilterAltOffIcon
              titleAccess="Clear Filter"
              sx={{ cursor: "pointer", marginLeft: "0.5rem" }}
              onClick={() => {
                setLoader(true);
                API.get(`/idea/pool/`, {
                  vault: subSystem != "PRODUCT" ? partId : undefined,
                  top_vault: topVault,
                  module: 15,
                  page: 1,
                  search_id: "",
                  hierarchy: true,
                })
                  .then((res: any) => {
                    setIdeaList(res.data);
                    setLoader(false);
                    setIsShowCancelFilter(false);
                  })
                  .catch((err: any) => {
                    setLoader(false);
                  });
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            
               height: { lg: "83vh", xl: "87vh" }
             }
            }
        
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {Loader ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {IdeaList &&
                IdeaList?.results?.map((item: any, index: any) => {
                  const titleValue = item
                    ? `${item.idea_id}(${item.cost} INR)`
                    : "";
                  return (
                    <>
                      <Box
                        onClick={(e: any) => IdearouteHandler(e, item)}
                        sx={{
                          backgroundColor: getBackgroundColor(item),
                          color: "primary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.1rem 0.5rem 0.1rem 0.2rem",
                          cursor:   !item?.rejected && item?.merge?"default": "pointer",
                          borderBottom: "1px solid #ddefff",
                          border:
                            item?.id == vault_id || item?.id == activeTab
                              ? "1px solid #007fff"
                              : `1px solid ${getBackgroundColor(item)}`,
                        }}
                        
                        id={item?.id}
                      >
                        <Box
                          sx={{
                            marginRight: "0.5rem",
                            width: "0",
                            height: "0",
                            borderTop: "8px solid transparent",
                            borderBottom: "8px solid transparent",
                            borderLeft:
                              item?.id == vault_id || item?.id == activeTab
                                ? "7px solid #007fff"
                                : `7px solid ${getBackgroundColor(item)}`,
                          }}
                        ></Box>
                        <Typography
                          title={titleValue}
                          sx={{
                            width: "100%",
                            fontSize: "1rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textDecoration:
                              !item?.rejected && item?.merge && "line-through",
                          }}
                        >
                          {item?.idea_id}({item?.cost_verified?item?.cost:0} INR)
                        </Typography>
                        {useraction && useraction?.action?.includes("U") && (
                          <>
                            {!item?.rejected && !item?.merge && (
                              <MergeIcon
                                titleAccess="Merge Idea"
                                sx={{ cursor: "pointer" }}
                                onClick={(e: any) => mergeIdeaClick(e, item)}
                              />
                            )}
                          </>
                        )}
                        <InfoIcon
                          titleAccess="Info"
                          sx={{ cursor: "pointer" }}
                          onClick={(e: any) => InfoHandler(e, item)}
                        />
                      </Box>
                    </>
                  );
                })}
            </>
          )}
        </Box>
      </Box>
      {vault_id == 0 ? (
        <Box
          sx={{
            width: { lg: "81vw", xl: "85vw" },
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Select Idea Id</Typography>
        </Box>
      ) : (
        <>
        <Box
            sx={{
              width: { lg: "81vw", xl: "85vw" },
              height: { xs: "87vh", lg: "86vh", xl: "90vh" },
              padding: "0 0.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                marginBottom: "1rem",
              }}
            >
              {workflow == true ? (
                <IdeaWorkflow
                  ideaAllDetails={ideaAllDetails && ideaAllDetails}
                  setWorkflowState={setWorkflowState}
                  StatusCounter={StatusCounter} />
              ) : (
                ""
              )}
              {ideaDetails == true ? (
                <rejectedStatus.Provider value={ideaAllDetails && ideaAllDetails[0]?.rejected}>
                  <Ideadetails
                    ideaDetails={ideaDetails}
                    width={width}
                    ideaAllDetails={ideaAllDetails && ideaAllDetails}
                    savingData={savingData}
                    setIdeaDetails={setIdeaDetails}
                    setStatusCounter={setStatusCounter} />
                </rejectedStatus.Provider>
              ) : (
                ""
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "0.5rem",
                width: "100%",
                height: workflow == true || ideaDetails == true
                  ? { lg: "55vh", xl: "66vh" }
                  : { lg: "84vh", xl: "88vh" },
              }}
            >
              <Box
                sx={{
                  width: "75%",
                  height: "100%",
                  border: "1px solid",
                  borderColor: "primary.light",
                  borderRadius: "1rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <rejectedStatus.Provider value={ideaAllDetails && ideaAllDetails[0]?.rejected}>
                  <ViewTabData
                    // tabParameter={tabValue}
                    status={ideaAllDetails?.[0]?.stage}
                    vault_id={vault_id} />
                </rejectedStatus.Provider>
              </Box>
              <Box
                sx={{
                  width: "25%",
                  height: "100%",
                  border: "1px solid",
                  borderColor: "primary.light",
                  borderRadius: "1rem",
                }}
              >
                <rejectedStatus.Provider value={ideaAllDetails && ideaAllDetails[0]?.rejected}>
                  <IdeagenImagesandVideosCombine status={ideaAllDetails?.[0]?.stage} />
                </rejectedStatus.Provider>
              </Box>
            </Box>
            {/* </Box> */}
          </Box></>
      )}
    </Box>
  );
}
