import * as React from "react";
import { IconButton, TableCell, Typography } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import WallpaperIcon from "@mui/icons-material/Wallpaper";

interface ISupplierpageProps {
  item: any;
  index: any;
  getPlantIds: any;
  useraction:any;
  getContactDetail: any;
  editSupplierDatabase: any;
  handleDeleteSupplier: any;
  viewFile: any;
  singleSupplierShow: any;
  handleListSupplierUse: any;
}

function Supplierpage(props: ISupplierpageProps) {
  const { item, index, getPlantIds, getContactDetail, editSupplierDatabase, handleDeleteSupplier, viewFile, singleSupplierShow, handleListSupplierUse ,useraction} = props;

  return (
    <>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{index}</TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          singleSupplierShow(
            item?.id,
            item?.name,
            item?.commodity,
            item?.vehicle_type,
            item?.subsystem,
            item?.supplier_details,
            item?.contact_info,
            item?.contact,
            item?.dialCode,
            item?.spoc_name,
            item?.spoc_email,
            item?.logo,
            item?.supplier_type,
            item?.certification,
            item?.plants,
            item?.created_user_name,
            moment(item?.last_updated).format("DD-MM-YYYY, h:mm a")
          );
        }}>
        <Typography sx={{ fontSize: "1rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {item?.name}{" "}
          {item?.logo && (
            <WallpaperIcon
              titleAccess="Logo"
              sx={{ cursor: "pointer" }}
              onClick={() => viewFile(item?.logo)}
            />
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.commodity?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
        <IconButton
          style={{ color: "#007fff", padding: "3px" }}
          title="Details"
          onClick={() => {
            getContactDetail(item?.supplier_details, item?.contact_info, item?.contact, item?.spoc_name, item?.spoc_email, item?.dialCode);
          }}>
          <InfoIcon />
        </IconButton>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.vehicle_type?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.subsystem?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
        <span
          onClick={() => getPlantIds(item?.id)}
          style={{ textDecoration: "underline", cursor: "pointer" }}>
          {item?.plants}
        </span>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.supplier_type}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.certification}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.created_user_name}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{moment(item?.last_updated).format("DD-MM-YYYY, h:mm a")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
       { useraction && useraction?.includes("U") &&<IconButton
          style={{ color: "#007fff", padding: "3px" }}
          title="Edit BOM Category">
          <EditIcon
            onClick={() =>
              editSupplierDatabase(
                item?.id,
                item?.name,
                item?.commodity,
                item?.vehicle_type,
                item?.subsystem,
                item?.supplier_details,
                item?.contact_info,
                item?.contact,
                item?.dialCode,
                item?.spoc_name,
                item?.spoc_email,
                item?.logo,
                item?.supplier_type,
                item?.certification
              )
            }
          />
        </IconButton>}
        {item?.mapped_status ? (
          <IconButton
            style={{ color: "#007fff", padding: "3px" }}
            onClick={() => handleListSupplierUse(item?.id)}
            title="Place Used (BOM Category)">
            <InfoIcon />
          </IconButton>
        ) : (
          useraction && useraction?.includes("D") && (
            <IconButton
              style={{ color: "#007fff", padding: "3px" }}
              onClick={(e: any) => handleDeleteSupplier(e, item?.id)}
              title="Delete BOM Category">
              <DeleteIcon />
            </IconButton>
          )
        )}
      </TableCell>
    </>
  );
}
export default Supplierpage;
