import { Select, TableCell, TableRow, TextField, debounce, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { API } from '../../api-services';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
interface tableRowRanderProps {

  rowItem: any;
  keys: any
  handleChangeCheckBox: any;
  setUpdatedCounter: any;
  setCounter: any;
}

const TablerowRander = (props: tableRowRanderProps) => {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const { rowItem, keys, handleChangeCheckBox, setUpdatedCounter, setCounter } = props
  const [tagData, settagData] = useState<any>(rowItem?.tag)

  const [sequence, setSequence] = useState<any>(rowItem?.sequence)
  const [requiredValue, setrequiredValue] = useState<any>(rowItem?.required)


  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, Id: any, type: any, topVault: any, validationsIds: any) => {
      if (type == "Tag") {
        await API.put(`/config/parameter_mapping/${Id}/`, { tag: criteria, top_vault: validationsIds == 0 ? topVault.toString() : validationsIds?.toString() }).then(
          (res: any) => {
            settagData(res.data?.tag);
            handleChangeCheckBox()
            // setCounter((prev:any)=>prev+1)
          }
        );
      }
      else if (type == "sequence") {
        if (criteria !== null) {
          await API.put(`/config/parameter_mapping/${Id}/`, { sequence: parseInt(criteria), top_vault: validationsIds == 0 ? topVault?.toString() : validationsIds?.toString() }).then(
            (res: any) => {
              //  setSequence(res.data?.sequence );
              setUpdatedCounter((prev: any) => prev + 1)
              setCounter((prev: any) => prev + 1)
              handleChangeCheckBox()
            }
          );
        }

      }
      else if (type == "required") {
        await API.put(`/config/parameter_mapping/${Id}/`, { required: criteria, top_vault: validationsIds == 0 ? topVault?.toString() : validationsIds?.toString() }).then(
          (res: any) => {
            setrequiredValue(res.data?.required);
            handleChangeCheckBox()
            // setCounter((prev:any)=>prev+1)
          }
        );
      }

      else if (type == "workstations") {
        await API.put(`/config/parameter_mapping/${Id}/`, { workstation: criteria, top_vault: validationsIds == 0 ? topVault?.toString() : validationsIds?.toString() }).then(
          (res: any) => {
            setrequiredValue(res.data?.required);
            // setCounter((prev:any)=>prev+1)
          }
        );
      }

    }, 700)
  ).current;
  const handleChange = (e: any, type: any) => {

    if (type == "Tag") {
      settagData(e.target.value);
      ExactDebounce(e.target.value, rowItem?.id, "Tag", productId, validationId)
      // setCounter((prev:any)=>prev+1)

    } else if (type == "sequence") {
      setSequence(e.target.value)
      if (e.target.value !== null || e?.target?.value?.length !== 0) {
        ExactDebounce(e.target.value, rowItem?.id, "sequence", productId, validationId)
      }



    }

    if (type == "required") {
      setrequiredValue(e.target.value)
      ExactDebounce(e.target.value, rowItem?.id, "required", productId, validationId)
      // setCounter((prev:any)=>prev+1)
    }

  };


  // console.log(rowItem, "rowItemrowItem")
  return (
    <TableRow>
      {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
        <TextField
          variant="standard"
          value={sequence}
          type="number"
          onChange={(e: any) => handleChange(e, "sequence")}
          placeholder='sequence'
          sx={{
            width: '5rem',
            ".MuiInputBase-root.MuiInput-root": {
              fontSize: '1rem !important'
            },
            '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
          }}
        /></TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{rowItem?.parameter?.name}</TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{rowItem?.parameter?.description}</TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', cursor: "pointer" }} onClick={() => rowItem?.info_doc != null && window.open(rowItem?.info_doc, "_blank")}>
        {rowItem?.info_doc == null ? "-" :
          < VisibilityOutlinedIcon />
        }
      </TableCell>
      {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={tagData}
          variant="standard"
          onChange={(e: any) => handleChange(e, "Tag")}
          label="Tag"
          sx={{
            "&:before": {
              borderBottomColor: "primary.light",
            },
            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
              fontSize: '1rem'
            },
            '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
          }}
        >
        
          < MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
       
          <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>

        </Select>


      </TableCell> */}
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={requiredValue}
          variant="standard"
          onChange={(e: any) => handleChange(e, "required")}
          label="required"
          sx={{
            "&:before": {
              borderBottomColor: "primary.light",
            },
            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
              fontSize: '1rem'
            },
            '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
          }}
        >

          {/* @ts-ignore */}
          < MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
          {/* @ts-ignore */}
          <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>

        </Select>


      </TableCell>







    </TableRow>
  )
}
export default TablerowRander;


