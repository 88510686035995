import * as React from 'react';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Skeleton} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';


type Anchor = "top" | "left" | "bottom" | "right";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Currency Database In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export interface ICurrencyDatabaseProps {
}


export function CurrencyDatabase(props: ICurrencyDatabaseProps) {
  const { revisionId, typeId, databaseName, categoryName, status, search } = useRouteParams<any>();
  const [countryLoading,setCountryLoading] = useState<boolean>(true);
  const [countryList,setCountryList] = useState<any>([]);
//   const [count,setCount] = useState<any>();

  const CurrencyAdd = useBaseModal();
  const ErrorModalFiles = useBaseModal();
  const { width } = useWindowDimensions();
  const SuccessModalFiles = useBaseModal();

  useEffect(()=>{
    if(search==0){
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true }, 0)
      .then((res: any) => {
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        setCountryLoading(false);
      });
    }
  },[search]);

  useEffect(()=>{
    if(search){
    API.get("customer/currency_search/", { search_key: search }, 0)
    .then((res: any) => {
      setCountryList(res.data);
      setCountryLoading(false);
    })
    .catch(() => {
      setCountryLoading(false);
    });
  }
  },[search])

// columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    {
        field: "flag",
        headerName: "Flag",
        headerAlign: "left",
        //   align: 'right',
        minWidth: width > 1370 ? 170 : 150,
        flex: 1,
        disableExport: true,
        renderCell: (cellValues: any) => {
          return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
              <img style={{height:'1.7rem',width:'3rem'}} src={`data:image/png;base64, ${cellValues?.row?.flag}`}/>
             </Box>)
        }
    },
    { field: "currencyName", headerName: "Currency Name", width: width > 1370 ? 540 : 420, },
    {
      field: "currencyCode",
      headerName: "Currency Symbol",
      width: width > 1370 ? 270 : 250,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            <span>{cellValues?.row?.currencyCode} </span>{`${cellValues?.row?.currencySymbol!=null && cellValues?.row?.currencySymbol!="_" ? `( ${cellValues?.row?.currencySymbol} )` : ""}`}
           </Box>)
      }
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = countryList && countryList?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.currency_id,
      sno: index + 1,
      flag: ideaItem?.flag,
      currencyName: ideaItem?.currency_name,
      currencyCode: ideaItem?.currency_code,
      currencySymbol: ideaItem?.symbol,
    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };


  return (
    <Box sx={{ width: "100%", height: '90vh' }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.4">

      {/* {SuccessModalFiles.isOpen && (
        <SuccessModalFilesMail
          isOpen={SuccessModalFiles.isOpen}
          onCloseModal={SuccessModalFiles.close}
          successMsg={SuccessModalFileMsg}
        />
      )}

      {ErrorModalFiles.isOpen && (
        <ErrorModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      )}
      {CurrencyAdd.isOpen && (
        <CurrencyAddModel
          isOpen={CurrencyAdd.isOpen}
          onCloseModal={CurrencyAdd.close}
          setCount={setCount}
        />
      )} */}
            <Box sx={{ height: {lg:'88vh',xl:"90vh"}, width: "100%" }}>
              {!countryLoading ? <DataGrid
                headerHeight={48}
                rowHeight={48}
                {...dataStructureDataGrid}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: 'none',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                  },
                }}
                density={"compact"}
                pageSize={100}
                rowsPerPageOptions={[100]}
              /> :
                <Box sx={{ padding: "0 1rem", width: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>}
            </Box>
    </Box>
  );
}