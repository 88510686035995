import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ShareIcon from "@mui/icons-material/Share";
import styles from "./BomConfigurationLanding.module.scss";

import Typography from "@mui/material/Typography";
import { API } from "../../api-services";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";
import BOMCustomValidation from "./BOMCustomValidation";
import { useShareCustomValidationModal } from "./useShareCustomValidationModal";
import BOMCustomValidationShareModal from "./BOMCustomValidationShareModal";
import validated from '../../Assets/images/Validated.svg';
import notvalidated from '../../Assets/images/NotValidated.svg';
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";


type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  topVaultId: any;
  categoryId: any;
  parameterId: any;
  projectname: any;
}

export interface IBOMCustomValidationLandingPageProps { }

export function BOMCustomValidationLandingPage() {
  const { projectId, projectname, topVaultId, categoryId, parameterId } = useRouteParams<Params>();
  useDocumentTitle( "Custom Validation Config" );
  const [ProductList, setProductList] = useState<any>([]);
  const [ProductLoader, setProductLoader] = useState<any>();
  const [CotegoryList, setCotegoryList] = useState<any>([]);
  const [ParentCount, setParentCount] = useState<any>(0)
  const history = useHistory();

  const shareCustomValidation: any = useShareCustomValidationModal();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {

    API.get("/xcpep/product_list/", {
      bom_custom_config: true,
      project: projectId,
    })
      .then((res: any) => {
        setProductList(res?.data);
      })
      .catch((err: any) => {

        console.log("serverError");
      });
  }, [projectId, ParentCount]);


  useEffect(() => {
    if (topVaultId != '0') {
      setProductLoader(true);
      API.get(`/config/custom_validation_config/`, {
        product: topVaultId,
        mui_custom_validation: true
      })
        .then((res: any) => {
          setCotegoryList(res?.data);
          setProductLoader(false);
        })
        .catch((err: any) => {
          setProductLoader(false);
          console.log("Server Icon");
        });
    }
  }, [topVaultId, ParentCount]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <CostingConfigurationDrawer />
    </Box>
  );

  const ProductHandler = (id: any) => {
    history.push(
      `/customValidation/${projectId}/${projectname}/${id}/0/0`
    );
  };

  const CategoryHandler = (item: any) => {
    history.push(
      `/customValidation/${projectId}/${projectname}/${topVaultId}/${item?.category}/0`
    );
  };

  const goBackHandler = () => {
    history.push(`/configurationmain/${projectId}/${projectname}`)
  };

  const handleShareCustomValidation = (e: any, productId: any, type: any, name: any) => {
    e.stopPropagation();
    shareCustomValidation.open(productId, type, name)
  }

  return (
    <div>
      {shareCustomValidation.isOpen &&
        <BOMCustomValidationShareModal
          isOpen={shareCustomValidation.isOpen}
          onCloseModal={shareCustomValidation.close}
          idShare={shareCustomValidation.id}
          type={shareCustomValidation.type}
          nameHeader={shareCustomValidation.nameHeader}
        />}
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: 'relative',
            zIndex: 100
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: { lg: "19vw", xl: "17.5vw" },
            }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={() => goBackHandler()}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}>
              {projectname} (BOM Custom Validation)
            </Typography>
          </Box>

          {
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1.5rem",
                justifyContent: "space-between",
                paddingRight: "1rem",
                width: {lg:'67vw',xl:"70vw"},
                padding: '0.3rem 0.5rem',
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1.5rem",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    gap: "0.5rem",
                  }}>
                  {ProductList &&
                    ProductList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            onClick={() => ProductHandler(item?.id)}
                            sx={{
                              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                              backgroundColor:
                                item?.id == topVaultId
                                  ? "primary.light"
                                  : "white",
                              display: "flex",
                              width: "fit-content",
                              borderRadius: "4px",
                              alignItems: "center",
                              padding: '0 0.5rem'
                              // border: item?.custom_config ? "2px solid #47fd47" : "2px solid yellow",
                            }}>
                            <img src={item?.custom_config ? validated : notvalidated} style={{ width: '1.5rem' }} />
                            <Typography sx={{ fontSize: "1rem", cursor: "pointer", marginLeft: '0.5rem', whiteSpace: 'nowrap' }}>
                              {item?.name} ({item?.product_id})
                            </Typography>
                            {item?.custom_config ? <span style={{ display: "flex", alignItems: "center", padding: '0.5rem', }}>
                              <ShareIcon titleAccess="Share Product" sx={{ cursor: 'pointer', fontSize: '1.5rem' }} onClick={(e: any) => handleShareCustomValidation(e, item?.id, 'productLevel', item?.name)} /></span>
                              : <span style={{ width: '0.5rem', height: "2.5rem" }}></span>}
                          </Box>
                        </>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>Select Configuration </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>Select Configuration</Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: "97%",
            display: "flex",
            alignItems: "flex-start",
            paddingRight: "1rem",
          }}>
          <Box>
            <Box
              sx={{
                height: { lg: "85vh", xl: "89vh" },
                width: { lg: "15vw", xl: "15vw" },
                padding: "0.5rem",
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                rowGap: "1rem",

              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >

              {ProductLoader ?
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                </Box> : <>
                  {CotegoryList &&
                    CotegoryList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            onClick={() => CategoryHandler(item)}
                            sx={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                              borderRadius: "10px",
                              cursor: "pointer",
                              width: "100%",
                              // height: "7rem",
                              // marginBottom: "1rem",
                            }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "start",
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                                // border: item?.is_validated
                                //   ? "2px solid #47fd47"
                                //   : "2px solid yellow",
                                backgroundColor:
                                  item?.category == categoryId
                                    ? "primary.light"
                                    : "white",
                              }}>
                              <Box sx={{
                                width: "100%", display: "flex",
                                flexDirection: "column",
                                margin: "0.5rem 0rem 0rem 0.5rem"
                              }}>
                                <Typography sx={{ fontSize: "1rem" }}>
                                  {item?.commodity_name}
                                </Typography>
                                {item?.is_leaf ? (
                                  <Typography sx={{ fontSize: "1rem" }}>
                                    Leaf
                                  </Typography>
                                ) : (
                                  <Typography sx={{ fontSize: "1rem" }}>
                                    Non Leaf
                                  </Typography>
                                )}
                                <Typography sx={{ fontSize: "1rem" }}>
                                  ({item?.parameter_count}) Parameters
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "2rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "5.5rem",
                                  alignItems: 'center',
                                  padding: '3px',
                                }}>
                                <img src={item?.is_validated ? validated : notvalidated} style={{ width: '1.5rem' }} />
                                {item?.is_validated && <ShareIcon titleAccess="Share Category" sx={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={(e: any) => handleShareCustomValidation(e, item?.id, 'categoryLevel', item?.commodity_name)} />}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </>}
            </Box>
          </Box>
          <Box sx={{ width: { lg: '81vw', xl: "83.3vw", }, marginTop: '-2.1rem' }}>
          {topVaultId == '0' && <Box sx={{width:'90%', height:'92vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'2rem', fontWeight:'bold'}}>
              Please select Product
          </Box>}
          {categoryId == '0' && <Box sx={{width:'90%', height:'92vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'2rem', fontWeight:'bold'}}>
              Please select Category
          </Box>}
            {categoryId == '0' ? <></> : CotegoryList?.length > 0 && <BOMCustomValidation CategoryList={CotegoryList && CotegoryList} setParentCount={setParentCount} />}
          </Box>
        </Box>
      </Box>
    </div>
  );
}