import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { API } from "../../api-services";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  debounce,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import FeatureConfigRows from "./FeatureConfigRows";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import FeatureTableParameter from "./FeatureTableParameter";
import { FeatureConfigurationInfoModal } from "./FeatureConfigurationInfoModal";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "../BOM/BomTemplatedHeader";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import FeatureSquenceModal from "./FeatureSquenceModal";
import { UserContextFeature } from "./FeatureConfigurationLanding";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface FeatureConfigurationProps {
  projectId: any;
  CategoryDatalist: any;
  setcategoryCount: any;
  setParentsCounter: any;
  handleChangeCheckBox: any;
  CategoryCount: any;
}
const FeatureConfiguration = (props: FeatureConfigurationProps) => {
  const {
    CategoryDatalist,
    setcategoryCount,
    setParentsCounter,
    CategoryCount,
  } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    mainId,
    categoryname,
    paramType,
  } = useRouteParams<any>();
  const [Datatypelist, setDatatypelist] = useState<any>([]);
  const [checked, setChecked] = React.useState<any>([]);
  const [FeatureCounter, setFeatureCounter] = useState<any>(0);
  const [ParmeterList, setParameterList] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [categoryIds, setCategoryIds] = useState<any>(null);
  const [Sequence, setSequence] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<any>(0);
  //0 for parameter, 1 for value, 2 for others
  const sequenceModal = useBaseModal();
  const ParameterModals = useBaseModal();
  const [datatypeLoader, setdatatypeLoader] = useState<any>(false);
  const ErrorModals = useBaseModal();
  const [updateLoader, setupdateLoader] = useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const ParametershareModals = useBaseModal();
  const [tableIds, settableIds] = useState<any>();
  const ParameterTableModals = useBaseModal();
  const [validationdata, setValidationdata] = useState<any>();
  const [dropdownList, setDropdownList] = useState<any>([]);
  const infomodal = useBaseModal();
  const history = useHistory<any>();
  const [rowItems, setrowItems] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [featurePramsCount, setFeatureParamsCount] = React.useState<any>({});
  const [numberparamsCount, setNumberParamsCount] = React.useState<any>(0);
  const [textparamsCount, setTextparamsCount] = React.useState<any>(0);
  const Premisssions = useContext(UserContextFeature);
  const [applyLoading, setApplyLoading] = React.useState<any>(false);
  const [applytextLoading, setApplyTextLoading] = React.useState<any>(false);
  // const [expanded, setExpanded] = React.useState<string | false>("panel1");

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //   };

  const getTypeName = (type: any) => {
    switch (type) {
      case 1:
        return "parameter";
      case 2:
        return "value";
      case 3:
        return "others";
      default:
        return "parameter";
    }
  };

  const featureLibraryParamsCount = () => {
    API.get(
      "/config/feature_mapping/",
      {
        project: projectId,
        category: categoryId,
      },
      0
    )
      .then((res: any) => {
        setFeatureParamsCount(res?.data[0]);
        setNumberParamsCount(res?.data[0]?.numerical_count);
        setTextparamsCount(res?.data[0]?.text_count);
      })
      .catch((err: any) => {});
  };
  useEffect(() => {
    featureLibraryParamsCount();
  }, [categoryId, projectId]);

  useEffect(() => {
    setdatatypeLoader(true);
    API.get("/config/parameter_datatype_config/", {
      module: 17,
      category: categoryId,
      project: projectId,
    })
      .then((res: any) => {
        setdatatypeLoader(false);
        setDatatypelist(res?.data);
        if(datatype ==0){
          setSelectedTab(res?.data[0]?.parameter__data_type);
          history.push(
            `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${res?.data[0]?.parameter__data_type}/${paramType}`
          );
        }
      })
      .catch((err: any) => {
        setdatatypeLoader(false);
      });
  }, [projectId, categoryId, CategoryCount]);

  const handleTabClick = (tabIndex: any) => {
    history.push(
      `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${0}/${tabIndex}`
    );
  };

  useEffect(() => {
    const checkboxvalue =
      CategoryDatalist &&
      CategoryDatalist?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];

    setValidationdata(checkboxvalue?.is_validated);
  }, [CategoryDatalist, categoryId]);

  const handleNumberApply = () => {
    setApplyLoading(true);
    API.put(
      `/config/feature_mapping/${featurePramsCount?.id}/`,
      {
        numerical_count: numberparamsCount,
        // text_count:textparamsCount
      },
      {},
      0
    )
      .then((res: any) => {
		Swal.fire({
			icon: "success",
			html: `<div>
		<br />
		<p style="color:green;">${"Applied Successfully"}</p>   
		 </div>`,
		  });
	 

        setApplyLoading(false);
      })
      .catch((err: any) => {
		const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
			<br />
			<p style="color:red;">${data[0]}</p>
			 </div>`,
        })
        setApplyLoading(false);
      });
  };

  const handleTextApply = () => {
    setApplyTextLoading(true);
    API.put(
      `/config/feature_mapping/${featurePramsCount?.id}/`,
      {
        // numerical_count:numberparamsCount,
        text_count: textparamsCount,
      },
      {},
      0
    )
      .then((res: any) => {
        setApplyTextLoading(false);
        Swal.fire({
          icon: "success",
          html: `<div>
	  <br />
	  <p style="color:green;">${"Applied Successfully"}</p>   
	   </div>`,
        });
      })
      .catch((err: any) => {
        setApplyTextLoading(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
			<br />
			<p style="color:red;">${data[0]}</p>
			 </div>`,
        });
      });
  };

  const ExactDebounce = React.useRef(
    debounce((criteria: any, id: any, data_type: any, mainIds: any) => {
      if (criteria.toString()?.length > 0) {
        setLoader(true);
        API.put(`/config/parameter_mapping/${id}/`, {
          sequence: parseInt(criteria),
        })
          .then((res: any) => {
            setParameterList([]);
            API.patch("/config/feature_config/", {
              id: mainIds,
              validated: false,
            })
              .then((res: any) => {
                setValidationdata(res?.data?.validated);
                setFeatureCounter((prev: any) => prev + 1);
                setcategoryCount((prev: any) => prev + 1);
              })
              .catch((err: any) => {
                const { data } = err?.response;
                setValidationdata(false);
              });
            setLoader(true);
            API.get(`/config/parameter_mapping/`, {
              project: projectId,
              module: 17,
              category: categoryId,
            })
              .then((res: any) => {
                setLoader(false);
                var filterdata = res?.data?.filter((item: any, index: any) => {
                  return item?.data_type == data_type;
                });
                setParameterList(filterdata);
              })
              .catch((err: any) => {
                setLoader(false);
                console.log("Sever Error");
              });
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }
    }, 900)
  ).current;
  const getData = (data: any, id: any) => {
    ExactDebounce(data, id, datatype, mainId);
  };

  const infoHandler = (id: any) => {
    infomodal?.open();
    setrowItems(id);
  };

  const DeleteHandler = (id: any) => {
    swal({
      title: `Delete Parameter`,
      text: `Are you sure?`,
      icon: "warning",

      buttons: ["Cancel", "Ok"],
    })
      .then((confirm) => {
        if (confirm) {
          if (id) {
            API.delete(`/config/parameter_mapping/${id}`).then((res: any) => {
              API.get(`/config/parameter_mapping/`, {
                project: projectId,
                module: 17,
                category: categoryId,
              })
                .then((res: any) => {
                  setupdateLoader(false);
                  var filterdata = res?.data?.filter(
                    (item: any, index: any) => {
                      return item?.data_type == datatype;
                    }
                  );
                  setParameterList(filterdata);
                  setFeatureCounter((prev: any) => prev + 1);
                  setcategoryCount((prev: any) => prev + 1);
                  API.patch("/config/feature_config/", {
                    id: mainId,
                    validated: false,
                  })
                    .then((res: any) => {
                      setValidationdata(res?.data?.validated);
                    })
                    .catch((err: any) => {
                      const { data } = err?.response;
                      setValidationdata(false);
                    });
                })
                .catch((err: any) => {
                  setupdateLoader(false);
                  console.log("Sever Error");
                });
            });
          }
        }
      })
      .catch((e: any) => {});
  };

  const ExactDebouncedropdown = React.useRef(
    debounce(async (criteria: any, id: any, validationIds: any) => {
      {
        await API.put(`/config/parameter_mapping/${id}/`, {
          required: criteria,
        }).then((res: any) => {
          API.patch("/config/feature_config/", {
            id: validationIds,
            validated: false,
          })
            .then((res: any) => {
              setValidationdata(res?.data?.validated);
              setFeatureCounter((prev: any) => prev + 1);
              setcategoryCount((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data } = err?.response;
              setValidationdata(false);
            });
        });
      }
    }, 900)
  ).current;

  const getDropDownData = (data: any, id: any) => {
    ExactDebouncedropdown(data, id, mainId);
  };

  // const selectedAccordian = (name: any) => {
  //   history.push(
  //     `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${name}/${paramType}`
  //   );
  //   setSelectedCategory(name);
  //   setExpanded(name !== expanded ? name : null);
  // };

  const selectedParameter = (name: any) => {
    setSelectedTab(name);
    setSelectedCategory(name);
    history.push(
      `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${name}/${paramType}`
    );
  };

  const { url } = useRouteMatch();
  useEffect(() => {
    if (datatype === 0) {
      setSelectedTab(0);
    } else if (url && url.includes(datatype)) {
      setSelectedTab(datatype);
    }
  }, [datatype, url]);

  useEffect(() => {
    {
      setLoader(true);
      API.get(`/config/parameter_mapping/`, {
        project: projectId,
        module: 17,
        category: categoryId,
      })
        .then((res: any) => {
          setLoader(false);
          var filterdata = res?.data?.filter((item: any, index: any) => {
            return item?.data_type == datatype;
          });
          setParameterList(filterdata);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log("Sever Error");
        });
    }
  }, [datatype, FeatureCounter]);

  const TableHandler = (id: any) => {
    ParameterTableModals.open();
    settableIds(id);
  };
  const handleChangeCount = (e: any) => {
    const { name, value } = e.target;
    if (name == "number") {
      setNumberParamsCount(+value);
    } else {
      setTextparamsCount(+value);
    }
  };

  const handleChangeCheckBox = (type: any, Id: any) => {
    if (type == "main") {
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: !validationdata,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    }
  };

  const handleChangeCheckBoxValidation = useCallback((type: any) => {
    if (type == "check") {
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
    if (type == "Uncheck") {
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
  }, []);
  const featureSequence = () => {
    sequenceModal?.open();
  };

  const getDropDownList = () => {
    API.get(
      "/config/parameter_mapping/",
      {
        project: projectId,
        module: 17,
        category: categoryId,
        selected_type: "parameter",
        drop_down_list: true,
      },
      0
    )
      .then((res: any) => {
        setDropdownList(res?.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getDropDownList();
  }, []);

  const columnsFiled = CellNameOne.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Sequence</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Name</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Unit</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Exceptions</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Description</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Allowed Values</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
          Not Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Default Value</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Guideline Doc</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Required at</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Action</TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameSecond.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Sequence</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Name</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Description</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Guideline Doc</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Required at</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Action</TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameThird.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Sequence</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Name</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Unit</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Exceptions</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Description</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Allowed Values</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Guideline Doc</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Required at</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Action</TableCell>
      </TableRow>
    </TableHead>
  ) : (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Sequence</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Name</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Description</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Guideline Doc</TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Required at</TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>Action</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <div>
        {ErrorModals.isOpen && (
          <ErrorModalFilesMail
            isOpen={ErrorModals.isOpen}
            onCloseModal={ErrorModals.close}
            ErrorMsg={ErrorModalFileMsg}
          />
        )}

        {infomodal?.isOpen && (
          <FeatureConfigurationInfoModal
            // expanded={selectedCategory}
            selectedTab={selectedCategory}
            onCloseModal={infomodal?.close}
            isOpen={infomodal?.isOpen}
            Item={rowItems}
          />
        )}

        {sequenceModal?.isOpen && (
          <FeatureSquenceModal
            isOpen={sequenceModal?.isOpen}
            onCloseModal={sequenceModal?.close}
            setCount={setFeatureCounter}
            setParentCount={setParentsCounter}
            setValidationdata={setValidationdata}
          />
        )}

        {ParameterTableModals.isOpen && (
          <FeatureTableParameter
            isOpen={ParameterTableModals.isOpen}
            onCloseModal={ParameterTableModals.close}
            tableIds={tableIds}
            categoryIds={categoryIds}
            setFeatureCounter={setFeatureCounter}
            setcategoryCount={setcategoryCount}
            setValidationdata={setValidationdata}
          />
        )}

        {datatypeLoader ? (
          <Box sx={{ padding: "0 1rem" }}>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
            <Skeleton sx={{ height: "3rem" }}></Skeleton>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 0.5rem",
                borderBottom: "1px solid",
                borderColor: "#bbdffb",
              }}
            >
              <Typography sx={{ fontSize: "1rem" }}>
                {categoryname !== 0 && categoryname}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                }}
              >
                {validationdata !== undefined && (
                  <Box sx={{ width: "2rem", textAlign: "center" }}>
                    <Checkbox
                      style={{ padding: "3px" }}
                      onChange={(e) => handleChangeCheckBox("main", mainId)}
                      checked={validationdata}
                      sx={{ color: "primary.main" }}
                      checkedIcon={
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            height: "1rem",
                            width: "1rem",
                            margin: "0.4rem 0.35rem 0.35rem",
                            // marginRight: "0.5rem",
                          }}
                        >
                          <VerifiedIcon
                            sx={{
                              color: "#39FF14",
                              position: "relative",
                              margin: "-0.36rem -0.25rem 0",
                            }}
                          />
                        </Box>
                      }
                    />
                  </Box>
                )}
                <Button
                  sx={{ lineHeight: 1 }}
                  disabled={
                    Premisssions && Premisssions?.includes("U") ? false : true
                  }
                  onClick={() => featureSequence()}
                >
                  Edit Sequence
                </Button>
              </Box>
            </Box>
            {/* Tabs Here */}
            <Box
              sx={{
                borderBottom: "1px solid",
                borderBottomColor: "#bbdffb",
                height: "62vh",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  padding: "0.2rem 1rem",
                }}
              >
                General Parameter
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  width: "100%",
                  borderBottom: "1px solid",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.2"
              >
                {Datatypelist &&
                  Datatypelist.map((item: any, index: any) => {
                    return (
                      <>
                        <Box
                          key={index}
                          sx={{
                            color: "#007fff",
                            height: "2rem",
                            minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0",
                            fontSize: "1rem",
                            width: "100%",
                            backgroundColor:
                              item?.parameter__data_type == selectedTab
                                ? "primary.main"
                                : "primary.light",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                item?.parameter__data_type == selectedTab
                                  ? "white"
                                  : "#007fff",
                              height: "2rem",
                              minHeight: "2.1rem",
                              maxWidth: "100% !important",
                              padding: "0 0.5rem",
                              fontSize: "1rem",
                              width: "100%",
                              textAlign: "center",
                              display: "flex",
                              whiteSpace: "nowrap",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "capitalize",
                            }}
                            onClick={() =>
                              selectedParameter(item?.parameter__data_type)
                            }
                          >
                            {item?.parameter__data_type?.toUpperCase()} (
                            {item?.count})
                          </Typography>
                        </Box>

                        {/* <div>
                        <Box key={index + 1} className={styles.accordianparent}>
                          <div
                            onClick={() =>
                              selectedAccordian(item?.parameter__data_type)
                            }
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              alignItems: "center",
                            }}
                          >
                            <KeyboardArrowDownIcon
                              sx={{
                                transform:
                                  datatype == item.parameter__data_type
                                    ? "rotate(360deg)"
                                    : "rotate(275deg)",
                              }}
                            />

                            <Typography sx={{ fontSize: "1rem" }}>
                              {item?.parameter__data_type?.toUpperCase()}
                            </Typography>
                          </div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1rem",
                              paddingRight: "0.5rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                width: "7rem",
                                textAlign: "right",
                              }}
                            >
                              {item?.count} Parameters
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                       <div
                        className={
                          datatype == item.parameter__data_type
                            ? styles.customacoordain_child_show
                            : styles.customacoordain_child_hide
                        }
                      >
                        <Box
                          sx={{ maxHeight: { lg: "50vh", xl: "70vh" } }}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3"
                        >
                          {loader ? (
                            <Box sx={{ width: "100%" }}>
                              <Table>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <Box>
                                      <Skeleton></Skeleton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </Box>
                          ) : (
                            <>
                              <Table key={index}>
                                {columnsFiled}
                                <TableBody>
                                  {ParmeterList &&
                                    ParmeterList?.map(
                                      (item: any, index: any) => {
                                        return (
                                          <>
                                            <FeatureConfigRows
                                              keys={index}
                                              updateLoader={updateLoader}
                                              getDropDownData={getDropDownData}
                                              getData={getData}
                                              sequence={item && item?.sequence}
                                              rowItems={item && item}
                                              infoHandler={infoHandler}
                                              DeleteHandler={DeleteHandler}
                                              TableHandler={TableHandler}
                                              dropdownList={
                                                dropdownList && dropdownList
                                              }
                                              getDropDownList={getDropDownList}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </>
                          )}

                          {loader ? (
                            ""
                          ) : (
                            <>
                              {ParmeterList && ParmeterList?.length < 1 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "10rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <p>NO Parameter</p>
                                </div>
                              )}
                            </>
                          )}
                        </Box>
                      </div> */}
                      </>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  maxHeight: { lg: "50vh", xl: "62vh" },
                  overflow: "hidden",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {loader ? (
                  <Box sx={{ width: "100%" }}>
                    <Table>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Box>
                ) : (
                  <>
                    <Table>
                      {columnsFiled}
                      <TableBody>
                        {ParmeterList &&
                          ParmeterList?.map((item: any, index: any) => {
                            return (
                              <>
                                <FeatureConfigRows
                                  keys={index}
                                  updateLoader={updateLoader}
                                  getDropDownData={getDropDownData}
                                  getData={getData}
                                  sequence={item && item?.sequence}
                                  rowItems={item && item}
                                  infoHandler={infoHandler}
                                  DeleteHandler={DeleteHandler}
                                  TableHandler={TableHandler}
                                  dropdownList={dropdownList && dropdownList}
                                  getDropDownList={getDropDownList}
                                />
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </>
                )}

                {loader ? (
                  ""
                ) : (
                  <>
                    {ParmeterList && ParmeterList?.length < 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10rem",
                          fontWeight: "bold",
                        }}
                      >
                        <p>NO Parameter</p>
                      </div>
                    )}
                  </>
                )}
              </Box>
            </Box>
            {categoryname != 0 && <Box sx={{ padding: "0.2rem 1rem" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  padding: "0.2rem 0rem",
                }}
              >
                Feature Library Parameter
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      width: "9rem",
                    }}
                  >
                    Number Parameter
                  </Typography>{" "}
                  :
                  <TextField
                    variant="standard"
                    type="number"
                    name="number"
                    inputProps={{
                      step: "any",
                      min: 1,
                    }}
                    value={numberparamsCount}
                    onChange={handleChangeCount}
                    sx={{
                      width: "15rem",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                  <LoadingButton
                    size="small"
                    variant="contained"
                    onClick={() => handleNumberApply()}
                    loading={applyLoading}
                  >
                    Apply
                  </LoadingButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      width: "9rem",
                    }}
                  >
                    Text Parameter
                  </Typography>{" "}
                  :
                  <TextField
                    variant="standard"
                    type="number"
                    name="text"
                    inputProps={{
                      step: "any",
                      min: 1,
                    }}
                    value={textparamsCount}
                    onChange={handleChangeCount}
                    sx={{
                      width: "15rem",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                  <LoadingButton
                    size="small"
                    variant="contained"
                    onClick={() => handleTextApply()}
                    loading={applytextLoading}
                  >
                    Apply
                  </LoadingButton>
                </Box>
              </Box>
            </Box>}
          </>
        )}
      </div>
      <div></div>
    </>
  );
};
export default memo(FeatureConfiguration);
