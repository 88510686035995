import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Autocomplete, Divider, FormControl, InputLabel, TextField, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  height:'fit-content',
  maxHeight: '90vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
interface FetchRawMaterialModalProps {
  onCloseModal: any;
  isOpen: any;
  categorydata?: any;
  electricalCategories: any;
}

const FetchBoughtOutModal = (props: FetchRawMaterialModalProps) => {
  const { onCloseModal, isOpen, categorydata, electricalCategories } = props;

  const { revisionId, typeId, databaseName, categoryName } = useRouteParams<any>();

  const [rawmaterialList, setRawmaterialList] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = React.useState<any>(false);
  const [GetSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false);
  const [expanded, setExpanded] = React.useState<string | false>();
  const [CommodityList, setCommodityList] = useState<any>([]);
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [elecDatatype, setElecDatatype] = useState<any>({});
  const { height, width } = useWindowDimensions();

  
  const handleOpenLinkReference = (link: any) => {
    window.open(link);
  }
  React.useEffect(() => {
    if (categorydata) {
      setDataTypeValues(categorydata[0]);
      handleSelectdatatype(categorydata[0]);
    }
  }, [categorydata]);

  var Columns: any = [
    {
      field: "name",
      headerName: "Item Name",
      width: 200,
    },
    {
      field: "reference",
      headerName: "Reference",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
              Link
            </span>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
    },

    {
      field: "unit_cost",
      headerName: "Per unit cost(INR)",
      width: width > 1400 ? 200 : 150, 
    }
  ];

  let rows: any = [];
  let dataGridDataGenerator: any =
    rawmaterialList &&
    rawmaterialList?.map((item: any, index: any) => {
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        name: item.name,
        reference: item.reference,
        description:item?.description,
        unit_cost: `₹ ${item.unit_cost}`
      };
      rows.push(objShared);
    });

  const saveHandler = () => {
    setLoader(true);
    API.post("/api/db/bought_out_cost/", {
      purchase_items: GetSelectedIds,
      revision: revisionId,
    })
      .then((res: any) => {
        setGetSelectedIds([]);
        onCloseModal()
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });


  };
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
    setIsLoadingData(true);
    setRawmaterialList([]);
    API.get("/api/db/bought_out/", { revision: revisionId?revisionId:undefined, commodity: item.category ,fetch:true})
      .then((res: any) => {
        setRawmaterialList(res?.data);
        setIsLoadingData(false);
      })
      .catch((err: any) => {
        console.log("Srver Error");
        setIsLoadingData(false);
      });
  };
  const handleSelectdatatypeElec = (item: any) => {
    setElecDatatype(item);
    setIsLoadingData(true);
    setRawmaterialList([]);
    API.get("/api/db/bought_out/", { revision: revisionId?revisionId:undefined, commodity: DataTypeValues.category, module_type: item.module_type,fetch:true })
      .then((res: any) => {
        setRawmaterialList(res?.data);
        setIsLoadingData(false);
      })
      .catch((err: any) => {
        console.log("Srver Error");
        setIsLoadingData(false);
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}>
            Add Bought-out Parts
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "0.5rem",
          }}
        />
        <Box sx={{ padding: "0 0.5rem" }}>
          <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", width: { lg: "89%", xl: "91%" }, marginBottom: "-2rem" }}>
            <FormControl
              variant="standard"
              sx={{ zIndex: "1" }}>
              {/* <InputLabel
                sx={{ color: "primary.main" }}
                shrink
                id="demo-simple-select-label">
                Select Material Type
              </InputLabel> */}
              <Autocomplete
                sx={{
                  marginTop: "1rem",
                  minWidth: "300px",
                  width: "100%",
                  ".MuiSvgIcon-root": { color: "primary.main" },
                  fontSize: "1rem !important",
                }}
                size="small"
                id="combo-box-demo"
                //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
                value={DataTypeValues}
                getOptionLabel={(option) => (option?.category == undefined ? "" : option?.category)}
                onChange={(event, newValue, situation) => handleSelectdatatype(newValue)}
                options={categorydata && categorydata}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ fontSize: "1rem" }}
                    {...props}>
                    {option?.category}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    // label="Select Material Type"
                    placeholder="Select Material Type"
                    sx={{
                      fontSize: "1rem",
                      borderBottom: "none",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    {...params}
                  // label="Data Type"
                  />
                )}
              />
            </FormControl>
            {DataTypeValues?.category === "Electrical & Electronics Proprietary Parts" && <FormControl
              variant="standard"
              sx={{ zIndex: "1" }}>
              {/* <InputLabel
                sx={{ color: "primary.main" }}
                shrink
                id="demo-simple-select-label">
                Select Material Type
              </InputLabel> */}
              <Autocomplete
                sx={{
                  marginTop: "1rem",
                  minWidth: "300px",
                  width: "100%",
                  ".MuiSvgIcon-root": { color: "primary.main" },
                  fontSize: "1rem !important",
                }}
                size="small"
                id="combo-box-demo"
                //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
                value={elecDatatype}
                getOptionLabel={(option) => (option?.module_type == undefined ? "" : option?.module_type)}
                onChange={(event, newValue, situation) => handleSelectdatatypeElec(newValue)}
                options={electricalCategories && electricalCategories}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ fontSize: "1rem" }}
                    {...props}>
                    {option?.module_type}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    // label="Select Material Type"
                    placeholder="Select Material Type"
                    sx={{
                      fontSize: "1rem",
                      borderBottom: "none",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    {...params}
                  // label="Data Type"
                  />
                )}
              />
            </FormControl>}
            <LoadingButton
              variant="contained"
              sx={{
                background: GetSelectedIds?.length > 0 ? "#d32f2f" : "",
                zIndex: "1",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
                padding: "0.2rem 1rem",
              }}
              disabled={GetSelectedIds?.length > 0 ? false : true}
              size="small"
              loading={Loader}
              onClick={() => saveHandler()}>
              Addd
            </LoadingButton>
          </Box>

          <Box
            sx={{ height: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {DataTypeValues && Object.keys(DataTypeValues) && (
              <DataGrid
                loading={isLoadingData}
                headerHeight={48}
                rowHeight={36}
                rows={rows && rows}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                checkboxSelection
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  setGetSelectedIds((prev: any) => ids);
                }}
                sx={{
                  "& .MuiDataGrid-checkboxInput": {
                    color: "primary.main",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.4rem",
                    height: "0.4rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FetchBoughtOutModal;
