import {
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import * as React from "react";

import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { BOMEntryAccordian } from "./BOMEntryAccordian";
import CircleIcon from '@mui/icons-material/Circle';
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { ICalculator } from "../../../Redux/Reducers/calculator.reducer";
import calcIcon from "../../../Assets/images/calculator_green.svg";
import BOMCalculatorValueInputModal from "./BOMCalculatorValueInputModal";
import calculatorService from "../../../Redux/Services/calculator.service";
import useFetch from "../../../Core/CustomHooks/useFetch";



import { useBaseModal } from "../../SearchPage/useBaseModal";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface IBomEntryTableComponentProps {
  tableDataGetCount: any;
  BOMValidationStatus: any;
  counter: any;
}

export default function BomEntryTableComponent(
  props: IBomEntryTableComponentProps
) {
  const { tableDataGetCount, BOMValidationStatus, counter } = props;
  const { workstation, topVault, vault, projectId } = useRouteParams<any>();
  const [tableData, setTableData] = React.useState<any>([]);
  const [tableDataLoader, setTableDataLoader] = React.useState<any>(false);
  const [tableAccData,setTableAccData]=React.useState<any>([]);
  const [tableIdddList, setTableIdddList] = React.useState<any>({});
  const [tableDataCount, setTableDataCount] = React.useState<any>(0);
  const [tableCalcData, setTableCalcData] = React.useState<any>();
  const [tableCalcDataLoader, setTableCalcDataLoader] = React.useState<any>(false);
  const [calcData, setCalcData] = React.useState<any>();
  const [calcId, setCalcId] = React.useState<any>(0);
  const inputModal = useBaseModal();
  const [count, setCount] = React.useState<number>(0);
  // const [Counter, setCounter] = React.useState<any>(0);
  const [CalculatorItem, setCalculatorItem] = React.useState<any>()
    const [calculatorName, setCalculatorName] = React.useState<
        string | undefined
    >(undefined);
  const [dataFullfilled, setDataFullfilled] = React.useState<boolean>(false);
  console.log(counter, "BOMValidationStatus");
  

 
  const userAction = React.useContext(userPermissionAction);
  console.log(tableAccData, "tableAccData");

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  const getTableData = () => {
    
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
      },
      0
    )
      .then((res: any) => {
        
        setTableData(res.data);
      })
      .catch((err: any) => {
        
      });
  };

  React.useEffect(() => {
    const dataObj:any = {};
    tableAccData.map((item:any)=>{
      dataObj[item.name] = item.id;
    })
    setTableIdddList(dataObj);


  }, [tableAccData])

  const getTableNameCount=()=>{
    setTableDataLoader(true);
    API.get("/xcpep/bom_create_view/",{
      vault:vault,
      table:true,
      basic_info:true,
    },0).then((res:any)=>{
      setTableAccData(res.data);
      setTableDataLoader(false);
    }).catch((err:any)=>{
      setTableDataLoader(false);
    })
  }


  const getCalculatorList = () => {
    setTableCalcDataLoader(true);
    API.get(
      `/xcpep/bom_entry_calculator/`,
      {
        ...getObjKeyName(),
      }, 0
    )
    .then((res: any) => {
      
      setTableCalcData(res?.data);
      setTableCalcDataLoader(false);
      
    
    })
    .catch((err: any) => {
      console.log(err);
      setTableCalcDataLoader(false);
    });
  }


//   const { 0: gettingCalvalue, 1: calculator } = useFetch<ICalculator>({
//     method: calculatorService.getByBOMCalcultorIds,
//     args: [vault, calcId, topVault],
//     dependencies: [calcId, vault, topVault],
//     condition: calculatorName !== undefined,
// })


  


  React.useEffect(() => {
    if (workstation != 0) {
      getTableNameCount();
      getCalculatorList();
    }
  }, [workstation, vault, tableDataGetCount, tableDataCount, counter]);

  React.useEffect(() => {
    if (workstation != 0 && count != 0) {
      getCalculatorList();
    }
  }, [count])

  React.useEffect(() => {
    BOMValidationStatus();
  }, [tableCalcData])


  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const getTableCalculatorData = (id:any) => {
    setDataFullfilled(false);
    API.get(`/xcpep/bom_entry_calculator/`,
    {
      calculation_id: id,
      ...getObjKeyName(),
    },
     0)
     .then((res: any) => {
      setCalcData(res.data);
      setDataFullfilled(true);
      console.log(res.data, "calcData");
     })
     .catch((err: any) => {
        console.log(err);
      });

  }

  // React.useEffect(() => {
  //   if (workstation != 0 && calcId != 0) {
      
  //     getTableCalculatorData(calcId);
  //   }
  
  // }, [calcId])


  const handleCalculatorClick = (id: any, name: any) => {
    setCalcId(id);
    setCalculatorName(name);
    getTableCalculatorData(id);
    inputModal.open();
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? false : false);
    };

  return (
    <div>
      {
        inputModal.isOpen && dataFullfilled && <BOMCalculatorValueInputModal
        isOpen={inputModal.isOpen}
        onClose={inputModal.close}
        fetchCalculator={getTableCalculatorData}
        CalculatorItem={calcId}
        calculatorData={calcData}
        setCalcData={setCalcData}
        calcName={calculatorName}
                    setCount={setCount}
                    count={count}
                    BOMValidationStatus={BOMValidationStatus}
                    
                    />

      }
      {!tableCalcDataLoader && tableCalcData ? (
        tableCalcData?.map((item: any, index: number) => {
          return (
            <Accordion
              // expanded={expanded === `${index}`}
              onChange={handleChange(`${index}`)}              
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "0.5rem",
                  width: "100%",

                }}
                onClick={() => handleCalculatorClick(item?.id, item?.calculator?.name)}
              >               
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",                    
                    backgroundColor: " #f7f7f7",  
                    height:'2rem',   
                    width: "100%", 
                    paddingRight: "0.5rem",           
                  }}
                ><Typography sx={{display: "flex",
                alignItems: "center",
                
                columnGap: "0.5rem",}}>
                  <CircleIcon  sx={{fontSize:'0.65rem',margin:'0 0.5rem'}} />                  
                  </Typography>
                  <Box sx={{display: "flex", alignItems: "center", columnGap: "0.5rem", width: "100%"  }}>
                    <Typography sx={{fontSize:'12px',width:'100%', cursor:'pointer'}} >
                    {item?.calculator?.name}  
                  </Typography>   
                  <Typography sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>
                    Result : {item?.result % 1 ? item?.result.toFixed(2) : item?.result}  
                  </Typography>  
                  <img src={calcIcon} alt="calculator" style={{width:'1rem',height:'1rem',cursor:'pointer'}} 
          title="Expand Calculator"
          // style={{ cursor: "pointer" }}
          onClick={() => handleCalculatorClick(item?.id, item?.calculator?.name)}
        />
                  </Box>
                             
                </Box>
              </Box>             
            </Accordion>
          )
        })
      ) : (
        <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="2rem"
          width="100%"
          sx={{ marginBottom: "1rem" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="2rem"
          width="100%"
          sx={{ marginBottom: "1rem" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="2rem"
          width="100%"
          sx={{ marginBottom: "1rem" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="2rem"
          width="100%"
          sx={{ marginBottom: "1rem" }}
        />
      </Box>
      )

      }
      {!tableDataLoader && tableAccData ? (
        tableAccData?.map((item: any, index: number) => {
          return (
            <Accordion
              // expanded={expanded === `${index}`}
              onChange={handleChange(`${index}`)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "0.5rem",
                  width: "100%",

                }}
              >               
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",                    
                    backgroundColor: " #f7f7f7",  
                    height:'2rem',   
                    width: "100%", 
                    paddingRight: "0.5rem",           
                  }}
                ><Typography sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                }}>
                    <CircleIcon sx={{ fontSize: '0.65rem', margin: '0 0.5rem' }} />
                  </Typography>
                  <BOMEntryAccordian
                    tableName={item?.name}
                    item={item?.id}
                    validateCount={item}
                    getTableData={getTableData}
                    materialCount={item?.count}
                    BOMValidationStatus={BOMValidationStatus}
                    tableAccData={getTableNameCount}
                    tableIdddList={tableIdddList}
                    setTableDataCount={setTableDataCount}
                  />                 
                </Box>
              </Box>             
            </Accordion>
          );
        })
      ) : (
        <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      )}
    </div>
  );
}
