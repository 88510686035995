import React, { useContext, useEffect, useState } from "react";
import {
  CellNameFive,
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  Autocomplete,
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { API } from "../../api-services";
import { debounce, set } from "lodash";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import { UserContext } from "./BomConfigurationLanding";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import BOMCreateCostCalculatorModal from "./BOMCreateCostCalculatorModal";
import { BOMConfigCalculator } from "../../DataBaseModule/ParameterLibrary/BOMCalculatorModal";
import SettingsIcon from "@mui/icons-material/Settings";
import calcIcon from "../../Assets/images/calculator.svg";
import calculator_green from "../../Assets/images/calculator_green.svg";
import { ConstantLine } from "devextreme-react/chart";
import { BOMConfigTableCalculator } from "../../DataBaseModule/ParameterLibrary/BOMTableCalculatorModal";
import { BomTemplateinfoModal } from "./BomTemplateinfoModal";

interface BomConfigurationsRowrenderProps {
  rowItem: any;
  keys: any;
  setCounter: any;
  setUpdatedCounter: any;
  workstationList: any;
  handleChangeCheckBox: any;
  tablecallBackhandler: any;
  InfoModalhandler: any;
}

const BomConfigurationsRowrender = (props: BomConfigurationsRowrenderProps) => {
  const {
    rowItem,
    keys,
    setCounter,
    workstationList,
    handleChangeCheckBox,
    setUpdatedCounter,
    tablecallBackhandler,
    InfoModalhandler,
  } = props;

  const history = useHistory<any>();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();

  const [tagData, settagData] = useState<any>(rowItem?.tag);
  const userpremission = useContext(UserContext);
  const [sequence, setSequence] = useState<any>(rowItem?.sequence);
  const [requiredValue, setrequiredValue] = useState<any>(rowItem?.required);
  const [WorkStations, setWorkStations] = useState<any>([]);
  const [database, setDataBase] = useState<any>({});
  const [workstationData, setworkstationData] = useState<any>([]);
  const [inputData, setInputData] = useState<any>([]);
  const [calculatorID, setCalculatorID] = useState<any>(0);
  const calculatorModal = useBaseParamsModal();
  const calculatorTableModal = useBaseParamsModal();
  const bomCostCalculator = useBaseParamsModal();
  const infoModal = useBaseParamsModal();
  const DataBaseName = [{ text: "SupplierDatabase", name: "Supplier DB" }];
  useEffect(() => {
    var filterdIds = workstationList?.filter((item: any, index: any) => {
      return rowItem?.workstation?.includes(item?.id);
    });
    setworkstationData(workstationList && workstationList);
    setWorkStations(filterdIds);
    var data = DataBaseName?.filter((item: any, index: any) => {
      return item?.text == rowItem?.database;
    });

    setDataBase(data[0]);
  }, [rowItem]);

  const parameterDatabaseRidirection = () => {
    window.open(
      `/#/data-base/Parameter-library/${rowItem?.data_type}/0`,
      "__blank"
    );
  };

  const CalculatorHandler = (rowItem: any) => {
    setInputData(rowItem);
    setCalculatorID(rowItem?.id);
    if (datatype == "BOM-table-calculator") {
      calculatorTableModal.open({
        id: rowItem?.id,
        calcId: rowItem?.table_calculator?.id,
        finalResult: rowItem?.table_calculator?.result,
        BOM_table_unit: rowItem?.table_calculator?.unit
      });
    } else {
      calculatorModal.open(rowItem?.id);
    }

    // bomCostCalculator.open(rowItem?.id);
  };

  var optionData = workstationData && workstationData;
  const ExactDebounce = React.useRef(
    debounce(
      async (
        criteria: any,
        Id: any,
        type: any,
        topVault: any,
        validationsIds: any
      ) => {
        if (type == "Tag") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            tag: criteria,
            top_vault:
              validationsIds == 0
                ? topVault.toString()
                : validationsIds?.toString(),
          }).then((res: any) => {
            settagData(res.data?.tag);
            handleChangeCheckBox();
            // setCounter((prev:any)=>prev+1)
          });
        } else if (type == "sequence") {
          if (criteria !== null) {
            await API.put(`/config/parameter_mapping/${Id}/`, {
              sequence: parseInt(criteria),
              top_vault:
                validationsIds == 0
                  ? topVault?.toString()
                  : validationsIds?.toString(),
            }).then((res: any) => {
              //  setSequence(res.data?.sequence );
              setUpdatedCounter((prev: any) => prev + 1);
              handleChangeCheckBox();
            });
          }
        } else if (type == "required") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            required: criteria,
            top_vault:
              validationsIds == 0
                ? topVault?.toString()
                : validationsIds?.toString(),
          }).then((res: any) => {
            setrequiredValue(res.data?.required);
            handleChangeCheckBox();
            // setCounter((prev:any)=>prev+1)
          });
        } else if (type == "workstations") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            workstation: criteria,
            top_vault:
              validationsIds == 0
                ? topVault?.toString()
                : validationsIds?.toString(),
          }).then((res: any) => {
            var filterdIds = workstationList?.filter(
              (item: any, index: any) => {
                return res.data?.workstation?.includes(item?.id);
              }
            );

            setworkstationData(workstationList && workstationList);
            setWorkStations(filterdIds);
          });
        }
        if (type == "dataBase") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            database: criteria == undefined ? null : criteria,
            top_vault:
              validationsIds == 0
                ? topVault?.toString()
                : validationsIds?.toString(),
          }).then((res: any) => {
            var data = DataBaseName?.filter((item: any, index: any) => {
              return item?.text == res?.data?.database;
            });

            setDataBase(data[0]);
          });
        }
      },
      700
    )
  ).current;

  const handleChange = (e: any, type: any) => {
    if (type == "Tag") {
      settagData(e.target.value);
      ExactDebounce(
        e.target.value,
        rowItem?.id,
        "Tag",
        productId,
        validationId
      );
      // setCounter((prev:any)=>prev+1)
    } else if (type == "sequence") {
      setSequence(e.target.value);
      if (e.target.value !== null || e?.target?.value?.length !== 0) {
        ExactDebounce(
          e.target.value,
          rowItem?.id,
          "sequence",
          productId,
          validationId
        );
      }
    }

    if (type == "required") {
      setrequiredValue(e.target.value);
      ExactDebounce(
        e.target.value,
        rowItem?.id,
        "required",
        productId,
        validationId
      );
      // setCounter((prev:any)=>prev+1)
    }
  };

  const handleUpdateChange = (e: any) => {
    setrequiredValue(e.target.value);
    API.put(
      `/xcpep/bom_calculator_config/`,
      {
        calculator_id: rowItem?.id,
        is_required: e.target.value,
      },
      0
    )
      .then((res: any) => {
        setCounter((prev: any) => prev + 1);
        handleChangeCheckBox();
      })
      .catch((err: any) => { });
  };

  const handleSelectChange = (item: any) => {
    setWorkStations(item);
    var ids = item?.map((item: any, index: any) => {
      return item?.id;
    });
    var filterdata = workstationData?.filter((items: any, index: any) => {
      return !ids.includes(items?.id);
    });

    ExactDebounce(ids, rowItem?.id, "workstations", productId, validationId);
    setworkstationData(filterdata);
  };

  const handledatabseSelectChange = (item: any) => {
    setDataBase(item);
    ExactDebounce(item?.text, rowItem?.id, "dataBase", productId, validationId);
  };

  const DeleteHandler = () => {
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/config/parameter_mapping/${rowItem?.id}/`,
          {
            top_vault:
              validationId == 0
                ? productId?.toString()
                : validationId?.toString(),
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
          })
          .catch((err: any) => { });
      }
    });
  };

  // http://localhost:9000/xcpep/bom_calculator_config?calculator_id=157

  const DeleteHandlerCostModel = () => {
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/xcpep/bom_calculator_config/`,
          {
            calculator_id: rowItem?.id,
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
          })
          .catch((err: any) => { });
      }
    });
  };

  const TableHandler = (item: any) => {
    tablecallBackhandler(item);
  };
  const Infohandler = (item: any) => {
    InfoModalhandler(item);
  };


  return (
    <>
      {CellNameOne.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={sequence}
              type="number"
              variant="standard"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.unit}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.exceptions}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.allowed_values.toString()}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {" "}
            {rowItem?.parameter?.not_allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.not_allowed_values.toString()}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.default_value}
            {rowItem?.parameter?.default_value == ""
              ? "_"
              : rowItem?.parameter?.default_value}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={tagData}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              onChange={(e: any) => handleChange(e, "Tag")}
              label="Tag">
          
              <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
           }
              <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
            </Select>
          </TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              id="combo-box-demo"
              value={WorkStations}
              sx={{
                ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                {
                  color: "primary.main",
                },
                ".MuiButtonBase-root.MuiChip-root": {
                  backgroundColor: "primary.light",
                },
              }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="work station"
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            {rowItem?.map_status ? (
              <InfoIcon
                titleAccess="Info"
                onClick={() => Infohandler(rowItem)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <>
                {(userpremission && userpremission?.includes("D")) ? (
                  <DeleteIcon
                    sx={{ cursor: "pointer", opacity: 1 }}
                    titleAccess="Delete"
                    onClick={() => DeleteHandler()}
                  />
                ) : (
                  <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                )}
              </>
              // <IconButton
              //   disabled={
              //     userpremission && userpremission?.includes("D") ? false : true
              //   }>
              //   <DeleteIcon
              //     sx={{ cursor: "pointer" }}
              //     titleAccess="Delete"
              //     onClick={() => DeleteHandler()}
              //   />
              // </IconButton>
            )}
            <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            />
            {(rowItem?.table_calculator?.result == "" || rowItem?.table_calculator?.result == null) && (datatype == "BOM-table-calculator") && (
              <img
                src={calcIcon}
                style={{ cursor: rowItem?.table_calc_enable ? "pointer" : "not-allowed", height: "1.4rem", opacity: rowItem?.table_calc_enable ? 1 : 0.5 }}
                title={
                  (rowItem?.table_calculator?.result == "" || rowItem?.table_calculator?.result == null)
                    ? "BOM Configuration table Calculator"
                    : `Result: ${rowItem?.table_calculator?.result}`
                }
                onClick={() => {
                  if (rowItem?.table_calc_enable) {
                    CalculatorHandler(rowItem)
                  }
                }}
              />
            )}
            {(rowItem?.table_calculator?.result != "" && rowItem?.table_calculator?.result != null) && (datatype == "BOM-table-calculator") && (
              <img
                src={calculator_green}
                style={{ cursor: "pointer", height: "1.4rem" }}
                title={
                  rowItem?.table_calculator?.result == ""
                    ? "BOM Configuration Calculator"
                    : `Result: ${rowItem?.table_calculator?.result}`
                }
                onClick={() => CalculatorHandler(rowItem)}
              />
            )}
            {datatype === "BOM-table-calculator" && (
              <>
                {/*  <IconButton
                   disabled={
                     false : true
                   }> */}
                {userpremission && userpremission?.includes("U") ? (
                  <TableChartIcon
                    onClick={() => TableHandler(rowItem)}
                    sx={{ cursor: "pointer", opacity: "1" }}
                  />
                ) : (
                  <TableChartIcon sx={{ opacity: "0.3" }} />
                )}
                {/* </IconButton> */}
              </>
            )}
          </TableCell>
        </TableRow>
      ) : CellNameSecond.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              variant="standard"
              value={sequence}
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          {datatype == "database" && (
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Autocomplete
                disabled={
                  userpremission && userpremission?.includes("U") ? false : true
                }
                id="combo-box-demo"
                value={database}
                sx={{ width: "auto" }}
                onChange={(event, newValue) => {
                  handledatabseSelectChange(newValue);
                }}
                options={DataBaseName}
                // @ts-ignore
                getOptionLabel={(option) =>
                  option?.name == undefined ? "" : option?.name
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Data base"
                    {...params}
                    variant="standard"
                    sx={{
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </TableCell>
          )}
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={tagData}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              onChange={(e: any) => handleChange(e, "Tag")}
              label="Tag">
            
              <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
           
              <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
            </Select>
          </TableCell> */}

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              value={WorkStations}
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              // renderTags={(tagValue, getTagProps) =>
              //   tagValue.map((option, index) => (
              //     <Chip
              //       size="small"
              //       //  @ts-ignore
              //       sx={{
              //         color: "primary.main",
              //         background: `${option?.user_color}`,
              //         fontSize: "1rem",
              //       }}
              //       // @ts-ignore
              //       label={option?.email}
              //       {...getTagProps({ index })}
              //     // disabled={fixedOptions.indexOf(option) !== -1}
              //     />
              //   ))
              // }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="work station"
                  {...params}
                  variant="standard"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            {rowItem?.map_status ? (
              <InfoIcon
                titleAccess="Info"
                onClick={() => Infohandler(rowItem)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <>
                {userpremission && userpremission?.includes("D") ? (
                  <DeleteIcon
                    sx={{ cursor: "pointer", opacity: 1 }}
                    titleAccess="Delete"
                    onClick={() => DeleteHandler()}
                  />
                ) : (
                  <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                )}
              </>
            )}
            <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            />
            {datatype == "table" && (
              <>
                {/*  <IconButton
                   disabled={
                     false : true
                   }> */}
                {userpremission && userpremission?.includes("U") ? (
                  <TableChartIcon
                    onClick={() => TableHandler(rowItem)}
                    sx={{ cursor: "pointer", opacity: "1" }}
                  />
                ) : (
                  <TableChartIcon sx={{ opacity: "0.3" }} />
                )}
                {/* </IconButton> */}
              </>
            )}
          </TableCell>
        </TableRow>
      ) : CellNameThird.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              variant="standard"
              value={sequence}
              onChange={(e: any) => handleChange(e, "sequence")}
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.unit}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.exceptions}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.allowed_values.toString()}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={tagData}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              onChange={(e: any) => handleChange(e, "Tag")}
              label="Tag">
          
              <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
          
              <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
            </Select>
          </TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              value={WorkStations}
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="work station"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            {rowItem?.map_status ? (
              <InfoIcon
                titleAccess="Info"
                onClick={() => Infohandler(rowItem)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <>
                { userpremission && userpremission?.includes("D") ? (
                  <DeleteIcon
                    sx={{ cursor: "pointer", opacity: 1 }}
                    titleAccess="Delete"
                    onClick={() => DeleteHandler()}
                  />
                ) : (
                  <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                )}
              </>
            )}
            <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            />
          </TableCell>
        </TableRow>
      ) : CellNameFive.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={rowItem?.sequence}
              variant="standard"
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.description}
          </TableCell>

          {/* <TableCell
            sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }>
            {rowItem?.info_doc == null ? "-" : <VisibilityOutlinedIcon sx={{ cursor: "pointer", }} />}
          </TableCell> */}
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={tagData}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              onChange={(e: any) => handleChange(e, "Tag")}
              label="Tag">
             
              <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
             
              <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
            </Select>
          </TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={rowItem?.is_required}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleUpdateChange(e)}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Autocomplete
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              id="combo-box-demo"
              value={WorkStations}
              sx={{ width: 'auto' }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              // renderTags={(tagValue, getTagProps) =>
              //   tagValue.map((option, index) => (
              //     <Chip
              //       size="small"
              //       //  @ts-ignore
              //       sx={{
              //         color: "primary.main",
              //         background: `${option?.user_color}`,
              //         fontSize: "1rem",
              //       }}
              //       // @ts-ignore
              //       label={option?.email}
              //       {...getTagProps({ index })}
              //     // disabled={fixedOptions.indexOf(option) !== -1}
              //     />
              //   ))
              // }
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem',whiteSpace:'nowrap'  }}>
                  {option?.name}
                </li>
              )} 
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="work station"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </TableCell> */}

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
            }}
          >
            {rowItem?.map_status ? (
              <InfoIcon
                titleAccess="Info"
                onClick={() => Infohandler(rowItem)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <>
                {rowItem?.mapped_status ?
                  <IconButton
                    style={{ color: "#007fff", }}
                   onClick={()=>infoModal.open(rowItem)}
                    title="Place Used (BOM Category)">
                    <InfoIcon />
                  </IconButton>
                  : <DeleteIcon
                    sx={{ cursor: "pointer", opacity:  userpremission && userpremission?.includes("D") ? 1 : 0.5 }}
                    titleAccess="Delete"
                    onClick={() => {
                      if (userpremission && userpremission?.includes("D")) {
                        DeleteHandlerCostModel()
                      }
                    }}
                  />}
              </>
            )}
            {/* <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            /> */}
            {(rowItem?.result == "" || rowItem?.result == null) && (
              <img
                src={calcIcon}
                style={{ cursor: "pointer", height: "1.4rem" }}
                title={
                  rowItem?.result == ""
                    ? "BOM Configuration Calculator"
                    : `Result: ${rowItem?.result}`
                }
                onClick={() => CalculatorHandler(rowItem)}
              />
            )}
            {rowItem?.result != "" && rowItem?.result != null && (
              <img
                src={calculator_green}
                style={{ cursor: "pointer", height: "1.4rem" }}
                title={
                  rowItem?.result == ""
                    ? "BOM Configuration Calculator"
                    : `Result: ${rowItem?.result}`
                }
                onClick={() => CalculatorHandler(rowItem)}
              />
            )}
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={sequence}
              variant="standard"
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={tagData}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              onChange={(e: any) => handleChange(e, "Tag")}
              label="Tag">
             
              <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
             
              <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
            </Select>
          </TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              disabled={
                userpremission && userpremission?.includes("U") ? false : true
              }
              id="combo-box-demo"
              value={WorkStations}
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              // renderTags={(tagValue, getTagProps) =>
              //   tagValue.map((option, index) => (
              //     <Chip
              //       size="small"
              //       //  @ts-ignore
              //       sx={{
              //         color: "primary.main",
              //         background: `${option?.user_color}`,
              //         fontSize: "1rem",
              //       }}
              //       // @ts-ignore
              //       label={option?.email}
              //       {...getTagProps({ index })}
              //     // disabled={fixedOptions.indexOf(option) !== -1}
              //     />
              //   ))
              // }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="work station"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            {rowItem?.map_status ? (
              <InfoIcon
                titleAccess="Info"
                onClick={() => Infohandler(rowItem)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <>
                { userpremission && userpremission?.includes("D") ? (
                  <DeleteIcon
                    sx={{ cursor: "pointer", opacity: 1 }}
                    titleAccess="Delete"
                    onClick={() => DeleteHandler()}
                  />
                ) : (
                  <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                )}
              </>
            )}
            <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            />
          </TableCell>
        </TableRow>
      )}

      {calculatorModal.isOpen && (
        <BOMConfigCalculator
          isOpen={calculatorModal.isOpen}
          onCloseModal={calculatorModal.close}
          id={calculatorModal.propsId}
          inputData={inputData}
          setCounter={setCounter}
        />
      )}
      {calculatorTableModal.isOpen && (
        <BOMConfigTableCalculator
          isOpen={calculatorTableModal.isOpen}
          onCloseModal={calculatorTableModal.close}
          rowObj={calculatorTableModal.propsId}
          inputData={inputData}
          setCounter={setCounter}
        />
      )}
      {infoModal.isOpen &&
        <BomTemplateinfoModal

          onCloseModal={infoModal.close}
          isOpen={infoModal.isOpen}
          Item={infoModal.propsId}


        />
      }
      {/* {
        bomCostCalculator.isOpen && <BOMCreateCostCalculatorModal isOpen={bomCostCalculator.isOpen} onCloseModal={bomCostCalculator.close} id={bomCostCalculator.propsId} inputData={inputData} />
      } */}
    </>
  );
};

export default BomConfigurationsRowrender;
