import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Swal from 'sweetalert2';
import { API } from '../../../api-services';

export interface IRawMaterialActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	history?: any;
	tab?: any;
	year?: any;
	month?: any;
	categoryName?: any;
	status?: any;
	search?: any;
	useraction?: any;
	getMaterialData?: any;
	infoRawMaterialModal?: any;
	graphModal?: any;
	moveModal?: any;
	setAnchorEl?: any;
}

export default function RawMaterialActionComponents(
	props: IRawMaterialActionComponentsProps
) {
	const {
		cellValues,
		rowId,
		history,
		tab,
		year,
		month,
		categoryName,
		status,
		search,
		useraction,
		getMaterialData,
		graphModal,
		infoRawMaterialModal,
		moveModal,
		setAnchorEl,
	} = props;

	// console.log("cellValues", cellValues);
	// console.log("rowId", rowId);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			{rowId.mapped_status ? (
				<IconButton
					title='Material Info'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
					onClick={() => infoRawMaterialModal.open(rowId.id)}>
					<InfoIcon />
				</IconButton>
			) : (
				rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('D') && (
					<IconButton
						title='Delete'
						sx={{
							padding: '0.5rem',
							cursor: 'pointer',
							color: 'primary.main',
						}}
						onClick={() => {
							setAnchorEl(null);
							Swal.fire({
								title: 'Delete material',
								text: 'Are you sure you want to delete ?',
								icon: 'error',
								confirmButtonText: 'Yes, delete it!',
								showCancelButton: true,
							}).then((confirm) => {
								if (confirm) {
									API.delete(`/api/db/material_cost/${rowId.id}/`, {}, 0)
										.then((res: any) => {
											getMaterialData(categoryName);
										})
										.catch((err: any) => {});
								}
							});
						}}>
						<DeleteIcon />
					</IconButton>
				)
			)}

			<IconButton
				title='Graph'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() => graphModal.open(rowId)}>
				<EqualizerIcon />
			</IconButton>

			<IconButton
				title='Redirect'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() =>
					history.push(
						`/data-base/revision-database/${tab}/${year}/${month}/1/1/rmDB/${categoryName}/${status}/${search}`
					)
				}>
				<LaunchIcon sx={{ cursor: 'pointer' }} />
			</IconButton>

			{rowId?.material_file_flag && (
				<IconButton
					title='Flag'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
					onClick={() =>
						window.open(rowId?.material_file, '_blank', ' noopener')
					}>
					{' '}
					<FlagIcon sx={{ color: 'green' }} />
				</IconButton>
			)}
			{rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('U') && (
					<IconButton
						title='Move'
						sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
						onClick={() => {
							moveModal.open(rowId);
							setAnchorEl(null);
						}}>
						<img
							src={move}
							style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem' }}
						/>
					</IconButton>
				)}
		</Box>
	);
}
