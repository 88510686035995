import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Skeleton,
  TextField,
  Typography,
  Chip,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

import { set } from "lodash";
import { ADMIN } from "../../Redux/Services/admin.service";
const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "80%", xl: "80%" },
  height: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "35vw", xl: "30vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const styleAddCostModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "55vw", xl: "50vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCounter: any;
  handleChangeCheckBox: any;

  //   updateDatatypeList:any;
}

const AddCostModelParameterModal = (props: AddParameterModalProps) => {
  const { isOpen, onCloseModal, setCounter, handleChangeCheckBox } = props;
  const { productId, categoryId, projectId } = useRouteParams<any>();
  // projectId/:projectname/:productId/:categoryId/:categoryname/:validationId/:datatype
  const classes = useStyles();
  const [loader, setLoader] = useState<any>(false);
  const [costModelNames, setCostModelNames] = React.useState<any>([]);
  const [tempselectedParameters, setTempSelectedParameters] =
    React.useState<any>([]);
  const [name, setName] = React.useState<any>(null);
  const [description, setDescription] = React.useState<any>(null);

  const handleChangeName = (e: any) => {
    setName(e.target.value);
  };

  React.useEffect(() => {
    API.get(
      "/api/db/parameter/",
      {
        project: projectId,
        module: 6,
        data_types: "BOM calculator",
        category: categoryId,
        top_vault: productId,
      },
      0
    )
      .then((res: any) => {
        setCostModelNames(res.data);
      })
      .catch((err: any) => {});
  }, []);

  const handleChangeDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const handleSubmit = () => {
    setLoader(true);
    API.post(
      `/xcpep/bom_calculator_config/`,
      {
        top_vault_id: productId,
        category_id: categoryId,
        parameter: name,
        name:costModelNames.find((item:any)=>item.id==name)?.name,
        description: description,
      },
      {},
      0
    )
      .then((res: any) => {
        setLoader(false);
        setName(null);
        ADMIN.toast.success("Cost Model Added Successfully");
        handleChangeCheckBox();
        setCounter((prev: any) => prev + 1);
        //   updateDatatypeList()
      })
      .catch((err: any) => {
        setLoader(false);
        console.log(err, "errerr");
        ADMIN.toast.error("Something Went Wrong");
      });
    onCloseModal();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAddCostModal}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Add Cost Model
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            {/* <FormControl fullWidth>
              <TextField
                value={name}
                onChange={(e: any) => handleChangeName(e)}
                variant="standard"
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: '#007fff'
                  }
                }}
                label="Name"
                placeholder="Name"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  marginBottom: "1rem"
                }}
              />
            </FormControl> */}
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" style={{ color: '#007fff', marginLeft: '-0.8rem' }}>
                  Select Cost Model Name <span style={{  }}>*</span>
                </InputLabel>
              <Select
                value={name}
                onChange={(e: any) => handleChangeName(e)}
                variant="standard"
                required
                label="Name"
                placeholder="Name"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  marginBottom: "1rem",
                }}
              >
                {costModelNames &&
                  costModelNames?.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{ fontSize: "1rem" }}
                        value={item.id}
                        key={index + 1}
                      >
                        {item?.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                value={description}
                onChange={(e: any) => handleChangeDescription(e)}
                variant="standard"
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "#007fff",
                  },
                }}
                label="Description"
                placeholder="Description"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <LoadingButton
                disabled={
                  name == null ||
                  description == null ||
                  name == "" ||
                  description == ""
                }
                loading={loader}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Add
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default AddCostModelParameterModal;
