import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from "./CostingConfigurationLanding.module.scss";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import guidelines from '../../Assets/images/guidelines.svg';
import calculator from '../../Assets/images/calculator.svg';
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import ReactDOMServer from 'react-dom/server';
import ShareCostingModel from './ShareCositngModel';
import WatermarkModel from './WatermarkModel';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import AssumptionCard from './AssumptionCard';
import validatedGuidelinesImg from "../../Assets/images/guidelines_green.svg";
import validatedCalculatorImg from "../../Assets/images/calculator_green.svg";
import CostingConfigurationDrawer from './CostingConfigurationDrawer';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';


type Anchor = 'top' | 'left' | 'bottom' | 'right';


export interface ICostingConfigurationAssumptionCardProps {
}

function CostingConfigurationAssumptionCard(props: ICostingConfigurationAssumptionCardProps) {
    const history = useHistory();
    useDocumentTitle( "Costing Config" );
    const { productId, tab} = useRouteParams<any>();
    console.log(tab)
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [products, setProducts] = useState<any>([])
    const editor = useRef(null);
    const AssumptionCardModal = useBaseModal();
    //@ts-ignore
    const otherValidation = JSON.parse(sessionStorage.getItem("otherValidation"))
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const { height, width } = useWindowDimensions();
    const { url } = useRouteMatch();
    const { projectId, projectname } = useRouteParams<any>();
    const getProducts = () => {
        API.get("xcpep/top_vault/", {
            project: projectId,
            module: 16,
            module_selected: true
        }, 0).then((res: any) => {
            setProducts(res.data)
            if (productId == 0 && res.data?.length > 0) {
                history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/${res?.data[0]?.id}/${tab}/true/true`)
                
            }
        })
    }
    const getAllResult = () => {
        API.get("/config/share_costing_config/", {
          project: projectId,
          costing_config: true
        }, 0).then((res: any) => {
          sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]))
        }).catch((err: any) => { })
      }
    useEffect(() => {
        getAllResult()
        getProducts() }, [])
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >            
            <CostingConfigurationDrawer />
        </Box>
    );

    const handleClick = () => {
        history.goBack();
    };

    const comapnionHandle = () => {
        API.get("/web_companion/comapanion_activity_log", {
            //   get_recycle_bin_data_count: true
            project: projectId, viewed: true
        }, 0).then((res: any) => {
            // console.log(res?.data.count, "countdata")
        }).catch((err: any) => {
            // console.log("Server error")
            //   SetLoader(false)
        })
        history.push(`/companionConfig/${projectId}/${projectname}`);
    };

    const costingModel = () => {
        history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
    };
    const commodityHandle = () => {
        history.push(`/costingconfiguration/${projectId}/${projectname}/0/commodity`);
    };
    const guideHandler = () => {
        history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines`);
    };
    const assumptionHandler = () => {
        
        history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/${tab}/true/true`);
    };

    const shareModal = useBaseModal();
    const WatermrkModel = useBaseModal();

    return (
        <div>
            {shareModal.isOpen && <ShareCostingModel
                isOpen={shareModal.isOpen}
                onCloseModal={shareModal.close}
            />}
            {WatermrkModel.isOpen && <WatermarkModel
                isWMOpen={WatermrkModel.isOpen}
                onCloseModal={WatermrkModel.close}
            />}
            <Box sx={{ margin: '0 0.5rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                        <IconButton sx={{ cursor: "pointer", }} title='Go Back' onClick={handleClick}>
                            <ArrowBackIcon color="primary" titleAccess="Go Back" />
                        </IconButton>
                        <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', fontWeight: '500' }}>{projectname} Costing Configuration</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                       {otherValidation?.guidline_config?<img
                            title="Guide lines"
                            src={validatedGuidelinesImg}
                            style={{ height: "1.65rem", width: "auto", cursor: 'pointer' }}
                            onClick={() => guideHandler()}
                        /> :<img
                            title="Guide lines"
                            src={guidelines}
                            style={{ height: "1.65rem", width: "auto", cursor: 'pointer' }}
                            onClick={() => guideHandler()}
                        />}
                        {otherValidation?.calculator_config?<img
                            title="Calulator"
                            src={validatedCalculatorImg}
                            style={{ height: "1.65rem", width: "auto", cursor: 'pointer' }}
                            onClick={() => costingModel()}
                        /> :<img
                            title="Calulator"
                            src={calculator}
                            style={{ height: "1.65rem", width: "auto", cursor: 'pointer' }}
                            onClick={() => costingModel()}
                        />}
                        {/* <CreditCardIcon sx={{ fontSize: '2.5rem', cursor: 'pointer' }}
                            titleAccess='Assumption Card'
                            // onClick={() => AssumptionCardModal.open()} 
                            onClick={() => assumptionHandler()}
                        /> */}
                        <ShareIcon sx={{ cursor: 'pointer'}} titleAccess='Share' onClick={() => shareModal.open()} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box className={styles.expandIconBox}>
                    <div className={styles.iconSec}>
                        {(["left"] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton
                                    size="medium"
                                    sx={{
                                        backgroundColor: "primary.light",
                                        color: "primary.main",
                                    }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                                </IconButton>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    className={styles.drawerContainer}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: "aliceblue",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{ padding: "1rem 0rem", paddingBottom: '0' }}
                                        adl-scrollbar="true"
                                        adl-scrollbar-width="0.3"
                                    >
                                        <Typography style={{ width: "100%", textAlign: "center", fontSize: '1rem', }}>
                                            Costing Configuration
                                        </Typography>
                                        <Divider sx={{ margin: "0.5rem" }} />
                                        {list(anchor)}
                                    </Box>
                                </Drawer>
                                <Typography
                                    style={{
                                        transform: "rotate(270deg)",
                                        whiteSpace: "nowrap",
                                        marginTop: "40vh",
                                        cursor: "pointer",
                                        fontSize: '1rem'
                                    }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    Costing Configuration
                                </Typography>
                            </React.Fragment>
                        ))}
                    </div>
                </Box>
                <Box sx={{ width: '100%', height: '89vh' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <AssumptionCard products={products} />
                </Box>
            </Box>
        </div>
    );
}
export default CostingConfigurationAssumptionCard;
