import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

import swal from "sweetalert";
import styles from "./BomConfigurationLanding.module.scss";

import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";

import { API } from "../../api-services";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";

import BomConfiguration from "./BomConfiguration";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { AddBomConfigurationModal } from "./AddBomConfigurationModal";
import WorkStationImage from "../../Assets/images/workstationImg.png";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { TableChart } from "@mui/icons-material";
import { WorkstationRenderCell } from "./WorkstationRenderCell";
import ShareIcon from '@mui/icons-material/Share';
import BomConfigurationShareModal from "./BomConfigurationShareModal";


type Anchor = "top" | "left" | "bottom" | "right";

export interface IRevisionAndProcessProps { }

export function WorkStationsPage() {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();

  const [TableDataList, setTableDataList] = useState<any>([]);
  const [TableLoader, setTableLoader] = useState<any>(false);
  const [CategoryList, setCategoryList] = useState<any>([]);
  const history = useHistory();
  const shareModal: any = useBaseModal();
  const [Parameterdata, setParameterdata] = useState<any>([])
  const [premissionData, setPremissionData] = useState<any>(null)
  const [ItemList, setItemList] = useState<any>(null)
  const [CategoryCount, setCategoryCount] = useState<any>(0);
  const [ParameterCount, setParameterCount] = useState<any>(0);



  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module:"BOM Configuration"
      },
      0
    )
      .then((res: any) => {
        setPremissionData(res.data.action);
      })
      .catch((err: any) => { });
  }, [projectId]);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"


    >
      <CostingConfigurationDrawer />
    </Box>
  );




  useEffect(() => {
    setTableLoader(true)
    API.get("/config/workstation/", { top_vault: productId, module: 6 }).then((res: any) => {
      setTableDataList(res?.data)
      setTableLoader(false)
    }).catch((err: any) => {
      console.log("Server Error")
      setTableLoader(false)
    })
  }, [productId, CategoryCount])
  useEffect(() => {

    API.get("/api/db/category/", { top_vault: productId, module: 6 }).then((res: any) => {
      setCategoryList(res?.data)

    }).catch((err: any) => {
      console.log("Server Error")

    })
  }, [productId])


  const goBackHandler = () => {
    history.goBack()
  };
  const ShareSwalAlertHandler = useCallback((type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> Shared successfully
  </p>   
   </div>`,
      });
    } else if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
  <br />
  <p style="color:red;">Server Error
  </p>   
   </div>`,
      });
    }
  }, []);
  const handleShare = () => {


    shareModal.open()
  }
  return (
    <div>
      <Box sx={{ margin: "0 0.5rem" }}>
        {shareModal.isOpen &&
          <BomConfigurationShareModal
            isOpen={shareModal.isOpen}
            onCloseModal={shareModal.close}
            ShareSwalAlertHandler={ShareSwalAlertHandler}
            type={"workstations"}
            nameHeader={ItemList}
          />}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: { lg: "23vw", xl: "21vw" },
            }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={() => goBackHandler()}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}>
              {projectname} [ {TableDataList[0]?.top_vault_name} (Work-Stations) ]
            </Typography>
          </Box>
          <Box sx={{paddingRight:'1rem'}}>
            {premissionData && premissionData?.includes("c") ?
              <ShareIcon  sx={{ opacity: '0.3' }} /> :
              <ShareIcon titleAccess="Share" onClick={() => handleShare()} sx={{ cursor: "pointer", opacity: 1 }} />}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}></Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}></Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            // marginLeft: "1rem"
          }}>

          <Box
            sx={{
              height: { lg: "86vh", xl: "89vh" },
              width: "100%",
              paddingRight: "1rem",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {TableLoader ?
              <Box sx={{padding:'0 1rem'}}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
              :
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white", width: '8rem', whiteSpace: 'nowrap' }}> Work Station </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white", width: '42%' }}> Bom Categories</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white", width: '47%' }}>Parameters</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white", width: '6rem', textAlign: 'center' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableDataList && TableDataList?.map((item: any, index: any) => {
                    return (<>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.name}</TableCell>
                        <WorkstationRenderCell
                          premissionData={premissionData}
                          setCategoryCount={setCategoryCount}
                          CategoryList={CategoryList} rowItem={item} />
                      </TableRow>
                    </>)
                  })}
                </TableBody>
              </Table>
            }
          </Box>
        </Box>
      </Box>
    </div>
  );
}