
// -------------------------------------------------------------------------------------

import { API } from "../../api-services";
import { GetInputCalculationsArg } from "../Actions/inputCalculation.actions";

const url = "/cost/calculation_input/";

// -------------------------------------------------------------------------------------

export default {
    get ( arg: GetInputCalculationsArg ) {
        return API.get( url, {
            id: arg.userInputId
        },0 );
    },
    update ( arg: any ) {
        return API.get( url, {
            id: arg?.id,
            procure: arg?.updates?.procure
        },0 );
    },
};