import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import styles from "./CostingCalculatorSection.module.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RawMaterialCard from "./RawMaterialCard/RawMaterialCard";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import CalculatorCard from "./CalculatorCard/CalculatorCard";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";

import {
  createRawMaterialCardCosts,
  getRawMaterialCardCosts,
  resetRawMaterialCard,
  updateRawMaterialCardCost,
} from "../../../Redux/Actions/rawMaterialCard.actions";
import ProcessCard from "./RawMaterialCard/ProcessCard";
import OverheadCard from "./RawMaterialCard/OverheadCard";
import { API } from "../../../api-services";
export interface ICostigngCalculatorSectionProps {
  passingObj?: any;
}

export const userPermission = React.createContext<any>(null);

export function CostigngCalculatorSection(
  props: ICostigngCalculatorSectionProps
) {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    condition,
    calctype,
    calcultorId,
    scenarioId
  } = useRouteParams<any>();
  const { passingObj } = props;
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const [PermissionData, setPermissionData] = React.useState<any>(null);
  // const handleClickCopy = () => {
  //   console.log("copy");
  // };
  const costsFetched = useQueryDispatch({
    // @ts-ignore
    query: { action: getRawMaterialCardCosts, arg: { costEstimationId,tableId:tableId } },
    cleanup: { action: resetRawMaterialCard, args: [] },
    
    dependency: [tableId, vault],
  });

  React.useEffect(() => {
    API.get("/auth/calibration_user_permission/", {
      action: true,
      costing_create: true,
      vault: vault,
      scenario: scenarioId?scenarioId:undefined
    },0)
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);

  const getComponent = () => {

    switch (calctype) {
      case 1:
        return <RawMaterialCard />;
      case 2:
        return <RawMaterialCard />;
      case 3:
        return <ProcessCard />;
      case 4:
        return <ProcessCard />;
      case 5:
        return <ProcessCard />;
      case 6:
        return <ProcessCard />;

      default:
        return <OverheadCard />;
    }
  };
  {
    /* <RawMaterialCard />
                        <ProcessCard/> */
  }
  return (
    <div>
      <Box>
        {costsFetched?.match("FETCHING") ? (
          <Skeleton
            variant="rectangular"
            height="89vh"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        ) : (
          <>
            <Box>
              {costsFetched?.match("FETCHING") ? (
                <Skeleton
                  variant="rectangular"
                  height="89vh"
                  width="100%"
                  sx={{ marginBottom: "1rem" }}
                />
              ) : (
                <>
                  <Box>
                    {tableId && (
                      <userPermission.Provider value={PermissionData}>
                        {getComponent()}
                      </userPermission.Provider>
                    )}
                  </Box>
                  <Box className={styles.calculatorTabSection}>
                    <userPermission.Provider value={PermissionData}>
                      <CalculatorCard passingObj={passingObj} />
                    </userPermission.Provider>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </div>
  );
}
