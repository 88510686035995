import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import InfoIcon from "@mui/icons-material/Info";
import styles from "./FeatureNamesLibraryLandingPage.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isNull } from 'lodash';
import { string } from 'yup';
import { Box } from '@mui/material';
import { FeatureInfoModal } from './FeatureInfoModal';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';


interface SystemNameTabProps {
  SystemData: any;
  SubsystemHanlder: any
  subystemEditHanlder: any;
  deleteHanlder: any;
  ParentsCounter: any;
}

const FeatureNameTab = (props: SystemNameTabProps) => {
  const { categoryId, subsystemId, search } = useParams<any>();
  const {
    SystemData,
    SubsystemHanlder,
    subystemEditHanlder,
    deleteHanlder, ParentsCounter
  } = props
  const infoModal = useBaseModal();
  const [SystemDataList, setSystemDataList] = useState<any>(SystemData && SystemData)
  const [SelectedId, setSelectedId] = useState<any>([])
  useEffect(() => {
    {
      setSystemDataList(SystemData && SystemData)
    }
  }, [SystemData, ParentsCounter]);
  useEffect(() => {
    if (subsystemId && subsystemId != null && subsystemId != 0) {
      let element: any = !isNull(subsystemId) && document.getElementById(String(subsystemId));

      let b: any = element && element.scrollIntoView(true);
    }

  }, []);

  const InfoHandler = (Id: any) => {
    infoModal?.open()
    setSelectedId(Id)
  }
  return (
    <>

      {/* {infoModal.isOpen &&
        <FeatureInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={SelectedId && SelectedId}
        />
      } */}
      <Box sx={{ width: "20%", height: { lg: '80vh', xl: '86vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {SystemDataList &&
          SystemDataList?.map((item: any, index: any) => {
            return (
              <div
                id={item?.id}
                key={index}
                className={styles.Data_card}
                onClick={() => SubsystemHanlder(item?.id)}>
                <div
                  className={
                    (search == 0 || search == "0") &&
                      subsystemId == item?.id
                      ? styles.active_box_container
                      : styles.box_container
                  }>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontSize: "1rem",
                    }}>
                    <span>
                      {item?.name} ({item?.abbreviation})
                    </span>
                    <span> Feature names ({item?.feature_count})</span>
                  </div>
                  <div
                    style={{
                      display: "flex",                      
                      flexDirection: "column",
                      justifyContent: 'space-between',
                    }}>
                    {/* {sessionStorage.getItem("DBPermission")?.includes("U") &&<EditIcon
                      titleAccess="Edit"
                      onClick={() => subystemEditHanlder(item)}
                    />}
                    {item?.mapped_status ? (
                      <InfoIcon onClick={() => InfoHandler(item?.id)} />
                    ) : (
                      sessionStorage.getItem("DBPermission")?.includes("D") &&<DeleteIcon
                        titleAccess="Delete"
                        onClick={() => deleteHanlder(item?.id)}
                      />
                    )} */}
                  </div>
                  <div></div>
                </div>
              </div>

            );
          })}
      </Box>
    </>
  )
}

export default FeatureNameTab;