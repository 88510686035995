import {
	Box,
	Button,
	ButtonProps,
	Checkbox,
	FormControlLabel,
	IconButton,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import { API } from '../../../../api-services';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';
import useWindowDimensions from '../../../../ui-reusable-component/useWindowDimensions';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';
import { ADMIN } from '../../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import Plot from 'react-plotly.js';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';

interface ICommodityWiseAnalysisPageProps {}

interface Params {
	projectId: any;
	projectName: any;
	initialTopVault:any;
	topVaultIds:any;
	expandTable:any;
	abbr:any;
	partId:any;
	depth:any;
	vault_id:any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const csvOptions: GridCsvExportOptions = {
	fileName: 'Outlier Data',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '2rem' }}>
		<span
			style={{
				position: 'absolute',
				right: '0rem',
				fontSize: '1rem',
				paddingRight: '1rem',
			}}>
			<GridToolbarColumnsButton />
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);

interface TrendlineData {
	part_no: string;
	costing_data: number;
	name: string;
	product_id: string;
}

//   const getColor = (productId: string) => {
// 	const colors: Record<string, string> = {
// 	 'red',
// 	'blue',
// 	  // Add more colors for different product IDs if needed
// 	};
// 	return colors[productId] || 'black';
//   };

const colors = ['green', 'blue', 'orange', 'purple'];
const colorMap = new Map();

const getColor = (productName: any) => {
	if (!colorMap.has(productName)) {
		// Generate a new color if it doesn't exist
		const color = `hsl(${Math.floor(Math.random() * 360)}, 100%, 50%)`;
		colorMap.set(productName, color);
	}
	return colorMap.get(productName);
};

export default function CommodityWiseAnalysisPage(
	props: ICommodityWiseAnalysisPageProps
) {
	const { projectId, projectName,initialTopVault,
		topVaultIds,
		expandTable,
		abbr,
		partId,
		depth,
		vault_id,projectIdOld,projectNameOld } = useRouteParams<any>();
		console.log("alkfnlksafnkldsanfa",projectName);
		
	const history = useHistory();
	const [productList, setProductList] = React.useState<any>();
	const [selectedproduct, setSelectedProduct] = React.useState<
		string | string[]
	>([]);
	console.log("sdlkjfdlksjflkds",projectIdOld,projectNameOld)
	// const [selectedproduct, setSelectedProduct] = React.useState<any>([]);
	const [BOMCategories, setBOMCategories] = React.useState<any>();
	const [selectedBOMCategory, setSelectedBOMCategory] = React.useState<any>();
	const [selectedCostType, setSelectedCostType] = React.useState<any>();
	// const [selectedPartType, setSelectedPartType] = React.useState<any>();
	const [anlysedgraph, setAnlysedgraph] = useState<any>();
	const [isChecked, setIsChecked] = useState(false);
	// console.log("isCheckedisCheckedisChecked",isChecked, anlysedgraph);
	const [preloadCombinationData, setPreloadCombinationData] =
		React.useState<any>();
	// const [trendlineData, setTrendlineData] = React.useState<any>();
	const [trendlineData, setTrendlineData] = useState<TrendlineData[]>([]);
	const [prodcuctColor, setProdcuctColor] = useState<any>();
	const [plotData, setPlotData] = useState<any[]>([]);
	const [trendCombinationData, setTrendCombinationData] = React.useState<any>();
	const [combinationId, setCombinationId] = React.useState<any>();
	const [outlierData, setOutlierData] = React.useState<any>();
	const [outlierCombinationData, setOutlierCombinationData] =
		React.useState<any>();
	const [combinationLoader, setCombinationLoader] =
		React.useState<boolean>(false);
	const [outlierLoader, setOutlierLoader] = React.useState<boolean>(false);
	const [trendlineLoader, setTrendlineLoader] = React.useState<boolean>(false);
	const [activeCominationId, setActiveCominationId] = React.useState<any>();
	const [averageCost, setAverageCost] = useState<any>();
	const [generatedGraph, setGeneratedGraph] = useState<any>();
	const handleProductChange = (
		event: SelectChangeEvent<typeof selectedproduct>
	) => {
		const { value } = event.target;
		setSelectedProduct(value as string | string[]);
	};
// console.log("plotDataplotDataplotData",plotData);

	//
	// const handleProductChange = (event: any, newSystemValue: any) => {
	// 	setSelectedProduct(newSystemValue);
	// 	// let newSelectedSystem = newSystemValue.map((item: any) => item.id);
	// 	// ExactDebounceCommodity(newSelectedSystem);
	//   };

	//   const getOptionDisabled = (option: any) => {
	// 	return selectedproduct.some(
	// 	  (selectedOption: any) =>
	// 		selectedOption.id === option.id
	// 	);
	//   };

	// const filteredProductList = productList.filter((item:any) => !selectedproduct.includes(item.id));

	// Popover
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const [hoveredItemId, setHoveredItemId] = useState<null | number>(null);

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLElement>,
		id: number
	) => {
		setAnchorEl(event.currentTarget);
		setHoveredItemId(id);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setHoveredItemId(null);
	};
	const open = Boolean(anchorEl);

	// All Combination Data
	const getCombinationData = () => {
		setCombinationLoader(true);
		API.get(
			`cost/get_trendline_combinations/`,
			{
				project: projectId,
			},
			0
		)
			.then((res: AxiosResponse) => {
				setTrendCombinationData(res?.data);				
				setCombinationLoader(false);
				if (res?.data.length > 0) {
					const firstCombinationId = res?.data[0]?.id;
					selctedCombination(res?.data[0]?.id);
					history.push(`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${firstCombinationId}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}/${projectIdOld}/${projectNameOld}`);
				}
			})
			.catch((err: AxiosError) => {
				console.log(err, 'error');
				setCombinationLoader(false);
			});
	};

	React.useEffect(() => {
		getCombinationData();
		setTrendlineData([]);
		setOutlierCombinationData([]);
		setPreloadCombinationData([]);
		setBOMCategories([]);
		setSelectedProduct([]);
		setSelectedBOMCategory('');
		setSelectedCostType('');
		// setSelectedPartType('');
	}, [projectId]);

	const selctedCombination = async (id: any) => {
		setActiveCominationId(id);
		try {
			setCombinationLoader(true);

			const res = await API.get(
				`cost/get_trendline_combinations/`,
				{
					load_combination: id, // Use the provided 'id' instead of 'combinationId'
				},
				0
			);
			// Use the selected data as needed
			setPreloadCombinationData(res?.data);
			setAverageCost(res?.data.cost_per_kg_mean);
			setProdcuctColor(res?.data.color_code);
			setCombinationLoader(false);
			setAnlysedgraph(res?.data.analysed);
			history.push(`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${id}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}/${projectIdOld}/${projectNameOld}`);
		} catch (err) {
			console.log(err, 'error');
			setCombinationLoader(false);
		}
	};

	React.useEffect(() => {
		if(anlysedgraph === true){
			setIsChecked(true);
		}else{
			setIsChecked(false);
		}
	}, [anlysedgraph, activeCominationId]);
	
	React.useEffect(() => {
		// if(Array.isArray(preloadCombinationData?.product_id)){
		// 	setSelectedProduct(productList?.filter((item:any)=>preloadCombinationData?.product_id?.includes(item?.id)))
		// }else{
		// 	setSelectedProduct([])
		// }

		setSelectedProduct(
			Array.isArray(preloadCombinationData?.product_id)
				? preloadCombinationData?.product_id
				: []
		);

		setSelectedBOMCategory(
			preloadCombinationData?.category_id !== null
				? String(preloadCombinationData?.category_id)
				: ''
		);
		setSelectedCostType(
			preloadCombinationData?.cost_type !== null
				? String(preloadCombinationData?.cost_type)
				: ''
		);
		setTrendlineData(preloadCombinationData?.trendline);
		setOutlierCombinationData(preloadCombinationData?.outliers);
	}, [preloadCombinationData]);

	// Generate Treandline Functions
	React.useEffect(() => {
		API.get(
			`xcpep/product_list_new/`,
			{ project: projectId, module: 'Costing-Create' },
			0
		)
			.then((res: AxiosResponse) => {
				setProductList(res?.data);
			})
			.catch((err: AxiosError) => {
				console.log(err, 'error');
			});
	}, [projectId]);

	React.useEffect(() => {
		if (selectedproduct.length > 0) {
			// Ensure there are selected products
			// if(Array.isArray(preloadCombinationData?.product_id)){
			// 	setSelectedProduct(productList?.filter((item:any)=>preloadCombinationData?.product_id?.includes(item?.id)))
			// }else{
			// 	setSelectedProduct([])
			// }
			const selectedProductsString = Array.isArray(selectedproduct)
				? selectedproduct.join(',')
				: selectedproduct; // Convert array to comma-separated string if it's an array

			API.get(
				`cost/cost_per_kg_trendline_graph/`,
				{ bom_category: true, product: selectedProductsString },
				0
			)
				.then((res: AxiosResponse) => {
					setBOMCategories(res?.data);
				})
				.catch((err: AxiosError) => {
					console.log(err, 'error');
				});
		}
	}, [selectedproduct]);

	const handleBOMCatChange = (
		event: SelectChangeEvent<typeof selectedBOMCategory>
	) => {
		setSelectedBOMCategory(event.target.value as string);
	};

	const handleTypeChange = (event: SelectChangeEvent) => {
		setSelectedCostType(event.target.value as string);
	};

	// const handleBOMTypeChange = (event: SelectChangeEvent) => {
	// 	setSelectedPartType(event.target.value as string);
	// };

	const handleGenerateGraph = () => {
		setTrendlineLoader(true);
		const selectedProductsString = Array.isArray(selectedproduct)
			? selectedproduct.join(',')
			: selectedproduct;
		API.get(
			`cost/cost_per_kg_trendline_graph/`,
			{
				project: projectId,
				trendline: true,
				product: selectedProductsString,
				category: selectedBOMCategory,
				costing_filter: selectedCostType,
				// system_type: selectedPartType,
			},
			0
		)
			.then((res: AxiosResponse) => {
				setGeneratedGraph(res?.data);
				setTrendlineData(res?.data.trendline);
				setCombinationId(res?.data.combination_id);
				setAverageCost(res?.data.cost_per_kg_mean);				
				setTrendlineLoader(false);
				getCombinationData();
				if (activeCominationId !== null) {	
					history.push(`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${activeCominationId}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}/${projectIdOld}/${projectNameOld}`);
					
				} else if (res?.data.length > 0) {
					const firstCombinationId = res?.data[0]?.id;
					selctedCombination(firstCombinationId);
					history.push(`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${firstCombinationId}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}/${projectIdOld}/${projectNameOld}`);

				}
				outlierDatahanlder();
			})
			.catch((err: AxiosError) => {
				console.log(err, 'error');
				setTrendlineLoader(false);
			});
	};

	// Ploty component
	// const { dataIncoming ,fullscreen} = props;
	const { height, width } = useWindowDimensions();

	useEffect(() => {
		const xData: any = [];
		const yData: any = [];
		const colors: any = [];
		const hoverText: any = [];

		trendlineData?.forEach((item: any) => {
			xData.push(item.part_no);
			yData.push(item.costing_data);
			colors.push(item.product_color);
			hoverText.push(
				`${item.name} - (${item.costing_data})`
			);
		});

		const trace = {
			x: xData,
			y: yData,
			type: 'scatter',
			mode: 'markers',
			marker: { color: colors },
			text: hoverText,
			hoverinfo: 'text',
		};

		setPlotData([trace]);
	}, [trendlineData]);

	const layout = {
		// title: 'Cost per Kg Analysis Graph',
		// legend: true,
		margin: {
			l: 40,
			r: 30,
			b: 150,
			t: 30,
		},
		xaxis: {
			//   title: 'Part Number',
			showgrid: true,
			zeroline: true,
			rangemode: 'tozero',
			type: 'category', // Ensures alphanumeric values are treated as categories
		},
		yaxis: {
			title: 'Cost per Kg (INR)',
			showgrid: true,
			zeroline: true,
			rangemode: 'tozero',
		},
		shapes:
			averageCost !== null && averageCost !== 0
				? [
						{
							title: 'Average Cost Per Kg',
							type: 'line',
							x0: 0,
							x1: 1,
							y0: averageCost,
							y1: averageCost,
							xref: 'paper',
							yref: 'y',
							line: {
								color: 'red',
								width: 1,
								dash: 'dashdot',
							},
						},
				  ]
				: [],
		legend: {
			x: 0.5,
			y: width < 1400 ? -0.7 : -0.5,
			orientation: 'h',
			xanchor: 'center',
			yanchor: 'top',
			font: {
				family: 'sans-serif',
				size: 12,
				color: '#000',
			},
			bgcolor: '#E2E2E2',
			bordercolor: '#FFFFFF',
			borderwidth: 2,
		},
		annotations: averageCost !== null && averageCost !== 0 ? [
			{
			  x: 1,
			  y: averageCost,
			  xref: 'paper',
			  yref: 'y',
			  text: `Average Cost: ${averageCost}`,
			  showarrow: false,
			  font: {
				color: 'red',
				size: 12
			  },
			  bgcolor: 'rgba(255, 255, 255, 0.8)',
			  bordercolor: 'red',
			  borderwidth: 1,
			  borderpad: 4,
			}
		  ] : [],
	};

	
	const outlierDatahanlder = () => {
		if(generatedGraph?.combination_id !== undefined) {
		setOutlierLoader(true);		
		const selectedProductsString = Array.isArray(selectedproduct)
			? selectedproduct.join(',')
			: selectedproduct;

		API.get(
			'cost/cost_per_kg_trendline_graph/',
			{
				outliers: true,
				combination_id: generatedGraph?.combination_id,
				project: projectId,
				product: selectedProductsString,
				category: selectedBOMCategory,
				costing_filter: selectedCostType,
			},
			0
		)
			.then((res: AxiosResponse) => {
				setOutlierCombinationData(res?.data);
				setOutlierLoader(false);
			})
			.catch((err: AxiosError) => {
				console.log(err, 'error');
				setOutlierLoader(false);
			});
		}
	};

	React.useEffect(() => {
		outlierDatahanlder();
	}, [generatedGraph?.combination_id]);

	const columns: GridColDef<(typeof rows)[number]>[] = [
		{
			field: 'part_no',
			headerName: 'Part No.',
			minWidth: 120,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<Typography
						title={`Go to Part - ${params.row.part_no}`} 
						variant='body2'
						sx={{ cursor: 'pointer' }}
						onClick={() => redirectToParthandler(params.row)}>
						{params.row.part_no}
					</Typography>
				);
			},
		},
		{
			field: 'value',
			headerName: 'Delta Value',
			width: 120,
			align: 'right',
			headerAlign: 'right',
		},
	];
	let rows: any = [];
	let dataGridDataGenerator: any =
		outlierCombinationData &&
		outlierCombinationData?.map((outlierItem: any, index: any) => {
			let objShared: any = {
				id: index + 1,
				pid:outlierItem.id,
				product_id: outlierItem.product_id,
				product_name: outlierItem.product_name,
				part_no: outlierItem.part_no,
				name: outlierItem.name,
				value: outlierItem.outlier_value,
				is_leaf: outlierItem.is_leaf,
				abbreviation: outlierItem.abbreviation,
				category: outlierItem.category,
			};
			rows.push(objShared);
		});
	let dataStructureDataGrid: any = {
		columns: columns,
		rows: rows,
	};

	const deleteCombination = (id: any) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You want to delete this Combination!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
		}).then((result) => {
			if (result.isConfirmed) {
				API.delete(`cost/get_trendline_combinations/${id}`, {}, 0)
					.then((res: AxiosResponse) => {
						getCombinationData();
						ADMIN.toast.success('Combination Deleted successfully.');
					})
					.catch((err: AxiosError) => {
						console.log(err, 'error');
					});
			}
		});
	};

	const productanalysisSummarised = (event: any) => {
		const newIsChecked = event.target.checked;
    	setIsChecked(newIsChecked);

		const selectedProductsString = Array.isArray(selectedproduct)
			? selectedproduct.join(',')
			: selectedproduct;
		API.put(
			`cost/product_analysis_summary/`,
			{
				analysed: newIsChecked,
				product: selectedProductsString,
				category: selectedBOMCategory,
				cost_type: selectedCostType,
				// system_type: selectedPartType,
			},
			0
		)
			.then((res: AxiosResponse) => {
				if (newIsChecked === true) {
					ADMIN.toast.success('Analysis Completed successfully.');
				} else {
					ADMIN.toast.error('Analysis Removed successfully.');
				}
			})
			.catch((err: AxiosError) => {
				console.log(err, 'error');
			});
	};

	// Sync Combination Data
	const syncCombinationData = async (combinationId: any) => {
		if (activeCominationId === null) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Please select combination to sync analysis!',
			});
		} else {
			setCombinationLoader(true);
			API.get(
				`cost/get_trendline_combinations/`,
				{
					sync: combinationId,
				},
				0
			)
				.then((res: AxiosResponse) => {
					getCombinationData();
					setCombinationLoader(false);
					ADMIN.toast.success('Combination Synced successfully.');
				})
				.catch((err: AxiosError) => {
					setCombinationLoader(false);
					console.log(err, 'error');
				});
		}
	};

	const redirectToParthandler = (item: any) => {		
		window.open(
			`/#/createcostingpart/${projectId}/${projectName}/${item.product_name}/${item.product_id}/${item.pid}/none/0/0/0/0/0/0/0/0`
		);
	};

	// console.log(prodcuctColor, 'prodcuctColor');
	return (
		<>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 2rem 4fr',
					columnGap: '1rem',
					padding: '0 1rem 0 1rem',
					marginBottom: '1rem',
					borderBottom: '1px solid',
					borderColor: 'primary.light',
				}}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						rowGap: '0.5rem',
						marginBottom: '1rem',
						width:'18vw',
					}}>
					<FormControl size='small' fullWidth variant='standard'>
						<InputLabel
							shrink
							sx={{ color: 'primary.main' }}
							id='demo-simple-select-label'>
							Select Product
						</InputLabel>
						<Select
							labelId='demo-multiple-name-label'
							id='demo-multiple-name'
							multiple
							value={selectedproduct || []}
							label='Select Product'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={handleProductChange}>
							{productList?.map((item: any, index: any) => (
								<MenuItem key={index} value={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl size='small' fullWidth variant='standard'>
						{/* <Autocomplete
						multiple
						options={productList}
						disableCloseOnSelect
						getOptionLabel={(option:any) => option?.name}
						renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
							icon={<CheckBoxOutlineBlank fontSize="small" />}
							checkedIcon={<CheckBox fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
							/>
							{option?.name}
						</li>
						)}
						renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip label={option.name} {...getTagProps({ index })} />
						))
						}
						value={selectedproduct}
						onChange={(event, newValue) => {
						setSelectedProduct(newValue);
						}}
						filterSelectedOptions
						renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label="Select Options"
							placeholder="Search and select"
						/>
						)}
					/> */}
					</FormControl>

					<FormControl size='small' fullWidth variant='standard'>
						<InputLabel
							shrink
							sx={{ color: 'primary.main' }}
							id='demo-simple-select-label'>
							BOM Categories
						</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={selectedBOMCategory || ''}
							label='Age'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={handleBOMCatChange}>
							{BOMCategories?.map((item: any, index: any) => (
								<MenuItem key={index} value={item.category__id}>
									{item.category__name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size='small' fullWidth variant='standard'>
						<InputLabel
							shrink
							sx={{ color: 'primary.main' }}
							id='demo-simple-select-label'>
							Select Cost Type
						</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={selectedCostType || ''}
							label='Select Type'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={handleTypeChange}>
							<MenuItem value={'DC'}>DC</MenuItem>
							<MenuItem value={'BOP'}>BOP</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'end', padding: '1rem 0' }}>
					<IconButton
						title='Generate Graph'
						onClick={handleGenerateGraph}
						size='small'>
						<AddchartRoundedIcon color='primary' />
					</IconButton>
				</Box>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '0 1rem 0 0',
							backgroundColor: 'primary.light',
						}}>
						<Typography
							sx={{
								padding: '0.2rem 0.5rem',
								borderBottomColor: 'primary.light',
							}}>
							Combination List
						</Typography>
						<Box></Box>
					</Box>
					{combinationLoader ? (
						<Box sx={{ marginTop: '0.5rem' }}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
								<Skeleton
									variant='rectangular'
									sx={{ width: '100%', height: '1.5rem' }}
								/>
							</Box>
						</Box>
					) : (
						<Box
							sx={{ height: { lg: '10rem', xl: '9rem' }, width: '100%' }}
							adl-scrollbar='true'
							adl-scrollbar-width='0.3'>
							{trendCombinationData && trendCombinationData.length > 0 ? (
								<Box
									sx={{
										display: 'grid',
										gridTemplateColumns: {
											lg: '1fr 1fr 1fr 1fr 1fr',
											xl: '1fr 1fr 1fr 1fr 1fr 1fr',
										},
										alignItems: 'flex-start',
										gap: '0.5rem',
										padding: '0.5rem',
									}}>
									{trendCombinationData &&
										trendCombinationData?.map((item: any, index: any) => (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													columnGap: '0.5rem 0',
													whiteSpace: 'nowrap',
													backgroundColor:
														activeCominationId == item.id
															? 'primary.main'
															: '#b5f3da',
													color:
														activeCominationId == item.id
															? 'white'
															: 'primary.main',
													borderRadius: '5px',
												}}
												key={index}>
												<IconButton
													onClick={() => selctedCombination(item.id)}
													sx={{
														padding: '3px',
														color:
															activeCominationId == item.id
																? 'white'
																: 'primary.main',
													}}
													aria-owns={
														open && hoveredItemId === item.id
															? 'mouse-over-popover'
															: undefined
													}
													aria-haspopup='true'
													onMouseEnter={(event) =>
														handlePopoverOpen(event, item.id)
													}
													onMouseLeave={handlePopoverClose}>
													<InfoIcon />
												</IconButton>
												<Popover
													id='mouse-over-popover'
													sx={{
														pointerEvents: 'none',
													}}
													elevation={1}
													open={open && hoveredItemId === item.id}
													anchorEl={anchorEl}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'left',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'left',
													}}
													onClose={handlePopoverClose}
													disableRestoreFocus>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															rowGap: '0.2rem',
															justifyContent: 'flex-start',
															padding: '0.5rem',
														}}>
														{item.product.join(', ')}
													</Box>
												</Popover>
												<Typography title={`${item.category} - ${item.cost_type}`}
													onClick={() => selctedCombination(item.id)}
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
														cursor: 'pointer',
														width: '12rem',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
														whiteSpace: 'nowrap',
													}}>
													{item.category} - {item.cost_type}
												</Typography>
												<IconButton
													title='Delete Combination'
													sx={{ padding: '3px', color: 'primary.main' }}
													onClick={() => deleteCombination(item.id)}>
													<CloseIcon sx={{ color: 'red' }} />
												</IconButton>
											</Box>
										))}
								</Box>
							) : (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
										height: { lg: '9rem', xl: '9rem' },
										borderRight: '1px solid',
										borderLeft: '1px solid',
										borderColor: 'primary.light',
									}}>
									Please Create Analysis
								</Box>
							)}
						</Box>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					columnGap: '0.5rem',
					width: '100%',
					padding: {lg:'0 0.5rem 0 2rem', xl:'0 1rem 0 2rem'},
				}}>
				<Box sx={{ width: '74vw', height: { lg: '60vh', xl: '71vh' } }}>
					{trendlineLoader ? (
						<Box sx={{ width: '100%', height: '60vh' }}>
							<Skeleton variant='rectangular' sx={{ height: '100%' }} />
						</Box>
					) : (
						<Box
							sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
							
							{!combinationLoader ? (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
										height: '100%',
									}}>
										<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											zIndex:1,
											marginBottom:'-1rem',
										}}>
										{(plotData && plotData?.length>0 && plotData[0]?.text?.length>0) && (prodcuctColor && Object.keys(prodcuctColor)?.length>0) && Object.keys(prodcuctColor)?.map((item:any)=>{
											return <Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												columnGap: '0.5rem',
												padding:'0 1rem',
												backgroundColor: '#e5e5e5',
											}}>									
											<Box
												sx={{
													width: '0.75rem',
													height: '0.75rem',
													borderRadius: '50%',
													backgroundColor: prodcuctColor[item],
												}}></Box>
											<Typography sx={{ fontSize: '1rem', color: prodcuctColor[item] }}>												
												{item}
											</Typography>
										</Box>
										})}
									</Box>
										{/* @ts-ignore */}
									{(plotData && plotData?.length>0 && plotData[0]?.text?.length>0) ?<Plot style={{ width: '72vw', height: width < 1400 ? '57vh' : '69vh' }} data={plotData} layout={layout}
										config={{
											responsive: true,
											modeBarButtonsToRemove: [
												'zoom2d',
												'pan2d',
												'select2d',
												'lasso2d',
												'zoomIn2d',
												'zoomOut2d',
												'autoScale2d',
												'resetScale2d',
												'hoverClosestGl2d',
												'hoverClosestPie',
												'toggleHover',
												'resetViews',
												'sendDataToCloud',
												// @ts-ignore
												'resetViewMapbox',
												'hoverClosestCartesian',
												'hoverCompareCartesian',
												'toggleSpikelines',
												'toImage',
											],
											displaylogo: false,
										}}
									/>:<Box
									sx={{
										width: '72vw',
										height: '60vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									Please Generate Graph or Select Combination
								</Box>}
									
								</Box>
							) : (
								<Box
									sx={{
										width: '72vw',
										height: '60vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									Please Generate Graph or Select Combination
								</Box>
							)}
							<IconButton
								title='Sync Graph'
								sx={{ padding: '3px' }}
								onClick={() => syncCombinationData(activeCominationId)}>
								<SyncIcon sx={{ color: 'primary.main' }} />
							</IconButton>
						</Box>
					)}
				</Box>
				<Box sx={{width:'100%', borderLeft: '1px solid', borderColor: 'primary.light' }}>
					<Typography
						sx={{
							textAlign: 'center',
							padding: '0.2rem',
							backgroundColor: 'primary.main',
							color: 'white',
							borderBottom: '1px solid',
							borderColor: 'primary.light',
						}}>
						Outliers (High to Low)
					</Typography>
					{outlierLoader ? (
						<Box>
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
							<Skeleton variant='rectangular' sx={{ height: '1.5rem' }} />
						</Box>
					) : (
						<>
							<Box
								sx={{
									height: { lg: '50vh', xl: '60vh' },
									borderBottom: '1px solid',
									borderColor: 'primary.light',
								}}
								adl-scrollbar='true'
								adl-scrollbar-width='0.3'>
								{outlierCombinationData ? (
									<>
										<Box
											sx={{
												height: { lg: '49vh', xl: '59vh' },
												width: '100%',
											}}>
											<DataGrid
												headerHeight={42}
												rowHeight={38}
												{...dataStructureDataGrid}
												// loading={loadAllLogs}
												disableSelectionOnClick={true}
												components={{
													Toolbar: () => {
														return CustomToolbar();
													},
												}}
												sx={{
													// class="MuiDataGrid-virtualScroller css-1w5m2wr-MuiDataGrid-virtualScroller"
													'& .MuiDataGrid-virtualScroller-thumb': {
														// background: $thumb-color;
														backgroundImage:
															'linear-gradient(#09C6F9, #045DE9)',
														borderRadius: '10px',
													},
													'& .MuiDataGrid-virtualScroller-thumb: hover ': {
														// width: "4px",
														height: '3px',
														backgroundColor: '#045DE9 !important',
													},
													'.MuiDataGrid-virtualScroller-track': {
														backgroundColor: '#e1e1f3 !important',
													},
													'&.MuiDataGrid-root': {
														border: 'none',
													},
												}}
												density={'compact'}
												pageSize={100}
												rowsPerPageOptions={[100]}
											/>
										</Box>
									</>
								) : (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											height: '100%',
										}}>
										{' '}
										Please select Combinations{' '}
									</Box>
								)}
							</Box>
							<Box sx={{ padding: '0 1rem' }}>
								{!combinationLoader && plotData && (
									<FormControlLabel
										checked={isChecked}
										onChange={productanalysisSummarised}
										control={<Checkbox />}
										label='Analysis Completed'
									/>
								)}
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	);
}
