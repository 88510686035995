import { Box, Skeleton } from "@mui/material";
import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BOMTreeViewCreate from "./BOMTreeViewCreate";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import styles from "./BOMEntryTreeSection.module.scss";
import { debounce } from "lodash";
import { userPermissionAction } from "./BOMEntryLandingPage";
import SyncIcon from '@mui/icons-material/Sync';

export interface IBOMEntryTreeSectionProps {
  setrecyclebinCounter: any;
  setParentsCounter: any;
  ParentsCounter: any;
  syncColor: any;
  setSyncColor: any;
}

export default function BOMEntryTreeSection(props: IBOMEntryTreeSectionProps) {

  const history = useHistory();
  const { setrecyclebinCounter, setParentsCounter, ParentsCounter, syncColor, setSyncColor } = props
  const descRef = React.useRef<any>();
  const { projectId, projectName, topVault, workstation, treeabbr, copyStatus } = useRouteParams<any>();
  const [HierarchyData, setHierarchyData] = React.useState<any>([]);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);
  const [counterApiCall, setCounterApiCall] = React.useState<number>(0);
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [useraction, setUseraction] = React.useState<any>("CRUD");

  const OuterRef = React.useRef<any>();
  const userAction = React.useContext(userPermissionAction)

  const [selectedValue, setSelectedValue] = React.useState<any>(null)
  const [SearchValue, setSearchValue] = React.useState<any>([])
  const inputRef = React.useRef<any>(null);

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);

    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit, topVault);
    }



  };


  const FocusHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    inputRef.current.style.display = "block";
    var DataSplit = selectedValue?.split("(")?.shift();
    if (DataSplit?.length > 0) {
      ExactDebounce(DataSplit, topVault);
    }
  };

  const ExactDebounce = React.useRef(

    debounce(async (criteria: any, tv: any) => {

      await API.get("/xcpep/vault/search/", { search: criteria, top_vault: tv }).then((res: any) => {
        setSearchValue(res.data);
      });
    }, 500)
  ).current;
  const getHierarchyData = () => {
    setIsDataLoading(true);
    API.get("/xcpep/bom_create_hirearchy/", { top_vault: topVault }, 0).then((res: any) => {
      setIsDataLoading(false);
      setHierarchyData(res.data.data);
    }).catch((err: any) => {
      setIsDataLoading(false);
    })
  }
  const getHierarchyDataReload = () => {
    setIsDataLoading(true);
    API.get("/xcpep/bom_create_hirearchy/", { top_vault: topVault, reload: true }, 0).then((res: any) => {
      setIsDataLoading(false);
      setHierarchyData(res.data.data);
      setSyncColor("");
    }).catch((err: any) => {
      setIsDataLoading(false);
    })
  }


  const handleClickOutside = (event: any) => {
    // if (!isUndefined(projectCount) && projectCount > 0)

    {
      if (OuterRef?.current && !OuterRef.current.contains(event?.target)) {
        inputRef.current.style.display = "none";
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  React.useEffect(() => {
    getHierarchyData();
    setSearchValue([])
    setSelectedValue([])
  }, [topVault, ParentsCounter]);

  const onSelect = (
    nodeId: any,
    abbr: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    if (abbr === "product") {
      history.push(`/bomentry/${projectId}/${projectName}/${itemId}/0/product/${workstation}/${HierarchyAllData?.is_leaf}/${HierarchyAllData?.is_copy}`);
    } else {
      if (abbr) {
        history.push(`/bomentry/${projectId}/${projectName}/${topVault}/${itemId}/${abbr}/${workstation}/${HierarchyAllData?.is_leaf}/${HierarchyAllData?.is_copy}`);
      } else {
        history.push(`/bomentry/${projectId}/${projectName}/${topVault}/${itemId}/${"none"}/${workstation}/${HierarchyAllData?.is_leaf}/${HierarchyAllData?.is_copy}`);
      }
    }
  };

  return (
    <div style={{width:'100%'}}>
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid",
          borderBottomColor: "primary.light",
          height: "2.5rem",
          margin: "0 0.5rem",
          overflow: 'hidden',
        }}
      >

        <div
          className={styles.inputbox}
          ref={OuterRef}
        // tabIndex={1} onKeyDown={(event:any)=>HandleKeyDown(event)}
        >
          {
            <input
              type="search"
              placeholder="Search"
              value={selectedValue}
              className={styles.searchInput}
              autoFocus={false}
              style={{ color: "#007fff" }}
              // @ts-expect-
              ref={descRef}
              onKeyPress={(event: any) => handleOnChange(event)}
              onChange={(event: any) => handleOnChange(event)}
            // onFocus={(e) => FocusHandler(e)}
            />
          }

          <div
            style={{ display: "none", zIndex: 900, position: 'absolute' }}
            className={styles.AutoSearchBox}
            ref={inputRef}>
            <>
              <div className={styles.searchSubText}>
                {SearchValue.map((item: any, index: any) => {
                  return (
                    <div>
                      <div
                        key={index}
                        //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                        className={styles.innserSearchSubText}
                        onClick={() => {
                          setSelectedValue(item.name);
                          inputRef.current.style.display = "none";
                          sessionStorage.removeItem("BOMCreateNode")
                          sessionStorage.setItem("BOMCreateNode", JSON.stringify(item?.ancestors));
                          history.push(`/bomentry/${projectId}/${projectName}/${topVault}/${item?.id}/none/${workstation}/true/${copyStatus}`)
                        }}
                      >
                        <span
                        // onClick={() => {
                        //   handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob);
                        // }}
                        >

                          <>
                            {/* <span>{item?.value} </span> */}
                            <span>
                              {item?.vault_name}
                              <span>
                                <span>(</span>
                                {item?.part_no}
                                <span>)</span>
                              </span>
                              | <span>{item?.name}</span>
                            </span>
                          </>
                        </span>
                        <span
                          style={{
                            // marginRight: "0.5rem",
                            paddingTop: "5px",
                          }}>
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  );
                })}
                {SearchValue && SearchValue?.length == 0 && (
                  <div
                    style={{
                      paddingLeft: "1rem",
                      fontSize: "0.9rem",
                    }}>
                    No Data
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
        <Divider sx={{ height: '28px', m: '0 0.5rem', borderColor: 'primary.light' }} orientation="vertical" />
        <IconButton color="primary" sx={{ p: "5px" }} aria-label="directions">
          <QrCodeScannerIcon onClick={() => history.push(`/bomscanner/${projectId}/${projectName}`)} />
        </IconButton>
        <IconButton color="primary" sx={{ p: "5px" }} aria-label="directions">
          <SyncIcon sx={{ cursor: 'pointer', color: syncColor }} onClick={() => getHierarchyDataReload()} />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: {xs:'75vh', sm: "75vh", md: "81vh", lg: "81vh", xl: "86vh", },
          // borderRight: "1px solid",
          // borderColor: "primary.light",
        }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        {!isDataLoading ? <BOMTreeViewCreate
          setrecyclebinCounter={setrecyclebinCounter}
          HierarchyData={HierarchyData && HierarchyData}
          onSelectCallback={onSelect}
          setParentsCounter={setParentsCounter}
        /> :
          <>
            <Box sx={{ width: "100%", padding: '0 0.5rem' }}>
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
            </Box>
          </>}
      </Box>
    </div>
  );
}
